import Joi from "joi";

import React from "react";

import {useRouter} from "next/router";

import {usePostPy3ApiUserVerifyMutation} from "@ef/api";
import {Input} from "@ef/components";
import {getOrganiserAppEventsUrl} from "@ef/constants";
import {OnFormSubmitType, useForm} from "@ef/hooks";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef/modals";
import {useAuth} from "@ef/providers";

import {Box, Button, Heading, Icon, Stack, useToast} from "@chakra-ui/react";

type FormInputsType = {
  password: string;
  passwordAgain: string;
};

const formInputsSchema = Joi.object({
  password: Joi.string().min(6).max(100).required(),
  passwordAgain: Joi.string().valid(Joi.ref("password")).required(),
});

const AccountCreationModal: React.FC = () => {
  const {closeModal} = useModal("accountCreation");
  const {refetchUserInfo} = useAuth();

  const {query, push} = useRouter();
  const toast = useToast();

  const [verifyUser] = usePostPy3ApiUserVerifyMutation();

  const {registerWithError, handleSubmit} = useForm<FormInputsType>(formInputsSchema, {
    keepDataOnSuccess: true,
  });

  const handleOnSubmit: OnFormSubmitType<FormInputsType> = async (data) => {
    const res = await verifyUser({
      requestVerifyUserA992424: {
        password1: data.password,
        password2: data.passwordAgain,
        token: query?.token?.toString() || "",
        user_id: query?.userId?.toString() || "",
      },
    });

    if ("data" in res && res.data.user) {
      await refetchUserInfo();
      closeModal();
      //@ts-expect-error swagger
      if (res.data.user.last_role === "org") {
        window.location.assign(getOrganiserAppEventsUrl());
        return;
      }
      push("/my-wallet");
      return;
    } else {
      toast({
        title: "Oops!",
        description:
          //@ts-expect-error err
          res?.error?.data?.message || "Something went wrong and we couldn't process your request.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack p={["2rem", "2rem", "3rem"]} align="center">
      <Heading variant="h3" pb="1rem">
        Set your password
      </Heading>

      <Box as="form" w="100%" onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack pb="0.8rem">
          <Input
            {...registerWithError("password")}
            placeholder="Set your password"
            type="password"
          />

          <Input
            {...registerWithError("passwordAgain")}
            placeholder="Repeat password"
            type="password"
          />
        </Stack>

        <Button type="submit" w="100%">
          Create account
        </Button>
      </Box>

      <Box pt="1rem">
        <Icon width="158px" height="20px" viewBox="0 0 158 20" fill="none">
          <path
            d="M37.8713 3.47949H46.5186V5.78071H40.4971V8.69932H46.2027L46.1829 10.8883H40.4773V13.9753H46.7752V16.2765H37.8516V3.47949H37.8713Z"
            fill="#D0D0D0"
          />
          <path
            d="M48.5527 7.33398H51.2377L53.6661 13.4706L56.2326 7.33398H58.7597L54.8111 16.2769H52.3433L48.5527 7.33398Z"
            fill="#D0D0D0"
          />
          <path
            d="M65.1547 16.5381C62.0946 16.5381 59.8242 14.5362 59.8242 11.8421C59.8242 9.16671 62.0354 7.07129 64.8981 7.07129C67.6818 7.07129 69.6955 9.09187 69.6955 11.7298C69.6955 11.9918 69.6758 12.3285 69.6363 12.6653H62.5092C62.7461 13.7504 63.6938 14.4614 65.0955 14.4614C66.0629 14.4614 66.7341 14.1246 67.4251 13.3575L69.3599 14.7233C68.4123 15.8833 66.9118 16.5381 65.1547 16.5381ZM67.0698 10.8131C66.9316 9.80281 66.0629 9.11058 64.8783 9.11058C63.6345 9.11058 62.8843 9.82152 62.5882 10.8131H67.0698Z"
            fill="#D0D0D0"
          />
          <path
            d="M71.9457 7.33353H74.2161V7.96963C74.7887 7.37094 75.6968 7.03418 76.7629 7.03418C78.9544 7.03418 80.4943 8.5122 80.4943 10.6263V16.2765H78.0462V11.0005C78.0462 9.9528 77.3157 9.16701 76.2496 9.16701C75.2033 9.16701 74.3543 9.9528 74.3543 10.9444V16.2765H71.9062V7.33353H71.9457Z"
            fill="#D0D0D0"
          />
          <path
            d="M82.4512 7.3335H84.149V4.78906H86.5971V7.3335H88.7096V9.35407H86.5971V16.2764H84.149V9.35407H82.4512V7.3335Z"
            fill="#D0D0D0"
          />
          <path
            d="M91.4719 13.7129C92.2418 14.2928 93.0118 14.5735 93.683 14.5735C94.374 14.5735 94.7491 14.2928 94.7491 13.7877C94.7491 13.3761 94.4728 13.1142 93.4264 12.7026L93.0118 12.5529C91.3337 11.8794 90.544 11.1123 90.544 9.91491C90.544 8.26852 91.9457 7.08984 94.0384 7.08984C95.1243 7.08984 96.3088 7.42661 97.059 7.93175L96.0127 9.6717C95.4204 9.2601 94.8479 9.0543 94.1964 9.0543C93.4856 9.0543 93.0908 9.31622 93.0908 9.76524C93.0908 10.1581 93.4067 10.4201 94.5715 10.8878L94.8676 11.0187C96.6247 11.7297 97.3157 12.4781 97.3157 13.6941C97.3157 15.3967 95.9732 16.5566 93.8805 16.5566C92.538 16.5566 91.0573 16.0702 90.2676 15.3405L91.4719 13.7129Z"
            fill="#D0D0D0"
          />
          <path
            d="M99.8027 3.47949H108.43V5.78071H102.448V8.79287H108.035V10.9818H102.448V16.2765H99.8225V3.47949H99.8027Z"
            fill="#D0D0D0"
          />
          <path
            d="M110.821 7.33353H113.091V8.08189C113.624 7.40836 114.433 7.03418 115.342 7.03418C115.756 7.03418 116.171 7.12773 116.605 7.2774L115.579 9.48507C115.282 9.3541 115.105 9.31669 114.828 9.31669C113.802 9.31669 113.249 10.0276 113.249 11.4121V16.2578H110.801V7.33353H110.821Z"
            fill="#D0D0D0"
          />
          <path
            d="M127.7 16.2576H125.39V15.4718C124.561 16.1453 123.495 16.5195 122.33 16.5195C119.605 16.5195 117.434 14.4241 117.434 11.7861C117.434 9.14815 119.625 7.05273 122.33 7.05273C123.495 7.05273 124.561 7.44562 125.39 8.10044V7.31466H127.7V16.2576ZM122.626 14.3867C124.087 14.3867 125.252 13.2641 125.252 11.7861C125.252 10.3081 124.087 9.18557 122.626 9.18557C121.145 9.18557 119.98 10.3081 119.98 11.7861C119.98 13.2641 121.165 14.3867 122.626 14.3867Z"
            fill="#D0D0D0"
          />
          <path
            d="M138.912 16.2578H136.464V11.0005C136.464 9.9528 135.714 9.16701 134.648 9.16701C133.602 9.16701 132.753 9.9528 132.753 10.9444V16.2765H130.305V7.33353H132.595V7.96963C133.187 7.37094 134.095 7.03418 135.142 7.03418C136.385 7.03418 137.392 7.4832 138.064 8.28769C138.735 7.52062 139.9 7.03418 141.183 7.03418C143.434 7.03418 144.993 8.49349 144.993 10.6076V16.2765H142.545V11.0566C142.545 9.93409 141.815 9.16701 140.729 9.16701C139.702 9.16701 138.873 9.93409 138.873 10.9631V16.2578H138.912Z"
            fill="#D0D0D0"
          />
          <path
            d="M152.616 16.5381C149.556 16.5381 147.285 14.5362 147.285 11.8421C147.285 9.16671 149.496 7.07129 152.359 7.07129C155.143 7.07129 157.156 9.09187 157.156 11.7298C157.156 11.9918 157.137 12.3285 157.097 12.6653H149.97C150.207 13.7504 151.155 14.4614 152.556 14.4614C153.524 14.4614 154.195 14.1246 154.886 13.3575L156.821 14.7233C155.873 15.8833 154.373 16.5381 152.616 16.5381ZM154.531 10.8131C154.392 9.80281 153.524 9.11058 152.339 9.11058C151.095 9.11058 150.345 9.82152 150.049 10.8131H154.531Z"
            fill="#D0D0D0"
          />
          <path
            d="M4.22492 0H0V4.00374V8.00748V11.9925V15.9963V20H4.22492H8.44983H12.655V15.9963H8.44983H4.22492V11.9925V8.00748V4.00374H8.44983H12.655V0H8.44983H4.22492Z"
            fill="#D0D0D0"
          />
          <path
            d="M25.3289 0H21.1039H16.879V4.00374H21.1039H25.3289V8.00748V11.9925V15.9963V20H29.5538V15.9963V11.9925V8.00748V4.00374V0H25.3289Z"
            fill="#D0D0D0"
          />
          <path d="M12.6741 8.00781H8.44922V12.0116H12.6741V8.00781Z" fill="#D0D0D0" />
          <path d="M21.1038 8.00781H16.8789V12.0116H21.1038V8.00781Z" fill="#D0D0D0" />
        </Icon>
      </Box>
    </Stack>
  );
};

export const useAccountCreationModal: InitialUseCreateModalType = () =>
  useCreateModal("accountCreation", AccountCreationModal, {
    closeOnEsc: false,
    closeOnOverlayClick: false,
    hideCloseButton: true,
    size: "md",
  });
