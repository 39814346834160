import {extendTheme, ThemeComponents, ThemeConfig} from "@chakra-ui/react";

const fonts = {heading: "OceanWide, sans-serif", body: "Inter, sans-serif"};

const breakpoints = {
  sm: "30em", // 480px
  md: "48em", // 768px
  lg: "64em", // 1024px
  xl: "80em", // 1280px
};

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const components: ThemeComponents = {
  Alert: {
    baseStyle: {container: {borderRadius: "6px", h: "40px"}},
  },
  Heading: {
    baseStyle: {fontWeight: "600", width: "fit-content"},
    variants: {
      h1: {
        fontSize: ["40px", "56px", null],
        lineHeight: ["44px", "70px", null],
      },
      h2: {
        fontSize: ["28px", "32px", null],
        lineHeight: ["30.8px", "40px", null],
      },
      h3: {
        fontSize: ["20px", "24px", null],
        lineHeight: ["25px", "30px", null],
      },
      h4: {
        fontSize: "20px",
        lineHeight: ["22px", "25px", null],
      },
    },
  },
  Text: {
    baseStyle: {
      width: "fit-content",
      // fontWeight: "400, Regular",
      fontSize: ["15px", "16px", null],
      lineHeight: "24px",
    },
    sizes: {small: {fontSize: "14px", lineHeight: "21px"}, medium: {fontSize: "16px"}},
    variants: {
      "form-error": {
        fontSize: "14px",
        color: "ef-red",
        fontWeight: "700",
        lineHeight: "16px",
      },
      "form-label": {color: "ef-black", mb: "4px"},
    },
  },
  Link: {
    baseStyle: {
      color: "ef-link",
      fontWeight: "400, Regular",
      fontSize: ["15px", "16px", null],
      lineHeight: "24px",
      textDecoration: "none",
      width: "fit-content",
      _hover: {textDecoration: "none"},
      _active: {textDecoration: "none", bg: "ef-light-blue"},
    },
    sizes: {small: {fontsize: "14px", lineHeight: "21px"}},
    variants: {
      baseDecorationSwitched: {textDecoration: "none", _hover: {textDecoration: "underline"}},
      black: {color: "ef-black"},
      blackWithNoDecoration: {
        color: "ef-black",
        textDecoration: "none",
        _hover: {textDecoration: "underline"},
      },
      grayWithNoDecoration: {
        color: "ef-gray",
        textDecoration: "none",
        _hover: {textDecoration: "underline"},
      },
      gray: {color: "ef-gray"},
      blue: {color: "ef-link"},
      lightGray: {color: "ef-border"},
      white: {color: "ef-white"},
      button: {textDecoration: "none"},
      unstyled: {
        textDecoration: "none",
        color: "unset",
      },
    },
  },
  Divider: {
    baseStyle: {borderColor: "ef-divider"},
  },
  Tabs: {
    defaultProps: {variant: "unstyled"},
    baseStyle: {
      tab: {
        borderRadius: "6px",
        fontWeight: "400, Regular",
        fontSize: "16px",
        whiteSpace: "nowrap",
        color: "ef-black",
        padding: "8px 12px 8px 12px",
        _selected: {
          color: "ef-black",
          bg: "ef-divider",
        },
      },
      tabpanel: {p: "0"},
    },
  },
  Button: {
    defaultProps: {variant: "none"},
    baseStyle: {
      borderRadius: "46px",
      bg: "ef-green",
      color: "ef-white",
      _hover: {
        _disabled: {
          bg: "ef-green",
        },
      },
    },
    sizes: {
      sm: {
        fontSize: "14px",
        height: "43px",
        fontWeight: "500",
        padding: "12px 16px 12px 16px",
      },
      md: {
        fontSize: "18px",
        height: "43px",
        fontWeight: "600",
        lineHeight: "21.78px",
        padding: "12px 40px 12px 40px",
        _hover: {
          bg: "ef-dark-green",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ef-green)",
        },
      },
    },
    variants: {
      red: {
        bg: "ef-red",
        color: "white",
        fontWeight: "normal",
        _hover: {
          bg: "ef-red",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ef-red)",
        },
      },
      blue: {
        bg: "ef-blue",
        color: "white",
        fontWeight: "normal",
        _hover: {
          bg: "ef-blue",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ef-blue)",
        },
      },
      blueOutline: {
        bg: "transparent",
        border: "1px solid",
        borderColor: "ef-blue",
        color: "ef-blue",
        fontWeight: "normal",
        _hover: {
          bg: "ef-white",
        },
      },
      gray: {
        bg: "ef-divider",
        border: "1px solid",
        borderColor: "ef-divider",
        color: "ef-black",
        fontWeight: "normal",
        _hover: {
          bg: "ef-divider",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ef-white)",
        },
      },
      grayOutline: {
        bg: "transparent",
        border: "1px solid",
        borderColor: "ef-border",
        color: "ef-gray-700",
        fontWeight: "normal",
        _hover: {
          bg: "ef-white",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ef-white)",
        },
      },
      tabs: {
        fontWeight: "400, Regular",
        bg: "transparent",
        color: "ef-black",
        _selected: {
          color: "ef-black",
          bg: "ef-divider",
        },
        _hover: {
          bg: "ef-divider",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ef-divider)",
        },
      },
      tabSelected: {
        fontWeight: "400, Regular",
        whiteSpace: "nowrap",
        bg: "ef-divider",
        color: "ef-black",
        _hover: {
          bg: "ef-divider",
        },
        _selected: {
          color: "ef-black",
          bg: "ef-divider",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ef-divider)",
        },
      },
      nftTransparentWithBorder: {
        height: "35px",
        fontSize: "16px",
        fontWeight: "400, Regular",
        whiteSpace: "nowrap",
        bg: "transparent",
        w: "fit-content",
        border: "1px",
        borderColor: "ef-divider",
        borderRadius: "6px",
        p: "0 14px",
        color: "ef-black",
        _hover: {
          bg: "ef-divider",
        },
        _selected: {
          bg: "ef-divider",
        },
        _focus: {
          boxShadow: "unset",
        },
      },
      nftSelected: {
        height: "35px",
        fontSize: "16px",
        fontWeight: "400, Regular",
        whiteSpace: "nowrap",
        bg: "ef-nft-primary",
        w: "fit-content",
        color: "ef-white",
        border: "1px",
        borderColor: "ef-divider",
        borderRadius: "6px",
        p: "0 14px",
        _hover: {
          bg: "ef-nft-primary-hover",
        },
        _selected: {
          bg: "ef-nft-primary-hover",
        },
        _focus: {
          boxShadow: "unset",
        },
      },
      tabSquare: {
        fontWeight: "400, Regular",
        borderRadius: "6px",
        bg: "white",
        borderWidth: "1px",
        borderColor: "ef-border",
        color: "ef-black",
        _hover: {
          borderColor: "ef-black",
          bg: "unset",
          _disabled: {
            bg: "unset",
          },
        },
      },
      ob_primary: {
        bg: "ob-blue",
        fontSize: "16px",
        borderRadius: "15px",
        _hover: {
          bg: "ob-grey-100",
          color: "ob-blue",
          _disabled: {
            bg: "ob-grey-100",
            color: "ob-blue",
          },
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ob-blue)",
        },
        _disabled: {},
      },
      ob_secondary: {
        bg: "ob-grey-100",
        fontSize: "16px",
        borderRadius: "15px",
        color: "ob-blue",
        _hover: {
          bg: "ob-grey-100",
          color: "ob-blue",
          borderColor: "ob-blue",
          borderWidth: "1px",
        },
        _focus: {
          boxShadow: "0 0 0 3px var(--chakra-colors-ob-blue)",
        },
      },
    },
  },
  ObInput: {
    baseStyle: {
      color: "ef-black",
      fontSize: "16px",
      fontWeight: "normal",
      border: "1px",
      borderRadius: "15px",
      borderColor: "ef-d1",
      _placeholder: {
        color: "ob-grey-300",
      },
      _active: {borderColor: "ef-blue"},
      _focus: {
        borderColor: "ef-blue",
      },
      _focusVisible: {
        color: "ef-blue",
      },
      _disabled: {
        bg: "#F1F1F1",
      },
      _hover: {
        borderColor: "ef-blue",
      },
    },
  },
  ObSelect: {
    baseStyle: {
      cursor: "pointer",
      color: "ef-black",
      fontSize: "16px",
      fontWeight: "normal",
      border: "0px !important",
      borderRadius: "15px",
      borderColor: "#F8F8F8",
      _placeholder: {
        color: "ob-grey-300",
      },
      _active: {borderColor: "ef-blue"},
      _focus: {
        borderColor: "ef-blue",
      },
      _focusVisible: {
        color: "ob-blue",
      },
      _disabled: {
        bg: "#F1F1F1",
      },
      _hover: {
        borderColor: "ef-blue",
      },
    },
  },
  CustomInput: {
    baseStyle: {
      color: "ef-black",
      fontSize: "16px",
      fontWeight: "normal",
      border: "1px",
      _placeholder: {
        color: "ef-border",
      },
      _active: {borderColor: "ef-blue"},
      _focus: {
        boxShadow: "0px 0px 0px 2px rgb(59 121 247 / 25%)",
      },
      _disabled: {
        bg: "#F1F1F1",
      },
    },
    variants: {
      code: {
        fontWeight: "bold",
        fontSize: "20px",
      },
    },
  },
  Checkbox: {
    baseStyle: {
      label: {color: "ef-black", fontSize: "16px", iconColor: "red.200"},
      control: {
        borderRadius: "4px",
        boxSize: "16px",

        _checked: {
          bg: "ef-checkbox",
          borderColor: "ef-checkbox",
          color: "ef-white",

          _hover: {
            bg: "ef-checkbox",
            borderColor: "ef-checkbox",
          },

          _disabled: {
            borderColor: "ef-checkbox-disabled",
            bg: "ef-checkbox-disabled",
            color: "ef-white",
          },
        },
      },
    },
    variants: {
      onboarding: {
        label: {
          fontSize: "15px",
        },
        control: {
          borderWidth: "2px",
          borderRadius: "2px",
          boxSize: "16px",
          borderColor: "ef-black",
          _checked: {
            bg: "ob-blue",
            borderColor: "ob-blue",
            color: "ef-white",

            _hover: {
              bg: "ob-blue",
              borderColor: "ob-blue",
            },

            _disabled: {
              borderColor: "ef-checkbox-disabled",
              bg: "ef-checkbox-disabled",
              color: "ef-white",
            },
          },
        },
      },
    },
  },
  Radio: {
    baseStyle: {
      label: {color: "ef-black", fontSize: "16px", iconColor: "red.200"},
      control: {
        boxSize: "16px",

        _checked: {
          bg: "ef-checkbox",
          borderColor: "ef-checkbox",
          color: "ef-white",

          _hover: {
            bg: "ef-checkbox",
            borderColor: "ef-checkbox",
          },

          _disabled: {
            borderColor: "ef-checkbox-disabled",
            bg: "ef-checkbox-disabled",
            color: "ef-white",
          },
        },
      },
    },
  },
};

const theme = extendTheme({
  config,
  colors: {
    black: "#16161D",
    "ef-purple": "#8067f0",
    "ef-orange": "#f75023",
    "ef-orange-dark": "#f74023",
    "ef-red": "#ED1F1F",
    "ef-border": "#A9A9A9",
    "ef-gray": "#6f6b80",
    "ef-gray-secondary": "#5C5C70",
    "ef-gray-l1": "#F9FAFB",
    "ef-gray-light": "#F8FAFC",
    "ef-checkbox-disabled": "#979797",
    "ef-black": "#000000",
    "ef-link": "#192BC2",
    "ef-primary": "#192BC2",
    "ef-checkbox": "#3B79F7",
    "ef-blue": "#1B299C",
    "ef-dark-blue": "#141A4E",
    "ef-light-blue": "#D4FFEF",
    "ef-lightest-blue": "#f4fbfe",
    "ef-divider": "#EBEBEB",
    "ef-green": "#19C57A",
    "ef-dark-green": "#0F955A",
    "ef-white": "#FFFFFF",
    "ef-green-l1": "#67CC7B",
    "ef-d1": "#D1D1D1",
    "ef-nft-primary": "#142294",
    "ef-nft-primary-hover": "#0b1566",
    "ef-gray-700": "#344054",
    "ef-gray-500": "#667085",
    "ef-gray-300": "#D0D5DD,",
    "ef-yellow": "#FFE600",
    "ef-light-yellow": "#FFEEB4",
    "infobar-green": "#BAF0DB",
    "infobar-red": "#FFB6B6",

    "ob-blue": "#1F2FBA",
    "ob-blue-100": "#e9eaf8",
    "ob-grey-100": "#f4f5fc",
    "ob-grey-300": "#888888",
    "ob-grey-400": "#444444",
    "ob-black": "#333333",
    "ob-white": "#E1E4FF",

    "text-neutral-blue-10": "#616174",
  },
  fonts,
  breakpoints,
  components,
  styles: {
    global: {
      html: {
        scrollBehavior: "smooth",
        scrollPaddingTop: "5rem",
      },
    },
  },
});

export default theme;
