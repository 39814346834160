import {baseApi as api} from "../baseQuery/baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAhWarmup: build.query<GetAhWarmupApiResponse, GetAhWarmupApiArg>({
      query: () => ({url: `/_ah/warmup`}),
    }),
    getPy3ApiEvent: build.query<GetPy3ApiEventApiResponse, GetPy3ApiEventApiArg>({
      query: () => ({url: `/py3/api/event/`}),
    }),
    getPy3ApiEventByEventIdBilling: build.query<
      GetPy3ApiEventByEventIdBillingApiResponse,
      GetPy3ApiEventByEventIdBillingApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/event/${queryArg.eventId}/billing/`}),
    }),
    postPy3ApiEventByEventIdBilling: build.mutation<
      PostPy3ApiEventByEventIdBillingApiResponse,
      PostPy3ApiEventByEventIdBillingApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/event/${queryArg.eventId}/billing/`, method: "POST"}),
    }),
    postPy3ApiEventByEventIdOrderPrecheck: build.mutation<
      PostPy3ApiEventByEventIdOrderPrecheckApiResponse,
      PostPy3ApiEventByEventIdOrderPrecheckApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/event/${queryArg.eventId}/order-precheck/`,
        method: "POST",
        body: queryArg.precheckPayload67108Ea,
      }),
    }),
    getPy3ApiEventByEventIdOrders: build.query<
      GetPy3ApiEventByEventIdOrdersApiResponse,
      GetPy3ApiEventByEventIdOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/event/${queryArg.eventId}/orders/`,
        params: {legacy_id: queryArg.legacyId, org_list: queryArg.orgList},
      }),
    }),
    getPy3ApiEventByEventIdTickets: build.query<
      GetPy3ApiEventByEventIdTicketsApiResponse,
      GetPy3ApiEventByEventIdTicketsApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/event/${queryArg.eventId}/tickets/`}),
    }),
    getPy3ApiEvents: build.query<GetPy3ApiEventsApiResponse, GetPy3ApiEventsApiArg>({
      query: () => ({url: `/py3/api/events/`}),
    }),
    getPy3ApiEventsByEventId: build.query<
      GetPy3ApiEventsByEventIdApiResponse,
      GetPy3ApiEventsByEventIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/events/${queryArg.eventId}/`}),
    }),
    patchPy3ApiEventsByEventId: build.mutation<
      PatchPy3ApiEventsByEventIdApiResponse,
      PatchPy3ApiEventsByEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/events/${queryArg.eventId}/`,
        method: "PATCH",
        body: queryArg.eventDetailPayload67108Ea,
      }),
    }),
    getPy3ApiEventsByEventIdAttendees: build.query<
      GetPy3ApiEventsByEventIdAttendeesApiResponse,
      GetPy3ApiEventsByEventIdAttendeesApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/events/${queryArg.eventId}/attendees/`,
        params: {
          page_size: queryArg.pageSize,
          direction: queryArg.direction,
          cursor: queryArg.cursor,
        },
      }),
    }),
    postPy3ApiEventsByEventIdAttendees: build.mutation<
      PostPy3ApiEventsByEventIdAttendeesApiResponse,
      PostPy3ApiEventsByEventIdAttendeesApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/events/${queryArg.eventId}/attendees/`,
        method: "POST",
        body: queryArg.attendeesPayloadD291183,
      }),
    }),
    getPy3ApiEventsByEventIdAttendeesCsv: build.query<
      GetPy3ApiEventsByEventIdAttendeesCsvApiResponse,
      GetPy3ApiEventsByEventIdAttendeesCsvApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/events/${queryArg.eventId}/attendees/csv/`}),
    }),
    getPy3ApiEventsByEventIdEmails: build.query<
      GetPy3ApiEventsByEventIdEmailsApiResponse,
      GetPy3ApiEventsByEventIdEmailsApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/events/${queryArg.eventId}/emails/`}),
    }),
    postPy3ApiEventsByEventIdEmails: build.mutation<
      PostPy3ApiEventsByEventIdEmailsApiResponse,
      PostPy3ApiEventsByEventIdEmailsApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/events/${queryArg.eventId}/emails/`,
        method: "POST",
        body: queryArg.reminderRequestB874D23,
      }),
    }),
    deletePy3ApiEventsByEventIdEmailsAndEmailId: build.mutation<
      DeletePy3ApiEventsByEventIdEmailsAndEmailIdApiResponse,
      DeletePy3ApiEventsByEventIdEmailsAndEmailIdApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/events/${queryArg.eventId}/emails/${queryArg.emailId}/`,
        method: "DELETE",
      }),
    }),
    patchPy3ApiEventsByEventIdEmailsAndEmailId: build.mutation<
      PatchPy3ApiEventsByEventIdEmailsAndEmailIdApiResponse,
      PatchPy3ApiEventsByEventIdEmailsAndEmailIdApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/events/${queryArg.eventId}/emails/${queryArg.emailId}/`,
        method: "PATCH",
        body: queryArg.reminderRequestB874D23,
      }),
    }),
    postPy3ApiEventsByEventIdOrderPrecheck: build.mutation<
      PostPy3ApiEventsByEventIdOrderPrecheckApiResponse,
      PostPy3ApiEventsByEventIdOrderPrecheckApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/events/${queryArg.eventId}/order-precheck/`,
        method: "POST",
        body: queryArg.precheckPayload67108Ea,
      }),
    }),
    getPy3ApiEventsByEventIdOrdersCsv: build.query<
      GetPy3ApiEventsByEventIdOrdersCsvApiResponse,
      GetPy3ApiEventsByEventIdOrdersCsvApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/events/${queryArg.eventId}/orders/csv/`}),
    }),
    getPy3ApiEventsByEventIdTickets: build.query<
      GetPy3ApiEventsByEventIdTicketsApiResponse,
      GetPy3ApiEventsByEventIdTicketsApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/events/${queryArg.eventId}/tickets/`}),
    }),
    postPy3ApiLogintoken: build.mutation<
      PostPy3ApiLogintokenApiResponse,
      PostPy3ApiLogintokenApiArg
    >({
      query: () => ({url: `/py3/api/logintoken/`, method: "POST"}),
    }),
    postPy3ApiLogintokenPop: build.mutation<
      PostPy3ApiLogintokenPopApiResponse,
      PostPy3ApiLogintokenPopApiArg
    >({
      query: () => ({url: `/py3/api/logintoken/pop/`, method: "POST"}),
    }),
    getPy3ApiNft: build.query<GetPy3ApiNftApiResponse, GetPy3ApiNftApiArg>({
      query: () => ({url: `/py3/api/nft/`}),
    }),
    getPy3ApiNftByNftId: build.query<GetPy3ApiNftByNftIdApiResponse, GetPy3ApiNftByNftIdApiArg>({
      query: (queryArg) => ({url: `/py3/api/nft/${queryArg.nftId}/`}),
    }),
    postPy3ApiNftByNftIdWithdrawal: build.mutation<
      PostPy3ApiNftByNftIdWithdrawalApiResponse,
      PostPy3ApiNftByNftIdWithdrawalApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/nft/${queryArg.nftId}/withdrawal/`,
        method: "POST",
        body: queryArg.nftWithdrawalRequestPayload08Fd148,
      }),
    }),
    getPy3ApiNftByNftIdWithdrawals: build.query<
      GetPy3ApiNftByNftIdWithdrawalsApiResponse,
      GetPy3ApiNftByNftIdWithdrawalsApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/nft/${queryArg.nftId}/withdrawals/`}),
    }),
    getPy3ApiNftsWithdrawalByWithdrawalId: build.query<
      GetPy3ApiNftsWithdrawalByWithdrawalIdApiResponse,
      GetPy3ApiNftsWithdrawalByWithdrawalIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/nfts/withdrawal/${queryArg.withdrawalId}/`}),
    }),
    postPy3ApiNftsWithdrawalByWithdrawalIdConfirm: build.mutation<
      PostPy3ApiNftsWithdrawalByWithdrawalIdConfirmApiResponse,
      PostPy3ApiNftsWithdrawalByWithdrawalIdConfirmApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/nfts/withdrawal/${queryArg.withdrawalId}/confirm/`,
        method: "POST",
        body: queryArg.nftWidthrawalConfirmationPayload08Fd148,
      }),
    }),
    postPy3ApiNftsByNftIdRetry: build.mutation<
      PostPy3ApiNftsByNftIdRetryApiResponse,
      PostPy3ApiNftsByNftIdRetryApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/nfts/${queryArg.nftId}/retry/`,
        method: "POST",
        body: queryArg.noncePayload08Fd148,
      }),
    }),
    getPy3ApiOrderByOrderId: build.query<
      GetPy3ApiOrderByOrderIdApiResponse,
      GetPy3ApiOrderByOrderIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/order/${queryArg.orderId}/`}),
    }),
    patchPy3ApiOrderByOrderId: build.mutation<
      PatchPy3ApiOrderByOrderIdApiResponse,
      PatchPy3ApiOrderByOrderIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/order/${queryArg.orderId}/`, method: "PATCH"}),
    }),
    patchPy3ApiOrderByOrderIdAttendeeAndAttendeeId: build.mutation<
      PatchPy3ApiOrderByOrderIdAttendeeAndAttendeeIdApiResponse,
      PatchPy3ApiOrderByOrderIdAttendeeAndAttendeeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/order/${queryArg.orderId}/attendee/${queryArg.attendeeId}/`,
        method: "PATCH",
        body: queryArg.attendeeUpdatePayload5E5B7E5,
      }),
    }),
    getPy3ApiOrderByOrderIdMerchandise: build.query<
      GetPy3ApiOrderByOrderIdMerchandiseApiResponse,
      GetPy3ApiOrderByOrderIdMerchandiseApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/order/${queryArg.orderId}/merchandise/`}),
    }),
    getPy3ApiOrderByOrderIdTickets: build.query<
      GetPy3ApiOrderByOrderIdTicketsApiResponse,
      GetPy3ApiOrderByOrderIdTicketsApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/order/${queryArg.orderId}/tickets/`}),
    }),
    getPy3ApiOrders: build.query<GetPy3ApiOrdersApiResponse, GetPy3ApiOrdersApiArg>({
      query: () => ({url: `/py3/api/orders/`}),
    }),
    postPy3ApiOrders: build.mutation<PostPy3ApiOrdersApiResponse, PostPy3ApiOrdersApiArg>({
      query: (queryArg) => ({
        url: `/py3/api/orders/`,
        method: "POST",
        body: queryArg.orderPaymentPayload5E5B7E5,
      }),
    }),
    getPy3ApiOrdersByOrderId: build.query<
      GetPy3ApiOrdersByOrderIdApiResponse,
      GetPy3ApiOrdersByOrderIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/orders/${queryArg.orderId}/`}),
    }),
    patchPy3ApiOrdersByOrderId: build.mutation<
      PatchPy3ApiOrdersByOrderIdApiResponse,
      PatchPy3ApiOrdersByOrderIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/orders/${queryArg.orderId}/`, method: "PATCH"}),
    }),
    deletePy3ApiOrdersByOrderIdAndAttendeeIdTicketSlug: build.mutation<
      DeletePy3ApiOrdersByOrderIdAndAttendeeIdTicketSlugApiResponse,
      DeletePy3ApiOrdersByOrderIdAndAttendeeIdTicketSlugApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/orders/${queryArg.orderId}/${queryArg.attendeeId}/${queryArg.ticketSlug}/`,
        method: "DELETE",
      }),
    }),
    postPy3ApiPaymentsAnonymousIntent: build.mutation<
      PostPy3ApiPaymentsAnonymousIntentApiResponse,
      PostPy3ApiPaymentsAnonymousIntentApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/payments/anonymous-intent/`,
        method: "POST",
        body: queryArg.payloadPaymentIntentEfdd37C,
      }),
    }),
    postPy3ApiPaymentsPaypalOrder: build.mutation<
      PostPy3ApiPaymentsPaypalOrderApiResponse,
      PostPy3ApiPaymentsPaypalOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/payments/paypal-order/`,
        method: "POST",
        body: queryArg.paypalOrderPayload3A00991,
      }),
    }),
    postPy3ApiPaymentsPurchaseConfirm: build.mutation<
      PostPy3ApiPaymentsPurchaseConfirmApiResponse,
      PostPy3ApiPaymentsPurchaseConfirmApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/payments/purchase-confirm/`,
        method: "POST",
        body: queryArg.requestPurchaseConfirm3A00991,
      }),
    }),
    postPy3ApiPaymentsByAction: build.mutation<
      PostPy3ApiPaymentsByActionApiResponse,
      PostPy3ApiPaymentsByActionApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/payments/${queryArg.action}/`,
        method: "POST",
        body: queryArg.requestPaymentAction3A00991,
      }),
    }),
    postPy3ApiRefunds: build.mutation<PostPy3ApiRefundsApiResponse, PostPy3ApiRefundsApiArg>({
      query: () => ({url: `/py3/api/refunds/`, method: "POST"}),
    }),
    getPy3ApiStatements: build.query<GetPy3ApiStatementsApiResponse, GetPy3ApiStatementsApiArg>({
      query: () => ({url: `/py3/api/statements/`}),
    }),
    getPy3ApiTicketByTicketId: build.query<
      GetPy3ApiTicketByTicketIdApiResponse,
      GetPy3ApiTicketByTicketIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/ticket/${queryArg.ticketId}/`}),
    }),
    postPy3ApiUserLogin: build.mutation<PostPy3ApiUserLoginApiResponse, PostPy3ApiUserLoginApiArg>({
      query: (queryArg) => ({
        url: `/py3/api/user/login/`,
        method: "POST",
        body: queryArg.requestLoginA992424,
      }),
    }),
    postPy3ApiUserLogout: build.mutation<
      PostPy3ApiUserLogoutApiResponse,
      PostPy3ApiUserLogoutApiArg
    >({
      query: () => ({url: `/py3/api/user/logout/`, method: "POST"}),
    }),
    getPy3ApiUserNotifications: build.query<
      GetPy3ApiUserNotificationsApiResponse,
      GetPy3ApiUserNotificationsApiArg
    >({
      query: () => ({url: `/py3/api/user/notifications/`}),
    }),
    postPy3ApiUserNotifications: build.mutation<
      PostPy3ApiUserNotificationsApiResponse,
      PostPy3ApiUserNotificationsApiArg
    >({
      query: () => ({url: `/py3/api/user/notifications/`, method: "POST"}),
    }),
    postPy3ApiUserPasswordChange: build.mutation<
      PostPy3ApiUserPasswordChangeApiResponse,
      PostPy3ApiUserPasswordChangeApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/user/password-change/`,
        method: "POST",
        body: queryArg.requestPasswordA992424,
      }),
    }),
    postPy3ApiUserPasswordReset: build.mutation<
      PostPy3ApiUserPasswordResetApiResponse,
      PostPy3ApiUserPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/user/password-reset/`,
        method: "POST",
        body: queryArg.requestPasswordResetA992424,
      }),
    }),
    getPy3ApiUserProfile: build.mutation<
      GetPy3ApiUserProfileApiResponse,
      GetPy3ApiUserProfileApiArg
    >({
      query: () => ({url: `/py3/api/user/profile/`, method: "GET"}),
    }),
    postPy3ApiUserProfile: build.mutation<
      PostPy3ApiUserProfileApiResponse,
      PostPy3ApiUserProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/user/profile/`,
        method: "POST",
        body: queryArg.requestLoginA992424,
      }),
    }),
    getPy3ApiUserRefresh: build.mutation<
      GetPy3ApiUserRefreshApiResponse,
      GetPy3ApiUserRefreshApiArg
    >({
      query: () => ({url: `/py3/api/user/refresh/`, method: "GET"}),
    }),
    postPy3ApiUserRegister: build.mutation<
      PostPy3ApiUserRegisterApiResponse,
      PostPy3ApiUserRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/user/register/`,
        method: "POST",
        body: queryArg.requestUserRegistrationA992424,
      }),
    }),
    getPy3ApiUserRoleByRole: build.query<
      GetPy3ApiUserRoleByRoleApiResponse,
      GetPy3ApiUserRoleByRoleApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/user/role/${queryArg.role}/`}),
    }),
    getPy3ApiUserStatus: build.mutation<GetPy3ApiUserStatusApiResponse, GetPy3ApiUserStatusApiArg>({
      query: (queryArg) => ({
        url: `/py3/api/user/status/`,
        method: "GET",
        params: {email: queryArg.email},
      }),
    }),
    postPy3ApiUserVerifyTrigger: build.mutation<
      PostPy3ApiUserVerifyTriggerApiResponse,
      PostPy3ApiUserVerifyTriggerApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/user/verify-trigger/`,
        method: "POST",
        body: queryArg.verifyTriggerPayloadA992424,
      }),
    }),
    postPy3ApiUserVerify: build.mutation<
      PostPy3ApiUserVerifyApiResponse,
      PostPy3ApiUserVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/user/verify/`,
        method: "POST",
        body: queryArg.requestVerifyUserA992424,
      }),
    }),
    getPy3ApiByAccountIdSubscriptions: build.query<
      GetPy3ApiByAccountIdSubscriptionsApiResponse,
      GetPy3ApiByAccountIdSubscriptionsApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/${queryArg.accountId}/subscriptions/`}),
    }),
    postPy3ApiByAccountIdSubscriptions: build.mutation<
      PostPy3ApiByAccountIdSubscriptionsApiResponse,
      PostPy3ApiByAccountIdSubscriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.accountId}/subscriptions/`,
        method: "POST",
        body: queryArg.requestSubscription3A00991,
      }),
    }),
    deletePy3ApiByAccountIdSubscriptionsAndSubscriptionId: build.mutation<
      DeletePy3ApiByAccountIdSubscriptionsAndSubscriptionIdApiResponse,
      DeletePy3ApiByAccountIdSubscriptionsAndSubscriptionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.accountId}/subscriptions/${queryArg.subscriptionId}/`,
        method: "DELETE",
      }),
    }),
    getPy3ApiByObjectTypeAndEventId: build.query<
      GetPy3ApiByObjectTypeAndEventIdApiResponse,
      GetPy3ApiByObjectTypeAndEventIdApiArg
    >({
      query: (queryArg) => ({url: `/py3/api/${queryArg.objectType}/${queryArg.eventId}/`}),
    }),
    patchPy3ApiByObjectTypeAndEventId: build.mutation<
      PatchPy3ApiByObjectTypeAndEventIdApiResponse,
      PatchPy3ApiByObjectTypeAndEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.objectType}/${queryArg.eventId}/`,
        method: "PATCH",
        body: queryArg.eventDetailPayload67108Ea,
      }),
    }),
    postPy3ApiByObjectTypeAndEventIdCustomFields: build.mutation<
      PostPy3ApiByObjectTypeAndEventIdCustomFieldsApiResponse,
      PostPy3ApiByObjectTypeAndEventIdCustomFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.objectType}/${queryArg.eventId}/custom-fields/`,
        method: "POST",
        body: queryArg.customFieldsPayload67108Ea,
      }),
    }),
    postPy3ApiByObjectTypeAndEventIdUpdateBilling: build.mutation<
      PostPy3ApiByObjectTypeAndEventIdUpdateBillingApiResponse,
      PostPy3ApiByObjectTypeAndEventIdUpdateBillingApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.objectType}/${queryArg.eventId}/update-billing/`,
        method: "POST",
        body: queryArg.payloadEventBilling67108Ea,
      }),
    }),
    patchPy3ApiByObjectTypeAndEventIdUpdateInfo: build.mutation<
      PatchPy3ApiByObjectTypeAndEventIdUpdateInfoApiResponse,
      PatchPy3ApiByObjectTypeAndEventIdUpdateInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.objectType}/${queryArg.eventId}/update-info/`,
        method: "PATCH",
        body: queryArg.eventDetailPayload67108Ea,
      }),
    }),
    postPy3ApiByObjectTypeAndEventIdUpdateText: build.mutation<
      PostPy3ApiByObjectTypeAndEventIdUpdateTextApiResponse,
      PostPy3ApiByObjectTypeAndEventIdUpdateTextApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.objectType}/${queryArg.eventId}/update-text/`,
        method: "POST",
        body: queryArg.updateTextPayload67108Ea,
      }),
    }),
    postPy3ApiByObjectTypeAndEventIdUpdateTickets: build.mutation<
      PostPy3ApiByObjectTypeAndEventIdUpdateTicketsApiResponse,
      PostPy3ApiByObjectTypeAndEventIdUpdateTicketsApiArg
    >({
      query: (queryArg) => ({
        url: `/py3/api/${queryArg.objectType}/${queryArg.eventId}/update-tickets/`,
        method: "POST",
        body: queryArg.eventUpdateTicketsPayload67108Ea,
      }),
    }),
    postPy3Error: build.mutation<PostPy3ErrorApiResponse, PostPy3ErrorApiArg>({
      query: (queryArg) => ({
        url: `/py3/error/`,
        method: "POST",
        body: queryArg.errorLogPayloadDfdcc98,
      }),
    }),
    getPy3ToByRoute: build.query<GetPy3ToByRouteApiResponse, GetPy3ToByRouteApiArg>({
      query: (queryArg) => ({url: `/py3/to/${queryArg.route}/`}),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as beApi};
export type GetAhWarmupApiResponse = unknown;
export type GetAhWarmupApiArg = void;
export type GetPy3ApiEventApiResponse = /** status 200 OK */ EventListResponse;
export type GetPy3ApiEventApiArg = void;
export type GetPy3ApiEventByEventIdBillingApiResponse =
  /** status 200 OK */ EventBillingFormMetaResponse;
export type GetPy3ApiEventByEventIdBillingApiArg = {
  eventId: string;
};
export type PostPy3ApiEventByEventIdBillingApiResponse =
  /** status 200 OK */ EventBillingFormMetaResponse;
export type PostPy3ApiEventByEventIdBillingApiArg = {
  eventId: string;
};
export type PostPy3ApiEventByEventIdOrderPrecheckApiResponse =
  /** status 200 OK */ PrecheckResponse;
export type PostPy3ApiEventByEventIdOrderPrecheckApiArg = {
  eventId: string;
  precheckPayload67108Ea: PrecheckPayload;
};
export type GetPy3ApiEventByEventIdOrdersApiResponse = /** status 200 OK */ EventOrdersResponse;
export type GetPy3ApiEventByEventIdOrdersApiArg = {
  eventId: string;
  legacyId?: boolean;
  orgList?: boolean;
};
export type GetPy3ApiEventByEventIdTicketsApiResponse = /** status 200 OK */ TicketKindsResponse;
export type GetPy3ApiEventByEventIdTicketsApiArg = {
  eventId: string;
};
export type GetPy3ApiEventsApiResponse = /** status 200 OK */ EventListResponse;
export type GetPy3ApiEventsApiArg = void;
export type GetPy3ApiEventsByEventIdApiResponse = /** status 200 OK */ EventResponse2;
export type GetPy3ApiEventsByEventIdApiArg = {
  eventId: string;
};
export type PatchPy3ApiEventsByEventIdApiResponse = /** status 200 OK */ EventFullResponse;
export type PatchPy3ApiEventsByEventIdApiArg = {
  eventId: string;
  eventDetailPayload67108Ea: EventDetailPayload;
};
export type GetPy3ApiEventsByEventIdAttendeesApiResponse =
  /** status 200 OK */ AttendeeListResponse;
export type GetPy3ApiEventsByEventIdAttendeesApiArg = {
  eventId: string;
  pageSize?: number;
  direction?: "next" | "prev";
  cursor?: string;
};
export type PostPy3ApiEventsByEventIdAttendeesApiResponse = unknown;
export type PostPy3ApiEventsByEventIdAttendeesApiArg = {
  eventId: string;
  attendeesPayloadD291183: AttendeesPayload;
};
export type GetPy3ApiEventsByEventIdAttendeesCsvApiResponse = unknown;
export type GetPy3ApiEventsByEventIdAttendeesCsvApiArg = {
  eventId: string;
};
export type GetPy3ApiEventsByEventIdEmailsApiResponse = /** status 200 OK */ ReminderList;
export type GetPy3ApiEventsByEventIdEmailsApiArg = {
  eventId: string;
};
export type PostPy3ApiEventsByEventIdEmailsApiResponse = unknown;
export type PostPy3ApiEventsByEventIdEmailsApiArg = {
  eventId: string;
  reminderRequestB874D23: ReminderRequest;
};
export type DeletePy3ApiEventsByEventIdEmailsAndEmailIdApiResponse = unknown;
export type DeletePy3ApiEventsByEventIdEmailsAndEmailIdApiArg = {
  eventId: string;
  emailId: number;
};
export type PatchPy3ApiEventsByEventIdEmailsAndEmailIdApiResponse = unknown;
export type PatchPy3ApiEventsByEventIdEmailsAndEmailIdApiArg = {
  eventId: string;
  emailId: number;
  reminderRequestB874D23: ReminderRequest;
};
export type PostPy3ApiEventsByEventIdOrderPrecheckApiResponse =
  /** status 200 OK */ PrecheckResponse;
export type PostPy3ApiEventsByEventIdOrderPrecheckApiArg = {
  eventId: string;
  precheckPayload67108Ea: PrecheckPayload;
};
export type GetPy3ApiEventsByEventIdOrdersCsvApiResponse = unknown;
export type GetPy3ApiEventsByEventIdOrdersCsvApiArg = {
  eventId: string;
};
export type GetPy3ApiEventsByEventIdTicketsApiResponse = /** status 200 OK */ TicketKindsResponse;
export type GetPy3ApiEventsByEventIdTicketsApiArg = {
  eventId: string;
};
export type PostPy3ApiLogintokenApiResponse = unknown;
export type PostPy3ApiLogintokenApiArg = void;
export type PostPy3ApiLogintokenPopApiResponse = unknown;
export type PostPy3ApiLogintokenPopApiArg = void;
export type GetPy3ApiNftApiResponse = /** status 200 OK */ NftDataListResponse;
export type GetPy3ApiNftApiArg = void;
export type GetPy3ApiNftByNftIdApiResponse = /** status 200 OK */ NftDataResponse;
export type GetPy3ApiNftByNftIdApiArg = {
  nftId: string;
};
export type PostPy3ApiNftByNftIdWithdrawalApiResponse = /** status 200 OK */ NftWithdrawalResponse;
export type PostPy3ApiNftByNftIdWithdrawalApiArg = {
  nftId: string;
  nftWithdrawalRequestPayload08Fd148: NftWithdrawalRequestPayload;
};
export type GetPy3ApiNftByNftIdWithdrawalsApiResponse =
  /** status 200 OK */ NftWithdrawalListResponse;
export type GetPy3ApiNftByNftIdWithdrawalsApiArg = {
  nftId: string;
};
export type GetPy3ApiNftsWithdrawalByWithdrawalIdApiResponse =
  /** status 200 OK */ NftWithdrawalResponse;
export type GetPy3ApiNftsWithdrawalByWithdrawalIdApiArg = {
  withdrawalId: string;
};
export type PostPy3ApiNftsWithdrawalByWithdrawalIdConfirmApiResponse =
  /** status 200 OK */ NftWithdrawalResponse;
export type PostPy3ApiNftsWithdrawalByWithdrawalIdConfirmApiArg = {
  withdrawalId: string;
  nftWidthrawalConfirmationPayload08Fd148: NftWidthrawalConfirmationPayload;
};
export type PostPy3ApiNftsByNftIdRetryApiResponse = /** status 200 OK */ ActionResponse;
export type PostPy3ApiNftsByNftIdRetryApiArg = {
  nftId: string;
  noncePayload08Fd148: NoncePayload;
};
export type GetPy3ApiOrderByOrderIdApiResponse = /** status 200 OK */ OrderDetailResponse;
export type GetPy3ApiOrderByOrderIdApiArg = {
  orderId: string;
};
export type PatchPy3ApiOrderByOrderIdApiResponse = unknown;
export type PatchPy3ApiOrderByOrderIdApiArg = {
  orderId: string;
};
export type PatchPy3ApiOrderByOrderIdAttendeeAndAttendeeIdApiResponse =
  /** status 200 OK */ AttendeeResponse;
export type PatchPy3ApiOrderByOrderIdAttendeeAndAttendeeIdApiArg = {
  orderId: string;
  attendeeId: string;
  attendeeUpdatePayload5E5B7E5: AttendeeUpdatePayload;
};
export type GetPy3ApiOrderByOrderIdMerchandiseApiResponse =
  /** status 200 OK */ MerchandiseListResponse;
export type GetPy3ApiOrderByOrderIdMerchandiseApiArg = {
  orderId: string;
};
export type GetPy3ApiOrderByOrderIdTicketsApiResponse = /** status 200 OK */ TicketListResponse;
export type GetPy3ApiOrderByOrderIdTicketsApiArg = {
  orderId: string;
};
export type GetPy3ApiOrdersApiResponse = /** status 200 OK */ OrderListResponse;
export type GetPy3ApiOrdersApiArg = void;
export type PostPy3ApiOrdersApiResponse = /** status 201 Created */ OrderPaymentResponse;
export type PostPy3ApiOrdersApiArg = {
  orderPaymentPayload5E5B7E5: OrderPaymentPayload;
};
export type GetPy3ApiOrdersByOrderIdApiResponse = /** status 200 OK */ OrderDetailResponse;
export type GetPy3ApiOrdersByOrderIdApiArg = {
  orderId: string;
};
export type PatchPy3ApiOrdersByOrderIdApiResponse = unknown;
export type PatchPy3ApiOrdersByOrderIdApiArg = {
  orderId: string;
};
export type DeletePy3ApiOrdersByOrderIdAndAttendeeIdTicketSlugApiResponse = unknown;
export type DeletePy3ApiOrdersByOrderIdAndAttendeeIdTicketSlugApiArg = {
  orderId: string;
  attendeeId: string;
  ticketSlug: string;
};
export type PostPy3ApiPaymentsAnonymousIntentApiResponse =
  /** status 200 OK */ ResponsePaymentIntent;
export type PostPy3ApiPaymentsAnonymousIntentApiArg = {
  payloadPaymentIntentEfdd37C: PayloadPaymentIntent;
};
export type PostPy3ApiPaymentsPaypalOrderApiResponse =
  /** status 201 Created */ ResponsePaypalOrder;
export type PostPy3ApiPaymentsPaypalOrderApiArg = {
  paypalOrderPayload3A00991: PaypalOrderPayload;
};
export type PostPy3ApiPaymentsPurchaseConfirmApiResponse = /** status 200 OK */ ActionResponse;
export type PostPy3ApiPaymentsPurchaseConfirmApiArg = {
  requestPurchaseConfirm3A00991: RequestPurchaseConfirm;
};
export type PostPy3ApiPaymentsByActionApiResponse = /** status 200 OK */ ResponsePaymentAction;
export type PostPy3ApiPaymentsByActionApiArg = {
  action: "setup-intent" | "payment-intent";
  requestPaymentAction3A00991: RequestPaymentAction;
};
export type PostPy3ApiRefundsApiResponse = unknown;
export type PostPy3ApiRefundsApiArg = void;
export type GetPy3ApiStatementsApiResponse = unknown;
export type GetPy3ApiStatementsApiArg = void;
export type GetPy3ApiTicketByTicketIdApiResponse = /** status 200 OK */ TicketDetailResponse;
export type GetPy3ApiTicketByTicketIdApiArg = {
  ticketId: number;
};
export type PostPy3ApiUserLoginApiResponse = /** status 200 OK */ ResponseUserProfile;
export type PostPy3ApiUserLoginApiArg = {
  requestLoginA992424: RequestLogin;
};
export type PostPy3ApiUserLogoutApiResponse = unknown;
export type PostPy3ApiUserLogoutApiArg = void;
export type GetPy3ApiUserNotificationsApiResponse = /** status 200 OK */ NotificationResponse;
export type GetPy3ApiUserNotificationsApiArg = void;
export type PostPy3ApiUserNotificationsApiResponse =
  /** status 204 No Content */ NotificationReadResponse;
export type PostPy3ApiUserNotificationsApiArg = void;
export type PostPy3ApiUserPasswordChangeApiResponse = /** status 200 OK */ ActionResponse;
export type PostPy3ApiUserPasswordChangeApiArg = {
  requestPasswordA992424: RequestPassword;
};
export type PostPy3ApiUserPasswordResetApiResponse = unknown;
export type PostPy3ApiUserPasswordResetApiArg = {
  requestPasswordResetA992424: RequestPasswordReset;
};
export type GetPy3ApiUserProfileApiResponse = /** status 200 OK */ ResponseUserProfile;
export type GetPy3ApiUserProfileApiArg = void;
export type PostPy3ApiUserProfileApiResponse = /** status 200 OK */ ResponseUserProfile;
export type PostPy3ApiUserProfileApiArg = {
  requestLoginA992424: RequestLogin;
};
export type GetPy3ApiUserRefreshApiResponse = /** status 200 OK */ ResponseUserProfile;
export type GetPy3ApiUserRefreshApiArg = void;
export type PostPy3ApiUserRegisterApiResponse = /** status 201 Created */ ActionResponse;
export type PostPy3ApiUserRegisterApiArg = {
  requestUserRegistrationA992424: RequestUserRegistration;
};
export type GetPy3ApiUserRoleByRoleApiResponse = unknown;
export type GetPy3ApiUserRoleByRoleApiArg = {
  role: string;
};
export type GetPy3ApiUserStatusApiResponse = /** status 200 OK */ UserStatusResponse;
export type GetPy3ApiUserStatusApiArg = {
  email: string;
};
export type PostPy3ApiUserVerifyTriggerApiResponse = /** status 200 OK */ ActionResponse;
export type PostPy3ApiUserVerifyTriggerApiArg = {
  verifyTriggerPayloadA992424: VerifyTriggerPayload;
};
export type PostPy3ApiUserVerifyApiResponse = /** status 200 OK */ ResponseVerifyUser;
export type PostPy3ApiUserVerifyApiArg = {
  requestVerifyUserA992424: RequestVerifyUser;
};
export type GetPy3ApiByAccountIdSubscriptionsApiResponse = /** status 200 OK */ SubscriptionList;
export type GetPy3ApiByAccountIdSubscriptionsApiArg = {
  accountId: string;
};
export type PostPy3ApiByAccountIdSubscriptionsApiResponse = unknown;
export type PostPy3ApiByAccountIdSubscriptionsApiArg = {
  accountId: string;
  requestSubscription3A00991: RequestSubscription;
};
export type DeletePy3ApiByAccountIdSubscriptionsAndSubscriptionIdApiResponse = unknown;
export type DeletePy3ApiByAccountIdSubscriptionsAndSubscriptionIdApiArg = {
  accountId: string;
  subscriptionId: number;
};
export type GetPy3ApiByObjectTypeAndEventIdApiResponse = /** status 200 OK */ EventResponse2;
export type GetPy3ApiByObjectTypeAndEventIdApiArg = {
  objectType: string;
  eventId: string;
};
export type PatchPy3ApiByObjectTypeAndEventIdApiResponse = /** status 200 OK */ EventFullResponse;
export type PatchPy3ApiByObjectTypeAndEventIdApiArg = {
  objectType: string;
  eventId: string;
  eventDetailPayload67108Ea: EventDetailPayload;
};
export type PostPy3ApiByObjectTypeAndEventIdCustomFieldsApiResponse =
  /** status 200 OK */ DummyResponse;
export type PostPy3ApiByObjectTypeAndEventIdCustomFieldsApiArg = {
  objectType: string;
  eventId: string;
  customFieldsPayload67108Ea: CustomFieldsPayload;
};
export type PostPy3ApiByObjectTypeAndEventIdUpdateBillingApiResponse =
  /** status 200 OK */ DummyResponse;
export type PostPy3ApiByObjectTypeAndEventIdUpdateBillingApiArg = {
  objectType: string;
  eventId: string;
  payloadEventBilling67108Ea: PayloadEventBilling;
};
export type PatchPy3ApiByObjectTypeAndEventIdUpdateInfoApiResponse =
  /** status 200 OK */ EventFullResponse;
export type PatchPy3ApiByObjectTypeAndEventIdUpdateInfoApiArg = {
  objectType: string;
  eventId: string;
  eventDetailPayload67108Ea: EventDetailPayload;
};
export type PostPy3ApiByObjectTypeAndEventIdUpdateTextApiResponse = unknown;
export type PostPy3ApiByObjectTypeAndEventIdUpdateTextApiArg = {
  objectType: string;
  eventId: string;
  updateTextPayload67108Ea: UpdateTextPayload;
};
export type PostPy3ApiByObjectTypeAndEventIdUpdateTicketsApiResponse =
  /** status 200 OK */ DummyResponse;
export type PostPy3ApiByObjectTypeAndEventIdUpdateTicketsApiArg = {
  objectType: string;
  eventId: string;
  eventUpdateTicketsPayload67108Ea: EventUpdateTicketsPayload;
};
export type PostPy3ErrorApiResponse = unknown;
export type PostPy3ErrorApiArg = {
  errorLogPayloadDfdcc98: ErrorLogPayload;
};
export type GetPy3ToByRouteApiResponse = unknown;
export type GetPy3ToByRouteApiArg = {
  route: string;
};
export type CustomFieldItem = {
  description?: string;
  label: string;
  required?: boolean;
  slug: string;
};
export type EventDiscount = {
  available_amount?: string;
  coupon_code?: string;
  discount_type: string;
  end_date?: string;
  is_combinable: boolean;
  is_coupon: boolean;
  is_percentage: boolean;
  is_public: boolean;
  is_time_limited: boolean;
  is_unlimited: boolean;
  label: string;
  min_bulk_ammount?: string;
  public: boolean;
  slug: string;
  start_date?: string;
  status: string;
  teaser?: string;
  ticket_kind_slug?: string;
  used_amount?: string;
  value: number;
};
export type NftStyleItem = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type TicketAddonKindItem = {
  available_amount?: number;
  available_order_batch: number[];
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type TicketKindItem = {
  addons: TicketAddonKindItem[];
  available_amount?: number;
  available_order_batch: number[];
  currency?: string;
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type EventPublicUrlsItem = {
  fb_url: string;
  header_url?: string;
  legal_url: string;
  li_url: string;
  org_legal_url?: string;
  org_url: string;
  page_url: string;
  pr_url: string;
  sitemap_url: string;
  tickets_url: string;
  tw_url: string;
};
export type EventResponse = {
  branding_enabled: boolean;
  calendar_attachment: boolean;
  collect_billing_info: boolean;
  collect_names: boolean;
  created: string;
  currency?: string;
  custom_fields: CustomFieldItem[];
  description?: string;
  description_seo?: string;
  description_text?: string;
  discounts: EventDiscount[];
  email_mandatory: boolean;
  end_date?: string;
  end_iso?: string;
  end_time?: string;
  fee_percentage?: number;
  full_dates: string;
  full_dates_times: string;
  google_maps_api_key?: string;
  has_hero: boolean;
  has_time: boolean;
  id: string;
  insurance_percentage?: number;
  insurance_purchasable: boolean;
  is_fee_excluded: boolean;
  is_nft_event: boolean;
  lang_code: string;
  location?: string;
  long_id: string;
  media: object;
  mode: string;
  name: string;
  names_mandatory: boolean;
  organiser?: string;
  paid_event: boolean;
  payment_methods: string[];
  paypal_client_id?: string;
  published?: string;
  reg_close_iso?: string;
  reg_open_iso?: string;
  seo_enabled: boolean;
  short_id: string;
  slug: string;
  start_date?: string;
  start_iso?: string;
  start_time?: string;
  status: string;
  stripe_pubkey?: string;
  theme_main_bg: string;
  theme_main_fg: string;
  theme_misc_bg: string;
  theme_misc_fg: string;
  ticket_kinds: TicketKindItem[];
  time_format_24: boolean;
  timezone: string;
  updated: string;
  urls: EventPublicUrlsItem;
  vat_country?: string;
  vat_enabled: boolean;
  vat_forced: boolean;
  vat_id?: string;
  vat_rate?: number;
};
export type EventListResponse = {
  items: EventResponse[];
};
export type BillingFormMeta = {
  braintree_token?: string;
  countries: {
    [key: string]: string;
  }[];
  currency?: string;
  current_country?: string;
  env: string;
  extra_euro_countries: {
    [key: string]: {
      [key: string]: string;
    };
  };
  home_vat_country?: string;
  months: string[];
  order_type: string;
  paypal_client_id?: string;
  pound_countries: {
    [key: string]: {
      [key: string]: string;
    };
  };
  preview: boolean;
  stripe_public_key?: string;
  vat_rates: {
    [key: string]: {
      [key: string]: string | number;
    };
  };
  years: string[];
};
export type EventBillingFormMetaResponse = {
  billing_meta: BillingFormMeta;
  success: boolean;
};
export type PrecheckResponse = {
  discounts_applied: object;
  items_available?: boolean;
  price_base?: number;
  price_fee?: number;
  price_insurance?: number;
  price_match?: boolean;
  price_total?: number;
  price_vat?: number;
};
export type PrecheckPayload = {
  country: string;
  coupon_code?: string;
  discount_slug?: string;
  has_insurance: boolean;
  ordered_merchandise: {
    [key: string]: number;
  };
  ordered_tickets: {
    [key: string]: number;
  };
  price: number;
  vat_id?: string;
};
export type OrderLightItem = {
  address_1?: string;
  address_2?: string;
  billing_enabled: boolean;
  billing_zip?: string;
  city: string;
  country: string;
  coupon?: string;
  created_at: string;
  currency?: string;
  email: string;
  first_name?: string;
  full_name: string;
  has_insurance?: boolean;
  id: string;
  insurance_price?: number;
  invoice?: string;
  is_nft_order: boolean;
  issued_tickets: boolean;
  last_name?: string;
  payment_method?: string;
  price: number;
  short_id: string;
  state?: string;
  status: string;
  total_merchandise: number;
  total_price: number;
  total_tickets: number;
  updated_at: string;
  vat_id?: string;
  vat_rate?: number;
  vat_tax?: number;
};
export type EventOrdersResponse = {
  items: OrderLightItem[];
  success: boolean;
};
export type NftStyleItem2 = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type TicketAddonKindItem2 = {
  available_amount?: number;
  available_order_batch: number[];
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem2;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type TicketKindItem2 = {
  addons: TicketAddonKindItem2[];
  available_amount?: number;
  available_order_batch: number[];
  currency?: string;
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem2;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type TicketKindsResponse = {
  items: TicketKindItem2[];
};
export type CustomFieldItem2 = {
  description?: string;
  label: string;
  required?: boolean;
  slug: string;
};
export type EventDiscount2 = {
  available_amount?: string;
  coupon_code?: string;
  discount_type: string;
  end_date?: string;
  is_combinable: boolean;
  is_coupon: boolean;
  is_percentage: boolean;
  is_public: boolean;
  is_time_limited: boolean;
  is_unlimited: boolean;
  label: string;
  min_bulk_ammount?: string;
  public: boolean;
  slug: string;
  start_date?: string;
  status: string;
  teaser?: string;
  ticket_kind_slug?: string;
  used_amount?: string;
  value: number;
};
export type NftStyleItem3 = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type TicketAddonKindItem3 = {
  available_amount?: number;
  available_order_batch: number[];
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem3;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type TicketKindItem3 = {
  addons: TicketAddonKindItem3[];
  available_amount?: number;
  available_order_batch: number[];
  currency?: string;
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem3;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type EventPublicUrlsItem2 = {
  fb_url: string;
  header_url?: string;
  legal_url: string;
  li_url: string;
  org_legal_url?: string;
  org_url: string;
  page_url: string;
  pr_url: string;
  sitemap_url: string;
  tickets_url: string;
  tw_url: string;
};
export type EventResponse2 = {
  branding_enabled: boolean;
  calendar_attachment: boolean;
  collect_billing_info: boolean;
  collect_names: boolean;
  created: string;
  currency?: string;
  custom_fields: CustomFieldItem2[];
  description?: string;
  description_seo?: string;
  description_text?: string;
  discounts: EventDiscount2[];
  email_mandatory: boolean;
  end_date?: string;
  end_iso?: string;
  end_time?: string;
  fee_percentage?: number;
  full_dates: string;
  full_dates_times: string;
  google_maps_api_key?: string;
  has_hero: boolean;
  has_time: boolean;
  id: string;
  insurance_percentage?: number;
  insurance_purchasable: boolean;
  is_fee_excluded: boolean;
  is_nft_event: boolean;
  lang_code: string;
  location?: string;
  long_id: string;
  media: object;
  mode: string;
  name: string;
  names_mandatory: boolean;
  organiser?: string;
  paid_event: boolean;
  payment_methods: string[];
  paypal_client_id?: string;
  published?: string;
  reg_close_iso?: string;
  reg_open_iso?: string;
  seo_enabled: boolean;
  short_id: string;
  slug: string;
  start_date?: string;
  start_iso?: string;
  start_time?: string;
  status: string;
  stripe_pubkey?: string;
  theme_main_bg: string;
  theme_main_fg: string;
  theme_misc_bg: string;
  theme_misc_fg: string;
  ticket_kinds: TicketKindItem3[];
  time_format_24: boolean;
  timezone: string;
  updated: string;
  urls: EventPublicUrlsItem2;
  vat_country?: string;
  vat_enabled: boolean;
  vat_forced: boolean;
  vat_id?: string;
  vat_rate?: number;
};
export type EventUrlsItem = {
  background_url?: string;
  header_url?: string;
  legal_url: string;
  org_legal_url?: string;
  org_url: string;
  page_url: string;
  page_url_new: string;
  preview_url: string;
  tickets_embed_url: string;
  tickets_url: string;
};
export type EventFullExport = {
  branding_enabled: boolean;
  calendar_attachment: boolean;
  collect_names: boolean;
  created: string;
  currency?: string;
  end_date?: string;
  end_iso?: string;
  end_time?: string;
  fee_percentage?: number;
  full_dates: string;
  full_dates_times: string;
  google_maps_api_key?: string;
  has_hero: boolean;
  has_time: boolean;
  id: string;
  is_fee_excluded: boolean;
  is_nft_event: boolean;
  location?: string;
  long_id: string;
  mode: string;
  name: string;
  organiser?: string;
  paid_event: boolean;
  paypal_client_id?: string;
  published?: string;
  reg_close_iso?: string;
  reg_open_iso?: string;
  seo_enabled: boolean;
  short_id: string;
  slug: string;
  start_date?: string;
  start_iso?: string;
  start_time?: string;
  status: string;
  stripe_pubkey?: string;
  theme_main_bg: string;
  theme_main_fg: string;
  theme_misc_bg: string;
  theme_misc_fg: string;
  time_format_24: boolean;
  timezone: string;
  updated: string;
  urls: EventUrlsItem;
  vat_country?: string;
  vat_enabled: boolean;
  vat_forced: boolean;
  vat_id?: string;
  vat_rate?: number;
};
export type EventFullResponse = {
  data: EventFullExport;
  message?: string;
  success: boolean;
};
export type EventDetailPayload = {
  agenda_planner: boolean;
  branding_enabled: boolean;
  calendar_attachment: boolean;
  checkin_enabled: boolean;
  colors: object;
  country?: string;
  end_date: string;
  end_time?: string;
  hide_soldout: boolean;
  language: string;
  legal_link?: string;
  location?: string;
  map_enabled: boolean;
  name?: string;
  reg_close_date?: string;
  reg_close_time?: string;
  reg_open_date?: string;
  reg_open_time?: string;
  seo_enabled: boolean;
  share_buttons: boolean;
  start_date: string;
  start_time?: string;
  tags: any[];
  time_format_24: boolean;
  timezone: string;
  use_feapp?: boolean;
};
export type AttendeeLightItem = {
  checked_in: boolean;
  created: string;
  email: string;
  full_name: string;
  id: string;
  invited: boolean;
  order_id: string;
  tickets_num: number;
};
export type AttendeeListResponse = {
  attendees: AttendeeLightItem[];
  next_cursor?: string;
  prev_cursor?: string;
  success: boolean;
};
export type AttendeesPayload = {
  format: "csv";
  value: string;
};
export type ReminderResponse = {
  content: string;
  id: number;
  is_done: boolean;
  subject: string;
  trigger_datetime: string;
  trigger_type: string;
};
export type ReminderList = {
  items: ReminderResponse[];
};
export type ReminderRequest = {
  content: string;
  subject: string;
  trigger_datetime: string;
  trigger_type: string;
};
export type NftAssetItem = {
  id: string;
  ipfs_url?: string;
  type: string;
  url?: string;
};
export type NftDataPublicItem = {
  asset: NftAssetItem;
  date: string;
  event_name: string;
  seat: string;
  sector: string;
  ticket_name: string;
};
export type NftPayloadDataItem = {
  organizer: object;
  public_data: NftDataPublicItem;
  user: object;
};
export type NftResponseDataItem = {
  error?: object;
  minting_state: string;
  nft_address: string;
  public_data: object;
};
export type PurchasedMerchandiseItem = {
  account: string;
  created_at: string;
  currency?: string;
  description?: string;
  event: string;
  is_nft_ticket: boolean;
  label: string;
  order: string;
  price?: number;
  status: "reserved" | "purchased" | "deleted" | "canceled";
  ticket_kind_slug: string;
  updated_at: string;
};
export type NftStyleItem4 = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type TicketItem = {
  account: string;
  assigned: boolean;
  attendee?: string;
  attendee_name?: string;
  created_at: string;
  currency?: string;
  description?: string;
  event: string;
  id: string;
  is_nft_ticket: boolean;
  label: string;
  order: string;
  price?: number;
  short_id: string;
  status: "available" | "reserved" | "purchased" | "deleted" | "canceled";
  ticket_kind_slug: string;
  updated_at: string;
};
export type NftDataItem = {
  api_payload?: NftPayloadDataItem;
  api_response?: NftResponseDataItem;
  created_at: string;
  id: string;
  merchandise?: PurchasedMerchandiseItem;
  nft_url?: string;
  status: string;
  style?: NftStyleItem4;
  ticket?: TicketItem;
  updated_at: string;
};
export type NftDataListResponse = {
  items: NftDataItem[];
};
export type NftAssetItem2 = {
  id: string;
  ipfs_url?: string;
  type: string;
  url?: string;
};
export type NftDataPublicItem2 = {
  asset: NftAssetItem2;
  date: string;
  event_name: string;
  seat: string;
  sector: string;
  ticket_name: string;
};
export type NftPayloadDataItem2 = {
  organizer: object;
  public_data: NftDataPublicItem2;
  user: object;
};
export type NftResponseDataItem2 = {
  error?: object;
  minting_state: string;
  nft_address: string;
  public_data: object;
};
export type PurchasedMerchandiseItem2 = {
  account: string;
  created_at: string;
  currency?: string;
  description?: string;
  event: string;
  is_nft_ticket: boolean;
  label: string;
  order: string;
  price?: number;
  status: "reserved" | "purchased" | "deleted" | "canceled";
  ticket_kind_slug: string;
  updated_at: string;
};
export type NftStyleItem5 = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type TicketItem2 = {
  account: string;
  assigned: boolean;
  attendee?: string;
  attendee_name?: string;
  created_at: string;
  currency?: string;
  description?: string;
  event: string;
  id: string;
  is_nft_ticket: boolean;
  label: string;
  order: string;
  price?: number;
  short_id: string;
  status: "available" | "reserved" | "purchased" | "deleted" | "canceled";
  ticket_kind_slug: string;
  updated_at: string;
};
export type NftDataResponse = {
  api_payload?: NftPayloadDataItem2;
  api_response?: NftResponseDataItem2;
  created_at: string;
  id: string;
  merchandise?: PurchasedMerchandiseItem2;
  nft_url?: string;
  status: string;
  style?: NftStyleItem5;
  ticket?: TicketItem2;
  updated_at: string;
};
export type NftWithdrawalResponse = {
  created_at: string;
  description?: string;
  destination_wallet_address: string;
  id: string;
  initiator: string;
  nft_data: string;
  status: string;
  updated_at: string;
  withdrawal_request_id?: string;
};
export type NftWithdrawalRequestPayload = {
  description?: string;
  destination_wallet_address: string;
};
export type NftWithdrawalDataItem = {
  created_at: string;
  description?: string;
  destination_wallet_address: string;
  id: string;
  initiator: string;
  nft_data: string;
  status: string;
  updated_at: string;
  withdrawal_request_id?: string;
};
export type NftWithdrawalListResponse = {
  items: NftWithdrawalDataItem[];
};
export type NftWidthrawalConfirmationPayload = {
  confirmation_code: number;
};
export type ActionResponse = {
  message?: string;
  success: boolean;
};
export type NoncePayload = {
  nonce: string;
};
export type OrderAttendeeItem = {
  checked_in: boolean;
  email?: string;
  first_name?: string;
  id: string;
  invited: boolean;
  last_name?: string;
  print_tickets_url?: string;
  short_id: string;
  tickets: object[];
};
export type NftStyleItem6 = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type OrderNftTicketItem = {
  created_at: string;
  id: string;
  nft_url: string;
  order: string;
  owner: string;
  status: string;
  style: NftStyleItem6;
  ticket: string;
  updated_at: string;
};
export type OrderTicketDataItem = {
  addons?: object[];
  available_order_batch: number[];
  currency?: string;
  description: string;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  price: number;
  slug: string;
  status: string;
};
export type OrderDetailResponse = {
  address_1?: string;
  address_2?: string;
  attendees: OrderAttendeeItem[];
  billing_enabled: boolean;
  billing_zip?: string;
  city: string;
  country: string;
  coupon?: string;
  created_at: string;
  currency?: string;
  email: string;
  event: string;
  first_name?: string;
  full_name: string;
  has_insurance?: boolean;
  id: string;
  insurance_price?: number;
  invoice?: string;
  is_nft_order: boolean;
  issued_tickets: boolean;
  last_name?: string;
  nfts: OrderNftTicketItem[];
  payment_method?: string;
  price: number;
  short_id: string;
  state?: string;
  status: string;
  ticket_data?: OrderTicketDataItem[];
  total_merchandise: number;
  total_price: number;
  total_tickets: number;
  updated_at: string;
  vat_id?: string;
  vat_rate?: number;
  vat_tax?: number;
};
export type AttendeeTicketItem = {
  id: string;
  label: string;
  slug: string;
};
export type AttendeeResponse = {
  checked_in: boolean;
  custom_fields?: object;
  email?: string;
  first_name?: string;
  id: string;
  invited: boolean;
  last_name?: string;
  tickets: AttendeeTicketItem[];
};
export type AttendeeTicketItem2 = {
  id: string;
  label: string;
  slug: string;
};
export type AttendeeUpdatePayload = {
  custom_fields?: object;
  email?: string;
  first_name?: string;
  last_name?: string;
  tickets: AttendeeTicketItem2[];
};
export type PurchasedMerchandiseItemWithNftItem = {
  account: string;
  created_at: string;
  currency?: string;
  description?: string;
  event: string;
  is_nft_ticket: boolean;
  label: string;
  order: string;
  price?: number;
  status: "reserved" | "purchased" | "deleted" | "canceled";
  ticket_kind_slug: string;
  updated_at: string;
};
export type MerchandiseListResponse = {
  items: PurchasedMerchandiseItemWithNftItem[];
};
export type AttendeeItem = {
  account: string;
  checked_in: boolean;
  checked_in_tickets?: object;
  coupon_code?: string;
  created_at: string;
  email?: string;
  event: string;
  fields?: object;
  first_name?: string;
  invited: boolean;
  is_orderer: boolean;
  last_name?: string;
  meta: object;
  order: string;
  order_currency?: string;
  order_price: number;
  order_total_price: number;
  order_vat_rate?: number;
  order_vat_tax?: number;
  payment_method?: string;
  security_code: string;
  status?: string;
  tickets?: any[];
  tickets_num: number;
  updated_at: string;
};
export type TicketItemWithNftItem = {
  account: string;
  assigned: boolean;
  attendee?: AttendeeItem;
  attendee_name?: string;
  created_at: string;
  currency?: string;
  description?: string;
  event: string;
  id: string;
  is_nft_ticket: boolean;
  label: string;
  order: string;
  price?: number;
  short_id: string;
  status: "available" | "reserved" | "purchased" | "deleted" | "canceled";
  ticket_kind_slug: string;
  updated_at: string;
};
export type TicketListResponse = {
  items: TicketItemWithNftItem[];
};
export type NftStyleItem7 = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type TicketAddonKindItem4 = {
  available_amount?: number;
  available_order_batch: number[];
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem7;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type TicketKindItem4 = {
  addons: TicketAddonKindItem4[];
  available_amount?: number;
  available_order_batch: number[];
  currency?: string;
  description: string;
  is_nft_ticket?: boolean;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem7;
  price: number;
  slug: string;
  status: string;
  type: string;
};
export type EventPublicUrlsItem3 = {
  fb_url: string;
  header_url?: string;
  legal_url: string;
  li_url: string;
  org_legal_url?: string;
  org_url: string;
  page_url: string;
  pr_url: string;
  sitemap_url: string;
  tickets_url: string;
  tw_url: string;
};
export type EventPublicExport = {
  branding_enabled: boolean;
  calendar_attachment: boolean;
  collect_names: boolean;
  created: string;
  currency?: string;
  description?: string;
  description_seo?: string;
  description_text?: string;
  end_date?: string;
  end_iso?: string;
  end_time?: string;
  fee_percentage?: number;
  full_dates: string;
  full_dates_times: string;
  google_maps_api_key?: string;
  has_hero: boolean;
  has_time: boolean;
  id: string;
  insurance_percentage?: number;
  insurance_purchasable: boolean;
  is_fee_excluded: boolean;
  is_nft_event: boolean;
  lang_code: string;
  location?: string;
  long_id: string;
  media: object;
  mode: string;
  name: string;
  organiser?: string;
  paid_event: boolean;
  paypal_client_id?: string;
  published?: string;
  reg_close_iso?: string;
  reg_open_iso?: string;
  seo_enabled: boolean;
  short_id: string;
  slug: string;
  start_date?: string;
  start_iso?: string;
  start_time?: string;
  status: string;
  stripe_pubkey?: string;
  theme_main_bg: string;
  theme_main_fg: string;
  theme_misc_bg: string;
  theme_misc_fg: string;
  ticket_kinds: TicketKindItem4[];
  time_format_24: boolean;
  timezone: string;
  updated: string;
  urls: EventPublicUrlsItem3;
  vat_country?: string;
  vat_enabled: boolean;
  vat_forced: boolean;
  vat_id?: string;
  vat_rate?: number;
};
export type OrderTicketDataItem2 = {
  addons?: object[];
  available_order_batch: number[];
  currency?: string;
  description: string;
  kind_name: string;
  label: string;
  max_order_size: number;
  min_order_size: number;
  price: number;
  slug: string;
  status: string;
};
export type OrderDetailItem = {
  address_1?: string;
  address_2?: string;
  billing_enabled: boolean;
  billing_zip?: string;
  city: string;
  country: string;
  coupon?: string;
  created_at: string;
  currency?: string;
  email: string;
  event: string;
  first_name?: string;
  full_name: string;
  has_insurance?: boolean;
  id: string;
  insurance_price?: number;
  invoice?: string;
  is_nft_order: boolean;
  issued_tickets: boolean;
  last_name?: string;
  payment_method?: string;
  price: number;
  short_id: string;
  state?: string;
  status: string;
  ticket_data?: OrderTicketDataItem2[];
  total_merchandise: number;
  total_price: number;
  total_tickets: number;
  updated_at: string;
  vat_id?: string;
  vat_rate?: number;
  vat_tax?: number;
};
export type OrderItem = {
  event: EventPublicExport;
  order: OrderDetailItem;
};
export type OrderListResponse = {
  items: OrderItem[];
};
export type OrderPaymentResponse = {
  items_available?: boolean;
  message?: string;
  order_id?: string;
  payment_secret?: string;
  price_match?: boolean;
  success?: boolean;
};
export type ClientEnum = "feapp" | "py2app";
export type OrderPaymentPayload = {
  attendee_tickets: object[];
  city: string;
  client: ClientEnum;
  country: string;
  coupon_code?: string;
  discount_slug?: string;
  email: string;
  event_id: string;
  first_name?: string;
  has_insurance: boolean;
  last_name?: string;
  nonce: string;
  ordered_merchandise: {
    [key: string]: number;
  };
  ordered_tickets: {
    [key: string]: number;
  };
  payment_method?: string;
  price: number;
  vat_id?: string;
};
export type ResponsePaymentIntent = {
  intent_secret?: string;
  message?: string;
  success: boolean;
};
export type PayloadPaymentIntent = {
  event_id: string;
  total_order_price: number;
};
export type ResponsePaypalOrder = {
  items_available: boolean;
  payment_id?: string;
  price_match: boolean;
  success: boolean;
};
export type PaypalOrderPayload = {
  country: string;
  coupon_code?: string;
  discount_slug?: string;
  event_id: string;
  has_insurance: boolean;
  ordered_merchandise: {
    [key: string]: number;
  };
  ordered_tickets: {
    [key: string]: number;
  };
  price: number;
  vat_id?: string;
};
export type RequestCustomerInfo = {
  email: string;
  name: string;
};
export type RequestPurchaseConfirm = {
  account_sid?: string;
  customer: RequestCustomerInfo;
  event_sid?: string;
  intent_id: string;
  product_id: string;
};
export type ResponsePaymentAction = {
  customer_id?: string;
  intent_secret?: string;
  message?: string;
  success: boolean;
};
export type RequestCustomerInfo2 = {
  email: string;
  name: string;
};
export type RequestPayment = {
  amount: number;
  currency: string;
};
export type RequestPaymentAction = {
  account_sid?: string;
  customer: RequestCustomerInfo2;
  event_sid?: string;
  gate?: "stripe" | "stripe_new";
  intent_id?: string;
  ordered_tickets?: object;
  payment?: RequestPayment;
  product_id?: string;
  subscription_id?: string;
};
export type TicketDetailResponse = {
  account: string;
  assigned: boolean;
  attendee?: string;
  attendee_name?: string;
  created: string;
  created_at: string;
  currency?: string;
  description?: string;
  event: string;
  id: string;
  is_nft_ticket: boolean;
  label: string;
  order: string;
  price: number;
  short_id: string;
  status: string;
  ticket_kind_slug: string;
  updated: string;
  updated_at: string;
};
export type UserRoleItem = "org" | "att";
export type UserProfile = {
  email: string;
  first_name?: string;
  is_active: boolean;
  last_account_id?: string;
  last_name?: string;
  last_role?: UserRoleItem;
  user_id: number;
};
export type ResponseUserProfile = {
  message?: string;
  success: boolean;
  user?: UserProfile;
};
export type RequestLogin = {
  next?: string;
  password: string;
  username: string;
};
export type NotificationResponse = {
  has_unread: boolean;
  items: any[];
  read_at: string;
};
export type NotificationReadResponse = {
  read_at: string;
};
export type RequestPassword = {
  password_new: string;
  password_old: string;
};
export type RequestPasswordReset = {
  username: string;
};
export type RequestUserRegistration = {
  email: string;
  first_name?: string;
  last_name?: string;
  mailing_list?: boolean;
};
export type UserStatusResponse = {
  is_created: boolean;
  is_verified: boolean;
};
export type VerifyTriggerPayload = {
  email: string;
};
export type ResponseVerifyUser = {
  message: string;
  success: boolean;
  user?: object;
};
export type RequestVerifyUser = {
  password1: string;
  password2: string;
  token: string;
  user_id: string;
};
export type ResponseSubscriptionProduct = {
  id: string;
  label: string;
  origin: string;
  origin_id: string;
  origin_key: string;
  subscription?: object;
};
export type SubscriptionList = {
  items: ResponseSubscriptionProduct[];
};
export type RequestCustomerInfo3 = {
  email: string;
  name: string;
};
export type RequestSubscription = {
  billing_details: RequestCustomerInfo3;
  fingerprint: object;
  payment_method: string;
  payment_method_id: string;
  product_id: string;
};
export type DummyResponse = object;
export type CustomFieldItem3 = {
  description?: string;
  label: string;
  required?: boolean;
  slug: string;
};
export type CustomFieldsBase = {
  collect_names: boolean;
  editable_names: boolean;
  email_mandatory: boolean;
  names_mandatory: boolean;
};
export type CustomFieldsPayload = {
  custom_fields: CustomFieldItem3[];
  data: CustomFieldsBase;
};
export type PayloadBillingData = {
  accept_payments: object;
  cancel_enabled: boolean;
  collect_billing_info: boolean;
  coupon_enabled: boolean;
  currency?: string;
  insurance_enabled: boolean;
  is_fee_excluded: boolean;
  on_sale: boolean;
  paid_event: boolean;
  threeds_enabled: boolean;
  vat_abbrev: string;
  vat_country?: string;
  vat_enabled?: boolean;
  vat_forced: boolean;
  vat_id?: string;
  vat_rate?: number;
};
export type PayloadEventBilling = {
  billing: PayloadBillingData;
};
export type UpdateTextPayload = {
  description: string;
  description_text: string;
};
export type NftStyleItem8 = {
  account?: string;
  id: string;
  mimetype: string;
  name?: string;
  status: string;
  suffix: string;
  url: string;
};
export type TicketAddonKindItemPayload = {
  available_amount?: number;
  description: string;
  is_nft_ticket?: boolean;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem8;
  price: number;
  slug: string;
  status: string;
};
export type TicketKindItemPayload = {
  addons: TicketAddonKindItemPayload[];
  available_amount?: number;
  description: string;
  is_nft_ticket?: boolean;
  label: string;
  max_order_size: number;
  min_order_size: number;
  nft_style?: NftStyleItem8;
  price: number;
  slug: string;
  status: string;
  type?: string;
};
export type EventUpdateTicketsPayload = {
  tickets: TicketKindItemPayload[];
  update_slug?: string;
};
export type ErrorLogPayload = {
  context: object;
  eventTime?: string;
  extras?: object;
  message?: string;
  serviceContext: object;
};
export const {
  useGetAhWarmupQuery,
  useGetPy3ApiEventQuery,
  useGetPy3ApiEventByEventIdBillingQuery,
  usePostPy3ApiEventByEventIdBillingMutation,
  usePostPy3ApiEventByEventIdOrderPrecheckMutation,
  useGetPy3ApiEventByEventIdOrdersQuery,
  useGetPy3ApiEventByEventIdTicketsQuery,
  useGetPy3ApiEventsQuery,
  useGetPy3ApiEventsByEventIdQuery,
  usePatchPy3ApiEventsByEventIdMutation,
  useGetPy3ApiEventsByEventIdAttendeesQuery,
  usePostPy3ApiEventsByEventIdAttendeesMutation,
  useGetPy3ApiEventsByEventIdAttendeesCsvQuery,
  useGetPy3ApiEventsByEventIdEmailsQuery,
  usePostPy3ApiEventsByEventIdEmailsMutation,
  useDeletePy3ApiEventsByEventIdEmailsAndEmailIdMutation,
  usePatchPy3ApiEventsByEventIdEmailsAndEmailIdMutation,
  usePostPy3ApiEventsByEventIdOrderPrecheckMutation,
  useGetPy3ApiEventsByEventIdOrdersCsvQuery,
  useGetPy3ApiEventsByEventIdTicketsQuery,
  usePostPy3ApiLogintokenMutation,
  usePostPy3ApiLogintokenPopMutation,
  useGetPy3ApiNftQuery,
  useGetPy3ApiNftByNftIdQuery,
  usePostPy3ApiNftByNftIdWithdrawalMutation,
  useGetPy3ApiNftByNftIdWithdrawalsQuery,
  useGetPy3ApiNftsWithdrawalByWithdrawalIdQuery,
  usePostPy3ApiNftsWithdrawalByWithdrawalIdConfirmMutation,
  usePostPy3ApiNftsByNftIdRetryMutation,
  useGetPy3ApiOrderByOrderIdQuery,
  usePatchPy3ApiOrderByOrderIdMutation,
  usePatchPy3ApiOrderByOrderIdAttendeeAndAttendeeIdMutation,
  useGetPy3ApiOrderByOrderIdMerchandiseQuery,
  useGetPy3ApiOrderByOrderIdTicketsQuery,
  useGetPy3ApiOrdersQuery,
  usePostPy3ApiOrdersMutation,
  useGetPy3ApiOrdersByOrderIdQuery,
  usePatchPy3ApiOrdersByOrderIdMutation,
  useDeletePy3ApiOrdersByOrderIdAndAttendeeIdTicketSlugMutation,
  usePostPy3ApiPaymentsAnonymousIntentMutation,
  usePostPy3ApiPaymentsPaypalOrderMutation,
  usePostPy3ApiPaymentsPurchaseConfirmMutation,
  usePostPy3ApiPaymentsByActionMutation,
  usePostPy3ApiRefundsMutation,
  useGetPy3ApiStatementsQuery,
  useGetPy3ApiTicketByTicketIdQuery,
  usePostPy3ApiUserLoginMutation,
  usePostPy3ApiUserLogoutMutation,
  useGetPy3ApiUserNotificationsQuery,
  usePostPy3ApiUserNotificationsMutation,
  usePostPy3ApiUserPasswordChangeMutation,
  usePostPy3ApiUserPasswordResetMutation,
  useGetPy3ApiUserProfileMutation,
  usePostPy3ApiUserProfileMutation,
  useGetPy3ApiUserRefreshMutation,
  usePostPy3ApiUserRegisterMutation,
  useGetPy3ApiUserRoleByRoleQuery,
  useGetPy3ApiUserStatusMutation,
  usePostPy3ApiUserVerifyTriggerMutation,
  usePostPy3ApiUserVerifyMutation,
  useGetPy3ApiByAccountIdSubscriptionsQuery,
  usePostPy3ApiByAccountIdSubscriptionsMutation,
  useDeletePy3ApiByAccountIdSubscriptionsAndSubscriptionIdMutation,
  useGetPy3ApiByObjectTypeAndEventIdQuery,
  usePatchPy3ApiByObjectTypeAndEventIdMutation,
  usePostPy3ApiByObjectTypeAndEventIdCustomFieldsMutation,
  usePostPy3ApiByObjectTypeAndEventIdUpdateBillingMutation,
  usePatchPy3ApiByObjectTypeAndEventIdUpdateInfoMutation,
  usePostPy3ApiByObjectTypeAndEventIdUpdateTextMutation,
  usePostPy3ApiByObjectTypeAndEventIdUpdateTicketsMutation,
  usePostPy3ErrorMutation,
  useGetPy3ToByRouteQuery,
} = injectedRtkApi;
