import {remove} from "diacritics";
import {isEmpty, toLower} from "ramda";
import {useKey, useKeys} from "rooks";

import * as React from "react";
import {useMemo} from "react";
import {FieldError} from "react-hook-form";

import {resolveChakraSpacing} from "@ef/utils";

import {ChevronDownIcon} from "@chakra-ui/icons";
import {
  Box,
  InputProps as ChakraInputProps,
  useMergeRefs,
  useStyleConfig,
  Text,
  Popover,
  PopoverTrigger,
  InputGroup,
  Input as ChakraInput,
  InputRightElement,
  PopoverContent,
  PopoverBody,
  Collapse,
  Stack,
  Divider,
} from "@chakra-ui/react";

import useClickOutside from "../hooks/useClickOutside";

export type TypingSelectProps = Omit<ChakraInputProps, "type"> & {
  label?: React.ReactNode;
  error?: FieldError;
  required?: boolean;
  dataTest?: string;
  customError?: string;
  options?: Array<{name: string; value: string}>;
  hideTyping?: boolean;
  labelColor?: string;
};

const TypingSelect = React.forwardRef<HTMLInputElement, TypingSelectProps>(
  (
    {label, hideTyping, error, labelColor, options = [], required, dataTest, customError, ...props},
    ref
  ) => {
    const inputStyles = useStyleConfig("CustomInput", {});
    const [isOpen, setOpen] = React.useState(false);
    const inputLocalRef = React.useRef(null);
    const inputRef = useMergeRefs(inputLocalRef, ref);
    const [upDownActiveIndex, setUpDownActiveIndex] = React.useState<number>(0);

    const [localInputValue, setLocalInputValue] = React.useState<string>(
      props?.value?.toString() || props?.defaultValue?.toString() || ""
    );

    const popperRef = React.useRef(null);

    const closeModal = () => {
      setOpen(false);
      setUpDownActiveIndex(0);
    };

    useClickOutside(
      popperRef,
      () => {
        closeModal();
      },
      [inputLocalRef]
    );

    const {rest: restProps, spacing} = resolveChakraSpacing<
      TypingSelectProps,
      TypingSelectProps,
      TypingSelectProps
    >(props);
    const {onFocus, onChange, ...restInputProps} = restProps;

    const filteredOptions = useMemo(
      () =>
        hideTyping
          ? options
          : options.filter((x) =>
              remove(toLower(x.name)).includes(remove(toLower(localInputValue)))
            ),
      [options, localInputValue, hideTyping]
    );

    useKey(
      ["Enter"],
      () => {
        inputLocalRef.current.value = filteredOptions[upDownActiveIndex].name || "";
        //@ts-expect-error hack hue to form hook subscription
        onChange && onChange({target: inputLocalRef.current});
        closeModal();
      },
      {
        target: inputLocalRef,
        when: isOpen,
      }
    );

    useKey(
      [40, 9],
      (e) => {
        if (upDownActiveIndex + 1 >= filteredOptions.length) {
          return closeModal();
        }
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        setUpDownActiveIndex((x) =>
          x + 1 >= filteredOptions.length ? filteredOptions.length - 1 : x + 1
        );
      },
      {when: isOpen}
    );

    useKeys(
      //@ts-ignore
      [16, 9],
      (e) => {
        if (upDownActiveIndex - 1 < 0) {
          return closeModal();
        }
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        setUpDownActiveIndex((x) => (x - 1 <= 0 ? 0 : x - 1));
      },
      {when: isOpen}
    );

    useKey(
      38,
      (e) => {
        if (upDownActiveIndex - 1 < 0) {
          return closeModal();
        }
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        setUpDownActiveIndex((x) => (x - 1 <= 0 ? 0 : x - 1));
      },
      {when: isOpen}
    );

    React.useEffect(() => {
      if (isOpen) {
        document.querySelector('[data-test="select#body"]')?.scroll({
          top:
            //@ts-expect-error fix
            (document.querySelector(`[data-test="select#item[${upDownActiveIndex}]"]`)?.offsetTop ||
              0) - 155,
          behavior: "smooth", //auto, smooth, initial, inherit
        });
      }
    }, [upDownActiveIndex, isOpen]);

    return (
      <Box {...spacing}>
        {label && React.isValidElement(label) ? (
          label
        ) : (
          <Text
            variant="form-label"
            color={Boolean(error) ? "ef-red" : labelColor ? labelColor : "ef-black"}
            size="small"
          >
            {label}
            {(required || props.isRequired) && (
              <Text as="span" pl="3px" color="ef-red">
                *
              </Text>
            )}
          </Text>
        )}
        <Popover
          isOpen={isOpen}
          autoFocus={false}
          closeOnBlur={false}
          placement="bottom-start"
          isLazy
          matchWidth
        >
          <PopoverTrigger>
            <InputGroup mb="5px">
              <ChakraInput
                ref={inputRef}
                type="text"
                sx={inputStyles}
                borderColor={Boolean(error) ? "ef-red" : "ef-border"}
                data-test={dataTest || "select-input-custom"}
                {...restInputProps}
                onFocus={(e) => {
                  onFocus && onFocus(e);
                  setOpen(true);
                }}
                onChange={(e) => {
                  onChange && onChange(e);
                  setLocalInputValue(e.target.value);
                }}
              />

              <InputRightElement>
                <ChevronDownIcon boxSize="24px" _hover={{cursor: "pointer"}} />
              </InputRightElement>
            </InputGroup>
          </PopoverTrigger>
          <PopoverContent w="100%" mt="-5px">
            <Box
              boxSize="8px"
              border="1px solid"
              borderTopColor="gray.200"
              borderLeftColor="gray.200"
              borderRightColor="white"
              borderBottomColor="white"
              mt="-5px"
              ml="20px"
              bg="white"
              transform="rotate(-315deg)"
            />

            <PopoverBody p="0" ref={popperRef} _focus={{boxShadow: "0"}}>
              {!hideTyping && (
                <Box p="10px">
                  <Collapse in={Boolean(!localInputValue)} animateOpacity>
                    <Text fontSize="15px" lineHeight="24px" color="ef-border" mb="10px">
                      Start typing or select below
                    </Text>
                    <Divider mb="10px" />
                  </Collapse>
                </Box>
              )}
              <Collapse in={!isEmpty(filteredOptions)} animateOpacity>
                <Stack
                  spacing="0"
                  maxH={["150px", "200px", "200px"]}
                  overflowX="auto"
                  data-test="select#body"
                >
                  {filteredOptions.map((x, i) => (
                    <Box
                      pl="10px"
                      w="100%"
                      key={x.name}
                      bg={upDownActiveIndex === i ? "ef-divider" : undefined}
                      _hover={{bg: "ef-gray-light", cursor: "pointer"}}
                      onClick={() => {
                        if (hideTyping) {
                          inputLocalRef.current.value = x.name;
                          //@ts-expect-error hack hue to form hook subscription
                          onChange && onChange({target: inputLocalRef.current});
                          closeModal();
                        }
                      }}
                      py="0.3rem"
                    >
                      <Text
                        w="100%"
                        data-test={`select#item[${i}]`}
                        fontSize="15px"
                        lineHeight="24px"
                        color="ef-black"
                        onClick={() => {
                          if (!hideTyping) {
                            inputLocalRef.current.value = x.name;
                            //@ts-expect-error hack hue to form hook subscription
                            onChange && onChange({target: inputLocalRef.current});
                            closeModal();
                          }
                        }}
                      >
                        {hideTyping ? (
                          x.name
                        ) : (
                          <>
                            <b>{x.name.slice(0, localInputValue.length)}</b>
                            {x.name.slice(localInputValue.length, x.name.length)}
                          </>
                        )}
                      </Text>
                    </Box>
                  ))}
                </Stack>
              </Collapse>

              {!hideTyping && (
                <Box p="10px">
                  <Collapse in={isEmpty(filteredOptions)} animateOpacity>
                    <Text fontSize="15px" lineHeight="24px" color="ef-black" fontWeight="bold">
                      Select an item listed in the drop down menu.
                    </Text>
                  </Collapse>
                </Box>
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>

        {/* <Collapse in={Boolean(error?.message || customError)} animateOpacity>
          {(error?.message || customError) && (
            <Stack direction="row" align="center" w="fit-content" pt="4px">
              <Box h="16px" w="3px" bg="ef-red" />
              <Text variant="form-error">{customError ? customError : error?.message}</Text>
            </Stack>
          )}
        </Collapse> */}
      </Box>
    );
  }
);

TypingSelect.displayName = "TypingSelect";

export default TypingSelect;
