import React from "react";

import {ButtonLink} from "@ef/components";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef/modals";

import {Box, Heading, Stack, Text} from "@chakra-ui/react";

import {getVisitorId} from "../utils/cookies";

type Props = {message: string; smallText?: string; onClose?: () => void};

const CheckoutErrorModal: React.FC<Props> = ({message, smallText, onClose}) => {
  const {closeModal} = useModal("CheckoutError");
  const visitorId = getVisitorId();

  return (
    <Box m="2rem">
      <Heading variant="h3" w="100%" textAlign="center" pb="2rem">
        We’re not able to complete your purchase
      </Heading>

      <Text mb="2rem" textAlign="center" w="100%">
        {message}
      </Text>

      <Stack align="center">
        <ButtonLink
          variant="blue"
          onClick={() => {
            closeModal();
            onClose && onClose();
          }}
        >
          Try again
        </ButtonLink>

        {smallText && (
          <Text fontSize="sm" color="ef-border">
            {smallText}
          </Text>
        )}

        {visitorId && (
          <Text mb="-1rem" opacity=".8" fontSize="12px" color="ef-border">
            Your request ID #{visitorId}
          </Text>
        )}
      </Stack>
    </Box>
  );
};

export const useCheckoutErrorModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("CheckoutError", CheckoutErrorModal, {
    hideCloseButton: true,
    size: "lg",
    closeOnEsc: false,
    closeOnOverlayClick: false,
  });
