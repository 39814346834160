import React from "react";

import {useRouter} from "next/router";

import {COOKIES_EVENTS_BUFFER, COOKIES_LAST_PAGE_URL} from "@ef/constants";
import {mixpanelTrackEvent} from "@ef/utils";

const useRouteChangeHandler = () => {
  const {events} = useRouter();

  React.useEffect(() => {
    const handler = () => {
      // Clear store
      delete window[COOKIES_LAST_PAGE_URL];
      delete window["isTrackingReady"];
      delete window[COOKIES_EVENTS_BUFFER];
    };

    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  React.useEffect(() => {
    const handleRouteChange = (url) => {
      const lastUrl = (window[COOKIES_LAST_PAGE_URL] || "").split("?")[0];
      const onlyPathname = url.split("?")[0];
      // DO NOT REMOVE
      if (lastUrl && lastUrl === onlyPathname) {
        return;
      }

      mixpanelTrackEvent("PAGE:VISITED", {pathname: url});
    };

    // Track initial url
    handleRouteChange(window.location.pathname);

    events.on("routeChangeComplete", handleRouteChange);

    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [events]);
};

export default useRouteChangeHandler;
