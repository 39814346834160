import React from "react";

import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef/modals";

import {Stack, Button, Heading, Text} from "@chakra-ui/react";

const WithdrawalSuccessModal: React.FC = () => {
  const {closeModal} = useModal("WithdrawalSuccessModal");

  return (
    <Stack p="24px">
      <Heading variant="h4" lineHeight="22px" mb="2rem">
        Congrats!
        <br /> Your NFT is withdrawing...
      </Heading>
      <Text pb="1rem">
        The process might take up to <b>10 minutes</b>.
      </Text>
      <Button variant="blue" alignSelf="flex-end" onClick={closeModal}>
        Ok
      </Button>
    </Stack>
  );
};

export const useWithdrawalSuccessModal: InitialUseCreateModalType = () =>
  useCreateModal("WithdrawalSuccessModal", WithdrawalSuccessModal, {
    size: "md",
  });
