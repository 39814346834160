/* eslint-disable no-console */
import {omit} from "ramda";

import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";

import {useRouter} from "next/router";

import {
  GetPy3ApiEventsByEventIdApiResponse,
  GetPy3ApiNftByNftIdApiResponse,
  GetPy3ApiOrdersByOrderIdApiResponse,
  useGetNftappApiAccountByAccountIdStyleQuery,
  useGetPy3ApiEventByEventIdBillingQuery,
  useGetPy3ApiEventsByEventIdQuery,
  useGetPy3ApiNftByNftIdQuery,
  useGetPy3ApiOrderByOrderIdMerchandiseQuery,
  useGetPy3ApiOrdersByOrderIdQuery,
  useGetPy3ApiNftByNftIdWithdrawalsQuery,
  useOrderTicketsQuery,
  useAllAttendeesQuery,
  useCalculateOrderPriceOrdersCalculationsPostMutation,
  OrderTicketItemCreate,
  beOrgApi,
  useGetEventWithIdEventsEventIdGetQuery,
} from "@ef/api";
import {useGetPy3ApiNftQuery} from "@ef/api/extended";
import {useAuth} from "@ef/providers";
import {decryptDataToString, encryptDataToString} from "@ef/utils";

import {useSelectOrderByQueryOrderId} from ".";
import {AttendeeTypeAttendeeBuilder} from "../scenes/EventCheckout/hooks/useAttendeeBuilder";
import {eventCheckoutStore} from "../scenes/NewEventDetail/eventCheckoutStore";

export function useInitEventDetailByQueryEventId(
  initialDetail?: GetPy3ApiEventsByEventIdApiResponse
) {
  const {query} = useRouter();

  const res = useGetPy3ApiEventsByEventIdQuery(
    {eventId: query?.eventId?.toString() || ""},
    {refetchOnMountOrArgChange: true, skip: !Boolean(query?.eventId)}
  );

  return {...res, data: res.data || initialDetail};
}

export function useInitNewEventDetailByQueryEventId() {
  const {query} = useRouter();
  const res = useGetEventWithIdEventsEventIdGetQuery(
    {eventId: query?.eventId?.toString() || ""},
    {refetchOnMountOrArgChange: true, skip: !Boolean(query?.eventId)}
  );

  return {...res, data: res.data};
}

export function useInitEventDetailBillingByQueryEventId() {
  const {query} = useRouter();

  return useGetPy3ApiEventByEventIdBillingQuery(
    {eventId: query?.eventId?.toString() || ""},
    {refetchOnMountOrArgChange: true, skip: !Boolean(query?.eventId)}
  );
}

export function useInitNftsStylesByAccountId() {
  const {user} = useAuth();

  return useGetNftappApiAccountByAccountIdStyleQuery(
    {accountId: user.last_account_id || "0"},
    {refetchOnMountOrArgChange: true, skip: !Boolean(user?.last_account_id)}
  );
}

export function useInitNftDetailByQueryNftId(initiaNft?: GetPy3ApiNftByNftIdApiResponse) {
  const {query} = useRouter();

  const res = useGetPy3ApiNftByNftIdQuery(
    {nftId: query?.nftId?.toString() || ""},
    {refetchOnMountOrArgChange: true, skip: !Boolean(query?.nftId)}
  );

  return {...res, data: res.data || initiaNft};
}

export function useInitNftWithdrawalStatusQuery() {
  const [keepPooling, setPooling] = useState(false);
  const {query} = useRouter();

  const res = useGetPy3ApiNftByNftIdWithdrawalsQuery(
    {nftId: query?.nftId?.toString() || ""},
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: keepPooling ? 10000 : undefined,
    }
  );

  React.useEffect(() => {
    setPooling(true);
  }, []);

  React.useEffect(() => {
    if (res?.data && Array.isArray(res.data?.items)) {
      if (res.data.items.length === 0) {
        setPooling(false);
        return;
      }
      if (res.data.items.some((w) => w.status === "requested")) {
        setPooling(true);
        return;
      }
      setPooling(false);
    }
  }, [res]);

  return res;
}

export function useInitOrderDetailByQueryOrderId(
  initiaOrder?: GetPy3ApiOrdersByOrderIdApiResponse
) {
  const {query} = useRouter();

  const res = useGetPy3ApiOrdersByOrderIdQuery(
    {orderId: query?.orderId?.toString() || ""},
    {refetchOnMountOrArgChange: true, skip: !Boolean(query?.orderId)}
  );

  return {...res, data: res.data || initiaOrder};
}

export function useInitOrderTicketsByQueryOrderId() {
  const {query} = useRouter();

  const res = useOrderTicketsQuery(
    {orderId: query?.orderId?.toString() || ""},
    {refetchOnMountOrArgChange: true, skip: !Boolean(query?.orderId)}
  );

  return res;
}

export function useInitMerchandiseByOrderedMerch() {
  const isInitial = useRef(true);
  const {data: orderDetail} = useSelectOrderByQueryOrderId();
  const [keepPooling, setPooling] = useState(false);
  const poolingCount = useRef(0);
  const poolingInterval = useRef(5000);

  const res = useGetPy3ApiOrderByOrderIdMerchandiseQuery(
    {orderId: orderDetail?.id},
    {
      refetchOnMountOrArgChange: true,
      skip:
        orderDetail && orderDetail?.status !== "canceled"
          ? isInitial.current
            ? keepPooling
            : !Boolean(orderDetail?.id)
          : true,
      pollingInterval: keepPooling ? poolingInterval.current : undefined,
    }
  );

  React.useEffect(() => {
    setPooling(true);
  }, []);

  React.useEffect(() => {
    if (poolingCount.current >= 12) {
      poolingInterval.current = 30000;
    }

    if (poolingCount.current >= 36) {
      setPooling(false);
    }

    poolingCount.current += 1;
    isInitial.current = false;

    if (
      res?.data &&
      Array.isArray(res.data?.items) &&
      res.data.items.length === orderDetail?.total_merchandise
    ) {
      if (orderDetail?.status !== "success") {
        setPooling(false);
        //@ts-ignore swagger
      } else if (res.data.items.every((item) => item.status === "minted")) {
        setPooling(false);
      }
    }
  }, [res]);

  return res;
}

export function useInitAllAttendeesDetailByQueryOrderId() {
  const {query} = useRouter();
  const isInitial = useRef(true);
  const {data: orderDetail} = useSelectOrderByQueryOrderId();
  const poolingCount = useRef(0);
  const [keepPooling, setPooling] = useState(false);

  const res = useAllAttendeesQuery(
    {orderId: query?.orderId?.toString() || ""},
    {
      refetchOnMountOrArgChange: true,
      skip:
        orderDetail && orderDetail?.status !== "canceled"
          ? isInitial.current
            ? keepPooling
            : !Boolean(query?.orderId)
          : true,
      pollingInterval: keepPooling ? 3000 : undefined,
    }
  );

  React.useEffect(() => {
    setPooling(true);
  }, []);

  React.useEffect(() => {
    if (poolingCount.current >= 7) {
      setPooling(false);
    }

    poolingCount.current += 1;
    isInitial.current = false;

    if (res?.data && Array.isArray(res.data.attendees)) {
      setPooling(false);
    }
  }, [res]);

  return res;
}

export function useInitNftListQuery() {
  const isInitial = useRef(true);
  const [keepPooling, setPooling] = useState(false);
  const poolingCount = useRef(0);
  const {user} = useAuth();

  const res = useGetPy3ApiNftQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: isInitial.current ? keepPooling : !Boolean(user),
    pollingInterval: keepPooling ? 5000 : undefined,
  });

  React.useEffect(() => {
    setPooling(true);
  }, []);

  React.useEffect(() => {
    if (res.isFetching) return;
    poolingCount.current = poolingCount.current + 1;

    isInitial.current = false;
    if (poolingCount.current >= 12) {
      setPooling(false);
      return;
    }

    if (res?.data && !res.data?.items?.some((x) => x.status !== "minted")) {
      setPooling(false);
    }
  }, [res?.data, res.isFetching]);

  return res;
}

export const useCalculatePayloadPreparator = () => {
  const {query} = useRouter();
  const addedTickets = eventCheckoutStore((state) => state.tickets);
  const discoutCodes = eventCheckoutStore((state) => state.discountCodes);
  return useMemo(
    () => ({
      isEmpty: Boolean(addedTickets.length === 0),
      payload: {
        event_id: query?.eventId?.toString() || "",
        coupon_codes: discoutCodes,
        items: addedTickets.map<OrderTicketItemCreate>((x) => ({
          quantity: x.quantity,
          type_id: x.type_id,
          type: x.type,
        })),
      },
    }),
    [addedTickets, query?.eventId, discoutCodes]
  );
};

export const addAttendeesToTickets = (
  attendees: AttendeeTypeAttendeeBuilder[],
  tickets: OrderTicketItemCreate[]
): OrderTicketItemCreate[] => {
  let attendeesCopy = [...attendees];
  const assignedTickets: OrderTicketItemCreate[] = [];
  tickets.forEach((ticket) => {
    while (ticket.quantity > 0) {
      const ticketTypeId = ticket.type_id;
      const attendee = attendeesCopy.find((att) => {
        return att.tickets.indexOf(ticketTypeId) > -1;
      });
      if (!attendee) {
        break;
      }
      const assignedTicket: OrderTicketItemCreate = {
        ...ticket,
        quantity: 1,
        attendee: {
          first_name: attendee.firstName,
          last_name: attendee.lastName,
          email: attendee.email,
          attendee_fields: Object.keys(attendee.customFields).map((key) => ({
            slug: key,
            value: attendee.customFields[key],
          })),
        },
      };
      ticket.quantity--;
      assignedTickets.push(assignedTicket);
      attendeesCopy = attendeesCopy.map((att) => {
        if (att._id === attendee._id) {
          const newTickets = attendee.tickets.filter((tt) => tt !== ticketTypeId);
          return {...att, tickets: newTickets};
        }
        return att;
      });
    }
  });
  const leftoverTickets = tickets.filter((t) => t.quantity > 0);
  return [...assignedTickets, ...leftoverTickets];
};

export const useHandleTicketCalculation = () => {
  const {replace, query} = useRouter();
  const [calculate] = useCalculateOrderPriceOrdersCalculationsPostMutation({
    fixedCacheKey: "calculate",
  });

  const setTickets = eventCheckoutStore((state) => state.actions.setTickets);
  const setErrors = eventCheckoutStore((state) => state.actions.setCalculationErrors);

  const calculator = useCalculatePayloadPreparator();

  useEffect(() => {
    const data = (query?.tickets || "")?.toString();
    if (!data) return;

    try {
      const decypted = decryptDataToString<typeof calculator["payload"]["items"]>(
        decodeURIComponent(data),
        true
      );
      if (decypted) {
        setTickets(decypted);
        return;
      }
    } catch {}
  }, []);

  const updateQuery = (data: string | null) => {
    replace(
      {
        query: data ? {...query, tickets: data} : {...omit(["tickets"], query)},
      },
      undefined,
      {shallow: true}
    );
  };

  useEffect(() => {
    if (calculator.isEmpty) {
      updateQuery(null);
      return;
    }

    updateQuery(encryptDataToString(JSON.stringify(calculator.payload.items)));
    calculate({orderPriceCalculationRequest: calculator.payload})
      .then((res) => {
        if (Object.keys(res).includes("error")) {
          updateQuery(null);
          setErrors([{type: "error", message: "Something went wrong"}]);
          return;
        } else {
          setErrors([]);
        }
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculator]);
};

export const useCalculateEventPrice = () => {
  const data = useSelector(
    //@ts-expect-error ignore
    beOrgApi.endpoints.calculateOrderPriceOrdersCalculationsPost.select({
      fixedCacheKey: "calculate",
    })
  );

  return data;
};
