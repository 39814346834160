import {isNil, omit} from "ramda";

import React from "react";

import {resolvePrefix} from "@ef/helpers";

import {
  AspectRatio,
  AspectRatioProps,
  ImageProps,
  Image,
  useBoolean,
  Text,
  Stack,
  Box,
  StackProps,
} from "@chakra-ui/react";

import {formatDate} from "../scenes/EventDetail/utils/eventHelpers";

const OMIT_KEYS = ["src", "children"];

const EventImagePreview: React.FC<
  React.PropsWithChildren<
    ImageProps & {
      ratio?: AspectRatioProps["ratio"];
      eventStartingISO?: string;
      eventLocation?: string;
      eventName?: string;
      borderRadius?: StackProps["borderRadius"];
      isCanceled?: boolean;
    }
  >
> = ({
  ratio = 21 / 9,
  eventName,
  isCanceled,
  eventStartingISO,
  borderRadius,
  eventLocation,
  src,
  ...rest
}) => {
  const [hasError, {on, off}] = useBoolean(isNil(src));

  React.useEffect(() => {
    if (isNil(src)) {
      return on();
    }

    off();
  }, [src]);

  return (
    <Box>
      <AspectRatio w="100%" ratio={ratio}>
        {!hasError ? (
          <Box>
            {isCanceled && (
              <Stack
                px="15px"
                py="20px"
                w="100%"
                h="100%"
                borderRadius={borderRadius}
                align="center"
                justify="center"
                position="absolute"
                bg="rgba(255,255,255,0.8)"
              >
                <Text fontSize="2rem" color="#141A4E" fontWeight="bold">
                  Order was canceled
                </Text>
              </Stack>
            )}
            {
              //@ts-ignore
              <Image
                w="100%"
                h="100%"
                alt={eventName || "event-image"}
                src={src}
                {...rest}
                onError={on}
                borderRadius={borderRadius}
              />
            }
          </Box>
        ) : (
          <Stack
            px="15px"
            py="20px"
            borderRadius={borderRadius}
            bg={`linear-gradient(0deg, rgba(249, 250, 251, 0.95), rgba(249, 250, 251, 0.95)), url('${resolvePrefix(
              "/images/event-detail-fallback.png"
            )}')`}
            {
              //@ts-expect-error fix
              ...omit(OMIT_KEYS, rest)
            }
          >
            {isCanceled && (
              <Text fontSize="2rem" pb="1rem" fontWeight="bold">
                Order was canceled
              </Text>
            )}
            {eventName && (
              <Text fontWeight="bold" opacity={isCanceled ? "0.5" : undefined} fontSize="20px">
                {eventName}
              </Text>
            )}
            {(eventStartingISO || eventLocation) && (
              <Stack direction="row" opacity={isCanceled ? "0.5" : undefined}>
                {eventStartingISO && <Text>{formatDate(eventStartingISO)}</Text>}
                {eventStartingISO && eventLocation && <Text>·</Text>}
                {eventLocation && <Text>{eventLocation.replace(",", ", ")}</Text>}
              </Stack>
            )}
          </Stack>
        )}
      </AspectRatio>
    </Box>
  );
};

export default EventImagePreview;
