import {useMediaQuery} from "@chakra-ui/react";

export const useMediaQueryMobile = () => {
  const [res] = useMediaQuery("(min-width: 30em)");
  return !res;
};

const VIEWS = {
  "only-mobile": "(min-width: 30em)",
};

export const useViewport = (type: keyof typeof VIEWS) => {
  const [res] = useMediaQuery(VIEWS[type]);
  return res;
};
