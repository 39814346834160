import {configureStore, ThunkAction} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query/react";
import {Action} from "redux";

import {createWrapper} from "next-redux-wrapper";

import {beApi, beOrgApi} from "@ef/api";

const makeStore = () =>
  configureStore({
    reducer: {
      [beApi.reducerPath]: beApi.reducer,
      [beOrgApi.reducerPath]: beOrgApi.reducer,
    },
    //@ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(beApi.middleware, beOrgApi.middleware),
    devTools: process.env.NODE_ENV !== "production",
  });

export const store = makeStore();
setupListeners(store.dispatch);

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export const wrapper = createWrapper<AppStore>(makeStore);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
