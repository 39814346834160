export const CURRENCIES = {
  aed: "__price__&nbsp;د.إ",
  amd: "AMD&nbsp;__price__",
  aud: "$&nbsp;__price__",
  bhd: "__price__ BD",
  brl: "R$__price__",
  byn: "Br__price__",
  cad: "$__price__",
  chf: "SFr.&nbsp;__price__",
  clp: "$__price__",
  cny: "¥__price__",
  cop: "$__price__",
  czk: "__price__&nbsp;Kč",
  dkk: "__price__&nbsp;kr",
  eur: "__price__&nbsp;€",
  gbp: "£__price__",
  hkd: "HK$__price__",
  huf: "__price__&nbsp;Ft",
  idr: "Rp&nbsp;__price__",
  ils: "₪&nbsp;__price__",
  inr: "__price__&nbsp;₹",
  isk: "__price__&nbsp;ISK",
  jod: "__price__&nbsp;JD",
  jpy: "¥__price__",
  kgs: "__price__&nbsp;som",
  krw: "₩__price__",
  kwd: "__price__&nbsp;K.D.",
  kzt: "₸__price__",
  mxn: "$&nbsp;__price__",
  myr: "RM__price__",
  nok: "kr&nbsp;__price__",
  nzd: "$&nbsp;__price__",
  omr: "ر.ع.&nbsp;__price__",
  pen: "__price__&nbsp;S/.",
  php: "₱&nbsp;__price__",
  pln: "__price__&nbsp;zł",
  qar: "QR&nbsp;__price__",
  ron: "__price__&nbsp;lei",
  rsd: "__price__&nbsp;din.",
  rub: "__price__&nbsp;р.",
  sar: "__price__&nbsp;SR",
  sek: "__price__&nbsp;kr",
  sgd: "$&nbsp;__price__",
  thb: "฿&nbsp;__price__",
  try: "__price__&nbsp;TL",
  twd: "NT$__price__",
  uah: "__price__&nbsp;грн.",
  usd: "$&nbsp;__price__",
  uzs: "__price__&nbsp;som",
  yer: " __price__&nbsp;﷼",
  zar: "R&nbsp;__price__",
};
