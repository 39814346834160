import React from "react";

type CreateContextOptions = {
  // If `true`, React will throw if context is `null` or `undefined`
  // In some cases, you might want to support nested context, so you can set it to `false`
  strict?: boolean;
  // Error message to throw if the context is `undefined`
  errorMessage?: string;
  // The display name of the context
  name?: string;
};

type CreateContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>];

export function rewriteHookType<T>(hook: () => void): T {
  //@ts-ignore Invalid type, rewrite
  return hook() as T;
}

// Creates a named context, provider, and hook.
export function createContext<ContextType>(
  options: CreateContextOptions = {}
): CreateContextReturn<ContextType> {
  const {
    strict = true,
    errorMessage = "useContext: `context` is undefined. Seems you forgot to wrap component within the Provider",
    name,
  } = options;

  const Context = React.createContext<ContextType | undefined>(undefined);

  Context.displayName = name;

  function useContext(): ContextType {
    const context = React.useContext(Context);

    if (!context && strict) {
      throw new Error(errorMessage);
    }

    return context as ContextType;
  }

  return [Context.Provider, useContext, Context] as CreateContextReturn<ContextType>;
}
