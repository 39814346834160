import React from "react";

import {usePostPy3ApiUserVerifyTriggerMutation} from "@ef/api";
import {InitialUseCreateModalType, useCreateModal} from "@ef/modals";

import {Box, Stack, Heading, Text, Spinner, Collapse} from "@chakra-ui/react";

type ResentActivationModalProps = {email: string};

const ResentActivationModal: React.FC<ResentActivationModalProps> = ({email}) => {
  const [resentEmail, {isLoading}] = usePostPy3ApiUserVerifyTriggerMutation();

  React.useEffect(() => {
    resentEmail({verifyTriggerPayloadA992424: {email}});
  }, []);

  return (
    <Box p="2rem">
      <Collapse in={isLoading} animateOpacity>
        <Stack align="center">
          <Spinner color="ef-primary" />
        </Stack>
      </Collapse>

      <Collapse in={!isLoading} animateOpacity>
        <Heading w="100%" variant="h3" textAlign="center" mb="1rem">
          The link has been sent
        </Heading>

        <Text w="100%" textAlign="center" fontSize="small">
          The activation link was resent to <b>{email}</b>
        </Text>
      </Collapse>
    </Box>
  );
};

export const useResentActivationEmailModal: InitialUseCreateModalType<
  ResentActivationModalProps
> = () => useCreateModal("resentActivationModal", ResentActivationModal);
