import * as React from "react";
import {FieldError} from "react-hook-form";

import {resolveChakraSpacing} from "@ef/utils";

import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Input as ChakraInput,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
  Stack,
  Text,
  useStyleConfig,
} from "@chakra-ui/react";

export type InputTypes =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export type InputProps = Omit<ChakraInputProps, "type"> & {
  type?: InputTypes;
  label?: React.ReactNode;
  labelRight?: React.ReactNode;
  error?: FieldError;
  rightElement?: React.ReactNode;
  required?: boolean;
  dataTest?: string;
  customError?: string;
  customMessage?: React.ReactNode;
  customBottomElement?: React.ReactNode;
  rightElementProps?: InputElementProps;
  labelColor?: string;
  leftContent?: React.ReactNode;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      type,
      rightElement,
      required,
      customMessage,
      dataTest,
      customError,
      rightElementProps,
      labelColor,
      leftContent,
      customBottomElement,
      labelRight,
      ...props
    },
    ref
  ) => {
    const inputStyles = useStyleConfig("CustomInput", {});
    const [showPassword, setShowPassword] = React.useState(false);

    const newProps = resolveChakraSpacing<InputProps, InputProps, InputProps>(props);

    const showRightContent = rightElement || type === "password";

    return (
      <Box w="100%" {...newProps.spacing}>
        {(label || labelRight) && (
          <Stack
            direction="row"
            w="100%"
            align="center"
            justify={label && labelRight ? "space-between" : labelRight ? "flex-end" : "flex-start"}
          >
            {label && React.isValidElement(label) ? (
              label
            ) : (
              <Text
                variant="form-label"
                color={Boolean(error) ? "ef-red" : labelColor ? labelColor : "ef-black"}
                size="small"
              >
                {label}
                {(required || props.isRequired) && (
                  <Text as="span" pl="3px" color="ef-red">
                    *
                  </Text>
                )}
              </Text>
            )}

            {labelRight && React.isValidElement(labelRight) ? (
              labelRight
            ) : (
              <Text variant="form-label" color="ef-black" size="small">
                {labelRight}
              </Text>
            )}
          </Stack>
        )}

        <InputGroup mb="5px" fontSize="20px">
          {leftContent && <InputLeftElement>{leftContent}</InputLeftElement>}

          <ChakraInput
            ref={ref}
            type={type !== "password" ? type : showPassword ? "text" : type}
            sx={inputStyles}
            borderColor={Boolean(error) ? "ef-red" : "ef-border"}
            data-test={dataTest}
            {...newProps.rest}
          />

          {showRightContent && (
            <InputRightElement {...rightElementProps}>
              <Stack direction="row" align="center" spacing="5px">
                {type === "password" && (
                  <Stack _hover={{cursor: "pointer"}} onClick={() => setShowPassword((p) => !p)}>
                    {!showPassword ? (
                      <ViewIcon color="ef-black" />
                    ) : (
                      <ViewOffIcon color="ef-black" />
                    )}
                  </Stack>
                )}

                {rightElement}
              </Stack>
            </InputRightElement>
          )}
        </InputGroup>

        <Collapse in={Boolean(error?.message)} animateOpacity>
          {(error?.message || customError) && (
            <Stack direction="row" align="top" w="fit-content" pt="4px">
              <Box h="16px" w="3px" bg="ef-red" />
              <Text variant="form-error">{customError ? customError : error.message}</Text>
            </Stack>
          )}
        </Collapse>

        {(customMessage || customBottomElement) && (
          <Box mt={Boolean(error?.message) ? "0.5rem" : "0"}>
            {customMessage ? (
              <Text variant="form-label" size="small" color="ef-gray">
                {customMessage}
              </Text>
            ) : (
              customBottomElement
            )}
          </Box>
        )}
      </Box>
    );
  }
);

Input.displayName = "Input";

export const ErrorMessage: React.FC<
  React.PropsWithChildren<{error: any; customError?: string; dataCy?: string}>
> = ({error, customError, dataCy}) =>
  error?.message ? (
    <Stack direction="row" align="center" w="fit-content" pt="4px" data-cy={dataCy}>
      <Box h="16px" w="3px" bg="ef-red" />
      <Text variant="form-error">{customError ? customError : error.message}</Text>
    </Stack>
  ) : null;

export default Input;
