/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import styled from "@emotion/styled";
import {ObjectSchema} from "joi";
import {isEmpty, omit, pathOr} from "ramda";

// import {IUser} from "utils/user";
import * as React from "react";
import {
  ControllerRenderProps,
  DeepPartial,
  FieldError,
  FieldValues,
  FormProvider,
  Path,
  SetValueConfig,
  UnpackNestedValue,
  useController,
  useForm as useReactForm,
  useFormContext,
  UseFormProps,
  UseFormRegister,
  UseFormReturn, // FieldError as ReactFieldError,
} from "react-hook-form";

import {useRouter} from "next/router";

import {UserProfile} from "@ef/api";
import {useAuth} from "@ef/providers";

import {Box, BoxProps} from "@chakra-ui/react";

import {joiResolver} from "../helpers/joiResolver";
import {JOI_LOCALIZATION} from "../utils/localization";
import {useLocalization} from "./useLocalization";

// import useGlobalState from "./useGlobalState";

const FORM_STORAGE_PREFIX = "form_";

export type OnFormSubmitType<FormInputs> = (formValues: FormInputs) => void | Promise<void>;

const updateValidationSchema = (validationSchema: ObjectSchema): ObjectSchema => {
  const schema = validationSchema.prefs({
    messages: JOI_LOCALIZATION,
    allowUnknown: true,
  });
  return schema;
};

type Unknown = Record<string, unknown>;

type CustomUseFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends Unknown = Unknown
> = UseFormProps<TFieldValues, TContext> & {
  formId?: string;
  omitSavingKeys?: Array<keyof TFieldValues>;
  keepDataOnSuccess?: boolean;
  onLoginChange?: (props: {
    user: UserProfile | null;
    isLoggedIn: boolean;
    form: Omit<UseFormReturn<TFieldValues>, "handleSubmit" | "unregister" | "register">;
  }) => void;
};

type CustomUseFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends Unknown = Unknown
> = {
  registerWithError: UseFormRegister<TFieldValues> & {
    error?: FieldError;
  };
  errors: UseFormReturn<TFieldValues>["formState"]["errors"];
  defaultValues: UseFormProps<TFieldValues, TContext>["defaultValues"];
  setFocusAfter: (input: Path<TFieldValues>, time?: number) => void;
  scrollAndSetFocus: (input: Path<TFieldValues>) => void;
  saveFormToStorage: () => void;
  clearFormStorage: (props?: {resetForm?: boolean}) => void;
  FormProvider: React.FC<
    React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
  >;
};

const saveFormToStorageLocalFnc = (formId: string, values: UnpackNestedValue<unknown>): void => {
  new Promise<void>(() => {
    localStorage.setItem(FORM_STORAGE_PREFIX + formId, JSON.stringify(values));
  });
};

const removeFormStorage = (formId: string): void => {
  localStorage.removeItem(FORM_STORAGE_PREFIX + formId);
};

const getFormFromStorage = <ReturnType extends unknown>(formId: string): ReturnType => {
  const values = localStorage.getItem(FORM_STORAGE_PREFIX + formId);

  if (!values) return {} as ReturnType;

  const parsedValues = JSON.parse(values);

  if (isEmpty(parsedValues)) return {} as ReturnType;

  return parsedValues as ReturnType;
};

type Hooks = {
  useFormField: (
    fieldName: string,
    defaultValue?: string
  ) => ControllerRenderProps<FieldValues, string> & {
    setValue: (value: string, options?: SetValueConfig) => void;
  };
};

const useField: Hooks["useFormField"] = (fieldName, defaultValue = "") => {
  const {control, setValue} = useFormContext();
  const {field} = useController({
    name: fieldName,
    control,
    defaultValue,
  });

  const setFieldValue = (value: string, options?: SetValueConfig): void => {
    setValue(fieldName, value, options);
  };

  return {...field, setValue: setFieldValue};
};

export {useField};

export default function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends Unknown = Unknown
>(
  validationSchema: ObjectSchema,
  formOptions: CustomUseFormProps<TFieldValues, TContext> = {}
): UseFormReturn<TFieldValues> & CustomUseFormReturn<TFieldValues, TContext> {
  const {language} = useLocalization();
  const {events} = useRouter();
  const {user} = useAuth();

  const form = useReactForm<TFieldValues, TContext>({
    resolver: (values, context, options) => {
      if (formOptions.formId) {
        saveFormToStorageLocalFnc(
          formOptions.formId,
          //@ts-expect-error fix
          omit(formOptions?.omitSavingKeys || [], values)
        );
      }

      return joiResolver(updateValidationSchema(validationSchema), {
        abortEarly: false,
        errors: {language},
      })(values, context, options);
    },
    ...formOptions,
    defaultValues: {
      ...formOptions.defaultValues,
      ...(formOptions.formId &&
        getFormFromStorage<UnpackNestedValue<DeepPartial<TFieldValues>>>(formOptions.formId)),
    },
  });

  const saveFormToStorage: CustomUseFormReturn<
    TFieldValues,
    TContext
  >["saveFormToStorage"] = () => {
    if (!formOptions.formId) {
      return;
    }

    saveFormToStorageLocalFnc(
      formOptions.formId,
      //@ts-expect-error fix
      omit(formOptions?.omitSavingKeys || [], form.getValues())
    );
  };

  const clearFormStorage: CustomUseFormReturn<TFieldValues, TContext>["clearFormStorage"] = (
    props
  ) => {
    if (!formOptions.formId) {
      return;
    }

    if (props?.resetForm) {
      //@ts-ignore
      form.reset({});
    }

    removeFormStorage(formOptions.formId);
  };

  React.useEffect(() => {
    const newForm = omit(["handleSubmit", "unregister", "register"], form);

    formOptions?.onLoginChange &&
      formOptions?.onLoginChange({isLoggedIn: Boolean(user), form: newForm, user});
  }, [user]);

  React.useEffect(() => {
    const saveForm = (): void => {
      if (formOptions.formId) {
        saveFormToStorageLocalFnc(
          formOptions.formId,
          //@ts-expect-error fix
          omit(formOptions?.omitSavingKeys || [], form.getValues())
        );
      }
    };

    events.on("routeChangeStart", saveForm);
    window.addEventListener("beforeunload", saveForm);

    return () => {
      window.removeEventListener("beforeunload", saveForm);
      events.off("routeChangeStart", saveForm);
    };
  }, []);

  const setFocusAfter: CustomUseFormReturn<TFieldValues, TContext>["setFocusAfter"] = (
    input,
    time = 500
  ): void => {
    setTimeout(() => {
      try {
        form.setFocus(input);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("form.setFocus", err);
      }
    }, time);
  };

  const scrollAndSetFocus: CustomUseFormReturn<TFieldValues, TContext>["setFocusAfter"] = (
    input
  ): void => {
    try {
      const element = document.getElementsByName(input)[0];

      if (element) {
        const yOffset = -100;
        const z = element?.getBoundingClientRect()?.top || 0 + window.pageYOffset + yOffset;

        window.scrollTo({top: z, behavior: "smooth"});
      }
      form.setFocus(input);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("form.scrollAndSetFocus", err);
    }
  };

  return {
    ...form,
    FormProvider: ({children, ...props}) => {
      return (
        <FormProvider {...form}>
          <form {...props}>{children}</form>
        </FormProvider>
      );
    },
    handleSubmit: (onValid, onInvalid) => {
      return form.handleSubmit(
        (...props) => {
          if (!formOptions.keepDataOnSuccess) {
            clearFormStorage();
            //@ts-ignore
            form.reset({});
          }

          //@ts-ignore
          return onValid(...props);
        },
        (...props) => {
          return onInvalid && onInvalid(...props);
        }
      );
    },
    errors: form.formState.errors,
    defaultValues: formOptions.defaultValues,
    saveFormToStorage,
    clearFormStorage,
    scrollAndSetFocus,
    setFocusAfter,
    registerWithError: (name, opts) => {
      let errorField;

      if (!name.includes(".")) {
        //@ts-ignore
        errorField = form.formState.errors[name];
      } else {
        const [baseName, index, ...rest] = name.split(".");
        //@ts-ignore
        errorField = pathOr(undefined, [baseName, index, ...rest], form.formState.errors);
      }
      return {
        ...form.register(name, opts),
        ...(errorField && {error: errorField}),
      };
    },
  };
}

// export const ErrorFormMessage: React.FC<{ error?: ReactFieldError }> = ({
//   error,
// }) => {
//   if (!error) {
//     return null;
//   }
//   return <FieldError>{error.message}</FieldError>;
// };

interface IHiddenInputProps {
  name: string;
}

const StyledInput = styled.input`
  display: none;
`;

export const Form: React.FC<HTMLFormElement & BoxProps> = ({children}) => {
  return <Box as="form">{children}</Box>;
};

export const HiddenInput = React.forwardRef<HTMLInputElement, IHiddenInputProps>((props, ref) => (
  <StyledInput ref={ref} {...props} />
));

HiddenInput.displayName = "HiddenInput";
