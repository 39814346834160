import {pathOr} from "ramda";

import React from "react";

import {NftDataItem} from "@ef/api";
import {Image, Link, NFTCanvas} from "@ef/components";
import {is3D} from "@ef/helpers";

import {AspectRatio, Badge, Stack, Text} from "@chakra-ui/react";

import {formatDate} from "../scenes/EventDetail/utils/eventHelpers";

const NFTPreview: React.FC<{nft: NftDataItem; isDetail?: boolean; eventId?: string}> = ({
  nft,
  isDetail,
  eventId,
}) => {
  const isNotMinted = pathOr<string>("not-minted", ["status"], nft) !== "minted";
  const nftLabel = pathOr<string>(
    "Processing NFT",
    ["api_payload", "public_data", isDetail ? "ticket_name" : "event_name"],
    nft
  );
  const isMerch = !!nft.merchandise;

  return (
    <Link
      opacity={isNotMinted ? ".35" : "1"}
      //@ts-ignore swagger
      href={
        isNotMinted
          ? undefined
          : `/my-wallet/event/${
              eventId ?? (nft.ticket ? nft.ticket.event : nft.merchandise.event)
            }/nft/${nft.id}/`
      }
      variant="unstyled"
      w="100%"
      p="1rem"
      _hover={{bg: "ef-gray-l1", boxShadow: "0px 3px 20px rgba(17, 17, 17, 0.1)"}}
      borderRadius="8px"
      cursor={isNotMinted ? "unset" : "pointer"}
    >
      <Stack w="inherit">
        <AspectRatio w="inherit" ratio={1}>
          {/* @ts-ignore swagger */}
          {is3D(nft?.style) ? (
            <NFTCanvas
              rotateOnly
              //@ts-ignore swagger
              nftAssetUrl={nft?.style.url}
              //@ts-ignore swagger
              nftId={nft?.style?.id}
            />
          ) : (
            <Stack>
              <Image
                borderRadius="6px"
                // w="inherit"
                //@ts-ignore swagger
                src={nft?.style?.url}
                fallbackSrc="/placeholders/event-nft-placeholder.png"
                alt="NFT ticket"
              />
            </Stack>
          )}
        </AspectRatio>

        <Text
          fontWeight="bold"
          textAlign={isNotMinted ? "center" : "left"}
          w="100%"
          pt={isNotMinted ? "1rem" : "0"}
        >
          {nftLabel}
        </Text>
        {!isNotMinted && (
          <Stack direction="row" align="center" flexFlow="wrap">
            {isMerch ? (
              <Badge
                borderRadius="15px"
                px="15px"
                py="3px"
                borderWidth="1px"
                borderColor="ef-divider"
                bg="white"
              >
                COLLECTIBLE
              </Badge>
            ) : (
              <>
                <Badge
                  bg="linear-gradient(84.26deg, #7FE7BE -8.66%, #97EEDA 46.51%, #B2F5F9 109.03%)"
                  borderRadius="15px"
                  px="15px"
                  py="3px"
                >
                  UPCOMING
                </Badge>
                {/* @ts-ignore swagger */}
                {nft?.api_payload?.public_data?.date && (
                  //@ts-ignore swaggers
                  <Text>{formatDate(nft.api_payload.public_data.date).replace("noon", "")}</Text>
                )}
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Link>
  );
};

export default NFTPreview;
