import Joi from "joi";

import React from "react";

import {useRouter} from "next/router";

import {EfFullLogo, Link, LogoSquare} from "@ef/components";
import {getOrganiserAppEventsUrl} from "@ef/constants";
import {useAuth} from "@ef/providers";

import {Container, Grid, Stack, Image, Divider, Text, AspectRatio} from "@chakra-ui/react";

import LoginSection, {Steps} from "./components/LoginSection";

export type FormInputsType = {
  email: string;
  password: string;
};

export const formInputsSchema = Joi.object({
  email: Joi.string().email({tlds: false}).required(),
  password: Joi.string().min(4).required(),
});

const Login: React.FunctionComponent<{asPage?: Steps}> = ({asPage}) => {
  const {push, query} = useRouter();
  const {user} = useAuth();

  React.useEffect(() => {
    // const searchQuery = new URLSearchParams(window.location.search);
    if (user) {
      // if (searchQuery.has("errStatus") && searchQuery.get("errStatus") === "unauth") {
      //   logout();
      //   return;
      // }

      if (query?.backToUrl) {
        push(decodeURIComponent(query?.backToUrl?.toString()));
        return;
      }

      if (user?.last_role === "org") {
        window.location.assign(getOrganiserAppEventsUrl());
        return;
      }

      push("/my-wallet");
    }
  }, [user]);

  return (
    <Stack w="100%" h={[undefined, "100vh"]} align="center" justify={[undefined, "space-between"]}>
      <Container maxW="1380px" py="2rem">
        <Stack direction="row" align="center" justify="space-between">
          <Link href="/">
            <EfFullLogo w="auto" h="24px" display={["none", "block"]} />
            <LogoSquare w="auto" h="24px" display={["block", "none"]} />
          </Link>
        </Stack>
      </Container>

      <Container maxW="1280px" h={["auto", "640px"]} maxH={["auto", "640px"]}>
        <Grid
          templateColumns={["repeat(1,1fr)", "repeat(1,1fr)", "50% 50%"]}
          w="100%"
          h="100%"
          gap="2rem"
        >
          <Stack justify="center" align={["center", "center", "normal"]}>
            <LoginSection asPage={asPage} />
          </Stack>

          <AspectRatio ratio={1} w="100%" h="fit-content">
            <Image
              //@ts-ignore
              objectFit="contain !important"
              width="100%"
              height="100%"
              alt="error-image"
              src="/app/placeholders/login-right-section.jpg"
            />
          </AspectRatio>
        </Grid>
      </Container>

      <Divider pt="2rem" display={["block", "none"]} />

      <Container maxW="1280px" pb="2rem" pt={["2rem", "5rem", "2rem"]}>
        <Stack
          direction={["column", "column", "row"]}
          spacing={["2rem", undefined]}
          align="center"
          justify="space-between"
        >
          <Stack direction={["column", "column", "row"]} align="center" spacing="1rem">
            <Text size="small" color="ef-gray">
              © Copyright 2022 EventsFrame. All rights reserved.
            </Text>
          </Stack>

          <Stack direction="row" align="center" spacing="1rem">
            <Link
              variant="baseDecorationSwitched"
              textDecoration="none"
              href="https://twitter.com/eventsframe"
            >
              Twitter
            </Link>
            <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
            <Link
              href="https://support.eventsframe.com"
              variant="baseDecorationSwitched"
              textDecoration="none"
            >
              Support
            </Link>
            <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
            <Link
              variant="baseDecorationSwitched"
              textDecoration="none"
              href="https://support.eventsframe.com"
            >
              Contact
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Login;
