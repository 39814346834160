import {Global} from "@emotion/react";

import {resolvePrefix} from "./helpers";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: "OceanWide";
        font-style: normal;
        src: url("${resolvePrefix("/fonts/oceanwide.otf")}") format("opentype");
      }
      @font-face {
        font-family: "OceanWide";
        font-style: italic;
        src: url("${resolvePrefix("/fonts/oceanwide-italic.otf")}") format("opentype");
      }
      `}
  />
);

export default Fonts;
