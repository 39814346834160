import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";

import React from "react";

import {Collapse} from "@chakra-ui/react";

type AlertOptions = {clearAfter?: number};

type AlertProviderData = {
  alerts: Array<{boxId: string; component: React.ReactNode; opts?: AlertOptions}>;
};

type AlertProviderActions = {
  actions: {
    setAlerts: (boxId: string, component: React.ReactNode, opts?: AlertOptions) => void;
  };
};

type AlertProvider = AlertProviderData & AlertProviderActions;

const useHook = create(
  subscribeWithSelector<AlertProvider>((set) => ({
    alerts: [],
    actions: {
      setAlerts: (boxId, component, opts) =>
        set(({alerts}) => ({
          alerts:
            component === null
              ? alerts.filter((x) => x.boxId !== boxId)
              : [...alerts, {boxId, component, opts}],
        })),
    },
  }))
);

export const useAlert = (
  boxId: string
): ((component: React.ReactNode, opts?: AlertOptions) => void) => {
  const setAlert = useHook(({actions: {setAlerts}}) => setAlerts);

  return (component, opts) => {
    return setAlert(boxId, component, opts);
  };
};

const useAlerts = (boxId: string) => {
  return useHook(({alerts}) => alerts.find((x) => x.boxId === boxId));
};

export const AlertSubscriber: React.FC<{boxId: string}> = ({boxId}) => {
  const alert = useAlerts(boxId);
  const alertSetter = useAlert(boxId);

  React.useEffect(() => {
    if (!alert) {
      return;
    }

    let timer: NodeJS.Timeout;

    if (alert.opts?.clearAfter) {
      timer = setTimeout(() => {
        alertSetter(null);
      }, alert.opts.clearAfter);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [alert, alertSetter]);

  return (
    <Collapse in={Boolean(alert)} animateOpacity>
      {alert?.component}
    </Collapse>
  );
};
