import {dataCys} from "@@shared";
import {useResentActivationEmailModal} from "apps/ef-app/src/modals/useResentActivationEmailModal";

import React from "react";

import {Link} from "@ef/components";
import {UseEmailCheckerChatusType} from "@ef/hooks";
import {useLoginModal} from "@ef/modals";
import {useAuth} from "@ef/providers";

import {Box, Collapse, Stack, Text} from "@chakra-ui/react";

const EmailChecker: React.FunctionComponent<{
  emailInput: string;
  status: UseEmailCheckerChatusType;
  customOnClick?: () => void;
}> = ({emailInput, status, customOnClick}) => {
  const {user} = useAuth();
  const {openModal} = useResentActivationEmailModal();

  const {openModal: openLoginModal} = useLoginModal();

  // React.useEffect(() => {
  //   if (user || !emailInput || emailValidator.validate(emailInput)?.error) {
  //     setShowSignInElement("hide");
  //     return;
  //   }

  //   validateEmailWithBe({email: emailInput})
  //     .then((e) => {
  //       if ("data" in e) {
  //         if (e.data.is_created && !e.data.is_verified) {
  //           setShowSignInElement("verificationNeeded");
  //           return;
  //         } else if (e.data.is_created && e.data.is_verified) {
  //           setShowSignInElement("created");
  //           return;
  //         }
  //       }
  //       setShowSignInElement("hide");
  //     })
  //     .catch(() => {
  //       setShowSignInElement("hide");
  //     });
  // }, [emailInput, user, validateEmailWithBe]);

  if (!user && !emailInput) {
    return null;
  }

  return (
    <Collapse in={status === "verificationNeeded" || status === "exists"} animateOpacity>
      <Stack direction="row" align="flex-start">
        <Box pt="5px">
          <Box h="16px" w="3px" bg="ef-primary" />
        </Box>
        {status === "verificationNeeded" && (
          <Text color="ef-primary" fontWeight="bold">
            This email is already registered with EventsFrame but not activated yet.{" "}
            <Link onClick={() => openModal({email: emailInput})}>Resend activation link</Link>
          </Text>
        )}
        {status === "exists" && (
          <Text color="ef-primary" fontWeight="bold">
            You already have an account on EventsFrame{" "}
            <Link
              onClick={() => {
                if (customOnClick) {
                  customOnClick();
                } else {
                  openLoginModal({email: emailInput});
                }
              }}
              whiteSpace="nowrap"
              data-cy={dataCys.eventCheckout.formEmailSingInBtn}
            >
              Sign in
            </Link>
          </Text>
        )}
      </Stack>
    </Collapse>
  );
};

export default EmailChecker;
