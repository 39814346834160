import {dataCys} from "@@shared";
import Joi from "joi";
import {pathOr} from "ramda";

import React, {useState} from "react";
import {useFieldArray} from "react-hook-form";

import {Checkbox, Input, TypingSelect} from "@ef/components";
import {OnFormSubmitType, useForm, useSelectEventDetailByQueryEventId} from "@ef/hooks";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef/modals";

import {Box, Text, Button, Divider, Heading, Stack, Select, CheckboxGroup} from "@chakra-ui/react";

import {CustomAttendeeFieldConfigRead} from "../api/__generated__/beOrgApi.generated";
import {useAttendeeBuilder} from "../scenes/EventCheckout/hooks/useAttendeeBuilder";

export type AttendeeAdded = {
  email: string;
  firstName: string;
  lastName: string;
  customFields: Record<string, string>;
  tickets: Array<string>;
};

type Props = {
  attendeeId?: string;
  availableTickets: {[key: string]: number};
  onAttendeeAdded: (props: AttendeeAdded) => void;
};

type CustomFields = CustomAttendeeFieldConfigRead;

type FormInputsTypes = {
  email: string;
  firstNameLastName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attendeeFields: Array<any>;
};

const CheckoutAttendeeModal: React.FC<Props> = ({
  onAttendeeAdded,
  attendeeId,
  availableTickets = {},
}) => {
  const {data: eventDetail} = useSelectEventDetailByQueryEventId();
  const attendee = useAttendeeBuilder((p) =>
    attendeeId ? p.attendees.find((x) => x._id === attendeeId) : null
  );
  const [assignedTickets, setAssignTickets] = useState<Array<string>>(
    attendee?.tickets ?? Object.keys(availableTickets).length === 1
      ? [Object.keys(availableTickets)[0]]
      : []
  );
  const formInputsSchema = Joi.object({
    email: eventDetail?.require_email_before_checkout
      ? Joi.string().email({tlds: false}).required()
      : Joi.string().email({tlds: false}).allow(""),

    firstNameLastName: eventDetail?.collect_attendee_name
      ? Joi.string().required()
      : Joi.string().allow(""),
    attendeeFields: Joi.array().items(Joi.object()),
  });

  const {control, registerWithError, handleSubmit, setFocusAfter, setError} =
    useForm<FormInputsTypes>(formInputsSchema, {
      keepDataOnSuccess: true,
      defaultValues: {
        email: attendee?.email || "",
        firstNameLastName:
          [attendee?.firstName, attendee?.lastName].filter(Boolean).join(" ") || "",
      },
    });

  const {fields, append} = useFieldArray({control, name: "attendeeFields"});

  const {closeModal} = useModal("CheckoutAttendee");

  React.useEffect(() => {
    setFocusAfter("email", 100);
    eventDetail?.custom_attendee_fields?.forEach((customField) => {
      append(
        {
          required: false,
          customField,
          value: pathOr("", [customField.slug], attendee?.customFields || {}),
        },
        {shouldFocus: false}
      );
    });
  }, []);

  const handleSubmitFields: OnFormSubmitType<FormInputsTypes> = async (data) => {
    const missingCustomFields = data.attendeeFields
      .map((element, index) => ({element, index}))
      .filter((item) => item.element.customField.isRequired && !item.element.value)
      .map((item) => item.index);
    if (missingCustomFields.length > 0) {
      missingCustomFields.forEach((x) =>
        setError(`attendeeFields.${x}.value`, {message: "Required"})
      );
      return;
    }

    const [firstName, ...lastName] = data?.firstNameLastName?.split(" ") || [];

    onAttendeeAdded({
      email: data.email,
      firstName,
      lastName: lastName.join(" "),
      tickets: assignedTickets,
      customFields: data.attendeeFields.reduce((prev, next) => {
        prev[next.customField.slug] = next.value || "";
        return prev;
      }, {}),
    });
    closeModal();
  };

  React.useEffect(() => {
    setAssignTickets(
      attendee?.tickets ?? Object.keys(availableTickets).length === 1
        ? [Object.keys(availableTickets)[0]]
        : []
    );
  }, [attendee?.tickets]);
  return (
    <Box my="1rem" position="relative">
      <Heading variant="h3" px="2rem" pb="0.5rem">
        {attendee?._id ? "Updating attendee" : "New attendee"}
      </Heading>

      <Divider />
      <Box h="fit-content" overflowY="auto">
        <form onSubmit={handleSubmit(handleSubmitFields)}>
          {/* <Box px="2rem" h={["100%", "calc(100vh - 300px)"]} pt="1rem" overflowY="auto"> */}
          <Box px="2rem" pt="1rem">
            <Stack mb="1rem">
              <Input
                type="email"
                {...registerWithError("email")}
                required={eventDetail?.require_email_before_checkout || false}
                placeholder="Email"
                data-cy={dataCys.modals.checkoutAttendees.email}
                label="Email"
              />
              <Input
                {...registerWithError("firstNameLastName")}
                placeholder="First & Last name"
                required={eventDetail?.require_attendee_name_before_checkout || false}
                label="First & Last name"
                data-cy={dataCys.modals.checkoutAttendees.label}
              />

              {fields.map((field: {id: string; customField: CustomFields}, i) => {
                if (field.customField.type === "free_text") {
                  return (
                    <Box>
                      <Input
                        key={field?.customField?.slug || field.id}
                        //@ts-ignore
                        {...registerWithError(`attendeeFields.${i}.value`)}
                        required={field?.customField.isRequired}
                        label={field?.customField?.name}
                        type="text"
                      />
                      <Text fontSize="10px" lineHeight="15px" color="ef-gray">
                        {field?.customField?.description}
                      </Text>
                    </Box>
                  );
                }
                if (field.customField.type === "number") {
                  return (
                    <Box>
                      <Input
                        key={field?.customField?.slug || field.id}
                        //@ts-ignore
                        {...registerWithError(`attendeeFields.${i}.value`)}
                        required={field?.customField.isRequired}
                        label={field?.customField?.name || ""}
                        type="number"
                      />
                      <Text fontSize="10px" lineHeight="15px" color="ef-gray">
                        {field?.customField?.description}
                      </Text>
                    </Box>
                  );
                }
                if (field.customField.type === "dropdown") {
                  return (
                    <Box mb="1rem">
                      <TypingSelect
                        key={field?.customField?.slug || field.id}
                        //@ts-ignore
                        {...registerWithError(`attendeeFields.${i}.value`)}
                        required={field?.customField.isRequired}
                        options={field.customField.enum_options.map((x) => ({
                          name: x,
                          value: x,
                        }))}
                        label={field?.customField?.name}
                      />
                      <Text fontSize="10px" lineHeight="15px" color="ef-gray">
                        {field?.customField?.description}
                      </Text>
                    </Box>
                  );
                }
                if (field.customField.type === "checkbox") {
                  return (
                    <Box mb="1rem">
                      <CheckboxGroup key={field?.customField?.slug || field.id} colorScheme="green">
                        <Text fontSize="14px" lineHeight="21px" color="black">
                          {field?.customField?.name}
                        </Text>
                        <Text fontSize="10px" lineHeight="15px" color="ef-gray">
                          {field?.customField?.description}
                        </Text>
                        <Stack spacing="2" direction="column">
                          {field.customField.enum_options.map((option) => (
                            <Checkbox
                              {...registerWithError(`attendeeFields.${i}.value`)}
                              key={`${field.id}.${option}`}
                              value={option}
                            >
                              {option}
                            </Checkbox>
                          ))}
                        </Stack>
                      </CheckboxGroup>
                    </Box>
                  );
                }
              })}
            </Stack>
          </Box>

          <Box px="2rem" pb="1rem">
            <Text mb="1rem" fontWeight="bold">
              Assign tickets
            </Text>
            <Stack mb="1rem">
              {Object.keys(availableTickets).map((ticketSlug) => {
                const ticket = eventDetail.ticket_types.find((x) => x.id === ticketSlug);
                const isAssigned = assignedTickets.includes(ticketSlug);
                return (
                  <Checkbox
                    data-cy={dataCys.modals.checkoutAttendees.ticket(ticketSlug)}
                    key={ticketSlug}
                    isDisabled={availableTickets[ticketSlug] === 0 && !isAssigned}
                    isChecked={isAssigned}
                    onChange={() =>
                      setAssignTickets((p) =>
                        p.includes(ticketSlug)
                          ? p.filter((x) => x !== ticketSlug)
                          : [...p, ticketSlug]
                      )
                    }
                  >
                    {ticket.name}
                    {availableTickets[ticketSlug] > 1 && (
                      <Text as="span" pl="1rem" color="ef-gray-500" fontSize="14px">
                        ( Remaining: {availableTickets[ticketSlug]} )
                      </Text>
                    )}
                  </Checkbox>
                );
              })}
            </Stack>
            <Button type="submit" w="100%" data-cy={dataCys.modals.checkoutAttendees.saveBtn}>
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export const useCheckoutAttendee: InitialUseCreateModalType<Props> = () =>
  useCreateModal("CheckoutAttendee", CheckoutAttendeeModal, {isFullOnMobile: true});
