import React from "react";

import NextLink from "next/link";
import {useRouter} from "next/router";

import {URL_QUERY_EMBED} from "@ef/constants";
import {resolveChakraSpacing} from "@ef/utils";

import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Button,
  ButtonProps,
  LinkProps,
} from "@chakra-ui/react";

export type ButtonLinkProps = ButtonProps & {
  href?: ChakraLinkProps["variant"];
  isExternal?: ChakraLinkProps["isExternal"];
};

export const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({href, children, ...props}, ref) => {
    const {rest, spacing} = resolveChakraSpacing<ButtonLinkProps, LinkProps, ButtonLinkProps>(
      props
    );

    return (
      //@ts-ignore
      <Link href={href} {...spacing}>
        <Button ref={ref} {...rest} w="inherit">
          {children}
        </Button>
      </Link>
    );
  }
);

const Link = React.forwardRef<HTMLAnchorElement, ChakraLinkProps & {ignoreExternalLogic?: boolean}>(
  ({ignoreExternalLogic, ...props}, ref) => {
    const {query} = useRouter();

    const isExternal = ignoreExternalLogic ? false : URL_QUERY_EMBED in query;

    if (!props?.href) {
      return <ChakraLink ref={ref} {...props} isExternal={isExternal || props.isExternal} />;
    }

    return (
      <ChakraLink
        ref={ref}
        as={NextLink}
        href="/"
        {...props}
        isExternal={isExternal || props.isExternal}
      />
    );
  }
);

ButtonLink.displayName = "ButtonLink";
Link.displayName = "Link";

export default Link;
