import {nanoid} from "nanoid";
import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";

type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type AttendeeBaseTypeAttendeeBuilder = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  customFields: Record<string, string>;
  tickets: Array<string>;
};

export type AttendeeTypeAttendeeBuilder = WithOptional<AttendeeBaseTypeAttendeeBuilder, "_id">;

type StoreType = {
  totalUnassignedTickets: number;
  attendees: Array<AttendeeTypeAttendeeBuilder>;
  __actions: StoreActionTypes;
};

type StoreActionTypes = {
  addNewAttendee: (newAttendee: Partial<AttendeeTypeAttendeeBuilder>) => void;
  updateAttendee: (attendeeid: string, newAttendee: Partial<AttendeeTypeAttendeeBuilder>) => void;
  deleteAttendee: (attendeeid: string) => void;
  updateAttendeeTickets: (
    attendeeid: string,
    tickets: AttendeeBaseTypeAttendeeBuilder["tickets"]
  ) => void;
  setTotalUnassignedTickets: (sum: number) => void;
  clearStore: () => void;
};

const useAttendeeBuilder = create(
  subscribeWithSelector<StoreType>((set) => ({
    attendees: [],
    totalUnassignedTickets: 0,
    __actions: {
      setTotalUnassignedTickets: (totalUnassignedTickets) => set({totalUnassignedTickets}),
      addNewAttendee: (newAttendee) =>
        set((p) => ({
          attendees: [
            ...p.attendees,
            {
              _id: nanoid(),
              tickets: newAttendee.tickets || [],
              email: newAttendee.email || "",
              firstName: newAttendee.firstName || "",
              lastName: newAttendee.lastName || "",
              customFields: newAttendee.customFields || {},
            },
          ],
        })),
      updateAttendee: (attendeeId, newAttendee) =>
        set((p) => ({
          attendees: p.attendees.map((att) => {
            if (att._id === attendeeId) {
              return {
                ...att,
                tickets: newAttendee.tickets || att.tickets || [],
                email: newAttendee.email || "",
                firstName: newAttendee.firstName || "",
                lastName: newAttendee.lastName || "",
                customFields: newAttendee.customFields || {},
              };
            }

            return att;
          }),
        })),
      deleteAttendee: (attendeeId) =>
        set((prev) => ({attendees: prev.attendees.filter((x) => x._id !== attendeeId)})),
      updateAttendeeTickets: (attendeeId, tickets) =>
        set((p) => ({
          attendees: p.attendees.map((x) => (x._id === attendeeId ? {...x, tickets} : x)),
        })),
      clearStore: () => set((p) => ({attendees: [], totalUnassignedTickets: 0})),
    },
  }))
);

export {useAttendeeBuilder};
