import Joi from "joi";

import React from "react";

import {NftDataResponse, usePostPy3ApiNftByNftIdWithdrawalMutation} from "@ef/api";
import {Input, Link} from "@ef/components";
import {OnFormSubmitType, useForm, useSelectEventDetailByQueryEventId} from "@ef/hooks";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef/modals";
import {useStrictAuth} from "@ef/providers";
import {mixpanelTrackEvent} from "@ef/utils";

import {CloseIcon, Icon} from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AspectRatio,
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import {NFTCanvas} from "../components/NFTCanvas";
import {is3D} from "../helpers/nftHelpers";
import {useWithdrawalEmailModal} from "./useWithdrawalEmailModal";

type FormInputsTypes = {
  hash: string;
};

const formInputsSchema = Joi.object({
  hash: Joi.string().regex(/^0x[a-fA-F0-9]{40}$/),
});

type WithdrawalModalProps = {
  nftDetail: NftDataResponse;
  refetch: () => void;
};

const WithdrawalModal: React.FC<WithdrawalModalProps> = ({nftDetail, refetch}) => {
  const {closeModal} = useModal("WithdrawalModal");
  const {openModal: openEmailModal} = useWithdrawalEmailModal();
  const [alert, setAlert] = React.useState<{type: "success"} | {type: "error"; text: string}>();
  const {data: eventDetail} = useSelectEventDetailByQueryEventId();

  const [step, setStep] = React.useState<"step1" | "step2">("step1");
  const [hashCode, setHashCode] = React.useState("");
  const [initiate, {isLoading}] = usePostPy3ApiNftByNftIdWithdrawalMutation();
  const {user} = useStrictAuth();

  const handleInitiation = React.useCallback(() => {
    initiate({
      nftId: nftDetail.id,
      nftWithdrawalRequestPayload08Fd148: {
        destination_wallet_address: hashCode,
      },
    }).then((data) => {
      if ("error" in data) {
        mixpanelTrackEvent("NFT:WITHDRAWAL:INITIALIZATION_ERROR", {
          eventId: eventDetail.id,
          eventName: eventDetail.name,
          nftId: nftDetail.id,
        });
        //@ts-ignore swagger
        setAlert({type: "error", text: data?.error?.data[0]?.msg || "Something went wrong."});
        return;
      }

      mixpanelTrackEvent("NFT:WITHDRAWAL:INITIALIZATION_SUCCESSFUL", {
        eventId: eventDetail.id,
        eventName: eventDetail.name,
        nftId: nftDetail.id,
      });

      setAlert({type: "success"});
      setTimeout(() => {
        closeModal();
        //@ts-ignore fix
        openEmailModal({withdrawalId: data?.data?.id, nftDetail, refetch});
      }, 2000);
    });
  }, [hashCode, nftDetail.id]);

  const {
    registerWithError,
    handleSubmit,
    formState: {isSubmitting},
  } = useForm<FormInputsTypes>(formInputsSchema, {
    defaultValues: {hash: ""},
    keepDataOnSuccess: true,
  });

  const handleSubmitWalletAddress: OnFormSubmitType<FormInputsTypes> = async ({hash}) => {
    setHashCode(hash);
    setStep("step2");
    mixpanelTrackEvent("NFT:WITHDRAWAL:WALLET_ID_ADDED", {
      eventId: eventDetail.id,
      eventName: eventDetail.name,
      nftId: nftDetail.id,
    });
  };
  return (
    <Box p="24px">
      <Stack direction="row" justify="space-between" align="center">
        <Heading variant="h3" lineHeight="22px">
          NFT Withdrawal
        </Heading>
        <Stack direction="row" align="center" onClick={closeModal} cursor="pointer">
          <Text size="small">Close</Text>
          <CloseIcon boxSize="13px" />
        </Stack>
      </Stack>

      <Heading w="100%" variant="h4" fontSize={["17px", "unset"]} mt="2rem" mb="1rem">
        {/* @ts-ignore swagger */}
        {nftDetail?.api_response.public_data.ticket_name}
      </Heading>
      <Stack direction={["column", "row"]} spacing="2rem" align="flex-start">
        <AspectRatio ratio={1} w="100%">
          {is3D(nftDetail?.style) ? (
            <NFTCanvas nftAssetUrl={nftDetail?.style.url} nftId={nftDetail?.style?.id} />
          ) : (
            <Stack>
              <Image
                src={nftDetail?.style?.url}
                alt="nft-modal-detail"
                // w="100%"
                // h="auto"
                // objectFit="none"
                borderRadius="10px"
              />
            </Stack>
          )}
        </AspectRatio>
        <Box w={["100%", "180%"]} h="100%">
          <Box p="1rem" borderRadius="6px" bg="ef-light-yellow" mb="1rem">
            <Stack direction="row" align="flex-start">
              <Icon width="19px" height="19px" viewBox="0 0 16 16" fill="none" mt="2px">
                <path
                  d="M8.00016 14.6693C11.6821 14.6693 14.6668 11.6845 14.6668 8.0026C14.6668 4.32071 11.6821 1.33594 8.00016 1.33594C4.31826 1.33594 1.3335 4.32071 1.3335 8.0026C1.3335 11.6845 4.31826 14.6693 8.00016 14.6693Z"
                  stroke="#F3B555"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 10.6667V8"
                  stroke="#F3B555"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 5.33594H8.00744"
                  stroke="#F3B555"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Icon>
              <Text size="small">
                {step === "step1" ? (
                  <>
                    Please make sure that your use{" "}
                    <Tooltip
                      maxW="500px"
                      bg="white"
                      borderRadius="6px"
                      p="1rem"
                      label={
                        <Box>
                          <Text color="ef-black">
                            <b>Polygon Chain Wallet Address</b> is a sequence of numbers and
                            characters used to send assets to the Polygon wallet that usually has
                            the following format: <br />
                            <b>0x329CdCBBD82c934fe32322b423bD8fBd30b4EEB6.</b>
                          </Text>
                        </Box>
                      }
                    >
                      <b>
                        <u>Polygon Chain Wallet Address</u>
                      </b>
                    </Tooltip>{" "}
                    and you have access to it.
                  </>
                ) : (
                  <>
                    Make sure you have written the correct wallet address to <b>avoid losing</b>{" "}
                    your NFT item.
                  </>
                )}
              </Text>
            </Stack>
            {step === "step1" && (
              <Link
                variant="blue"
                href="https://support.eventsframe.com/article/132-nft-withdrawal"
                target="_blank"
              >
                <Stack direction="row" align="center" mt="1rem">
                  <Icon width="5" height="5" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667"
                      stroke="#192BC2"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.5 2.5H17.5V7.5"
                      stroke="#192BC2"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.33398 11.6667L17.5007 2.5"
                      stroke="#192BC2"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </Icon>
                  <Text size="small">Withdraw NFT to private wallet</Text>
                </Stack>
              </Link>
            )}
          </Box>
          {step === "step1" ? (
            <form onSubmit={handleSubmit(handleSubmitWalletAddress)}>
              <Text variant="form-label">Please enter your wallet address</Text>
              <Input
                h="44px"
                {...registerWithError("hash")}
                placeholder="Enter your wallet address"
              />
              <Stack spacing="0">
                <Button
                  w={["100%", "70%"]}
                  variant="blue"
                  type="submit"
                  isLoading={isSubmitting}
                  alignSelf="flex-end"
                  mt="3rem"
                >
                  Request withdrawal
                </Button>
              </Stack>
            </form>
          ) : (
            <Box>
              <Text variant="form-label">Your wallet address</Text>
              <Box bg="ef-gray-l1" p="10px 1rem" borderRadius="6px">
                <Text overflowWrap="anywhere" color="ef-blue" fontWeight="bold">
                  {hashCode}
                </Text>
              </Box>
              <Box position="relative">
                {alert &&
                  (alert.type === "error" ? (
                    <Box pb="1rem" mt="10px" position="absolute" w="100%">
                      <Alert status="error">
                        <AlertIcon boxSize="16px" />
                        <AlertDescription fontWeight="bold" color="red.500">
                          {alert.text}
                        </AlertDescription>
                      </Alert>
                    </Box>
                  ) : (
                    <Box pb="1rem" mt="10px" position="absolute" w="100%">
                      <Alert status="success">
                        <AlertIcon boxSize="16px" />
                        <AlertDescription fontWeight="bold" color="green.500">
                          Success!
                        </AlertDescription>
                      </Alert>
                    </Box>
                  ))}
              </Box>
              <Stack
                direction={["column", "row"]}
                spacing="1rem"
                justify={["unset", "flex-end"]}
                mt={["20px", "90px"]}
              >
                <Button
                  variant="blueOutline"
                  isLoading={isSubmitting}
                  alignSelf={["unset", "flex-end"]}
                  onClick={() => setStep("step1")}
                >
                  Cancel
                </Button>
                <Button
                  variant="blue"
                  alignSelf={["unset", "flex-end"]}
                  onClick={handleInitiation}
                  isLoading={isLoading}
                >
                  Confirm
                </Button>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export const useWithdrawalModal: InitialUseCreateModalType<WithdrawalModalProps> = () =>
  useCreateModal("WithdrawalModal", WithdrawalModal, {
    hideCloseButton: true,
    size: "3xl",
    isFullOnMobile: true,
  });
