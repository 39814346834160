import {groupBy, isEmpty, pick} from "ramda";

import {useSelector} from "react-redux";

import {useRouter} from "next/router";

import {beApi, beNftApi, beOrgApi, NftDataItem} from "@ef/api";
import {useAuth} from "@ef/providers";

export const useSelectNFTTemplates = () => {
  const {user} = useAuth();

  return useSelector(
    beNftApi.endpoints.getNftappApiAccountByAccountIdTemplate.select({
      accountId: user?.last_account_id || "0",
    })
  );
};

export const useSelectNFTEnums = () => {
  return useSelector(beNftApi.endpoints.getNftappApiEnums.select());
};

export const useSelectOrderByQueryOrderId = () => {
  const {query} = useRouter();

  return useSelector(
    beApi.endpoints.getPy3ApiOrdersByOrderId.select({
      orderId: query.orderId?.toString() || "",
    })
  );
};

export const useSelectAllTickets = () => {
  const {data: orderData} = useSelectOrderByQueryOrderId();

  return useSelector(
    beApi.endpoints.getPy3ApiOrderByOrderIdTickets.select({
      orderId: orderData?.id,
    })
  );
};

export const useSelectOrderTicketsByQueryOrderId = () => {
  const {query} = useRouter();

  return useSelector(
    beApi.endpoints.orderTickets.select({
      orderId: query.orderId?.toString() || "",
    })
  );
};

export const useSelectEventDetailByQueryEventId = () => {
  const {query} = useRouter();

  return useSelector(
    beOrgApi.endpoints.getEventWithIdEventsEventIdGet.select({
      eventId: query.eventId?.toString() || "",
    })
  );
};

export const useSelectEventDetailBillingByQueryEventId = () => {
  const {query} = useRouter();

  return useSelector(
    beApi.endpoints.getPy3ApiEventByEventIdBilling.select({
      eventId: query.eventId?.toString() || "",
    })
  );
};

export const useSelectNftStylesByAccountId = () => {
  const {user} = useAuth();

  return useSelector(
    beNftApi.endpoints.getNftappApiAccountByAccountIdStyle.select({
      accountId: user?.last_account_id || "0",
    })
  );
};

export const useSelectMerchFromOrderDetailByQueryId = () => {
  const {data: orderData} = useSelectOrderByQueryOrderId();

  const {data: merchData, isLoading} = useSelector(
    beApi.endpoints.getPy3ApiOrderByOrderIdMerchandise.select({
      orderId: orderData?.id,
    })
  );

  return {merchData, isLoading};
};

export const useSelectNftTicketsFromOrderDetailByQueryId = () => {
  const {data: orderData} = useSelectOrderByQueryOrderId();

  const {data, isLoading} = useSelector(
    beApi.endpoints.getPy3ApiOrderByOrderIdTickets.select({
      orderId: orderData?.id,
    })
  );

  const nftTickets =
    data &&
    !isEmpty(data?.items) &&
    data.items
      .map((item) => {
        if (item?.is_nft_ticket) return item;
      })
      .filter(Boolean);

  return {ticketData: nftTickets, isLoading};
};

export const useSelectAllAttendeesByQueryOrderId = () => {
  const {query} = useRouter();

  return useSelector(
    beApi.endpoints.allAttendees.select({
      orderId: query.orderId?.toString() || "",
    })
  );
};

export const useSelectNftDetailByQueryNftId = () => {
  const {query} = useRouter();

  return useSelector(
    beApi.endpoints.getPy3ApiNftByNftId.select({nftId: query?.nftId?.toString() || ""})
  );
};

export const useSelectNftByNftIdWithdrawals = () => {
  const {query} = useRouter();
  return useSelector(
    beApi.endpoints.getPy3ApiNftByNftIdWithdrawals.select({nftId: query?.nftId?.toString() || ""})
  );
};

export const useSelectAllBoughtNfts = () => {
  return useSelector(beApi.endpoints.getPy3ApiNft.select());
};

export const useSelectAllNftsInState = (): {
  minting: Array<NftDataItem>;
  merch: Array<NftDataItem>;
  inSomeState: boolean;
  failedCount: number;
} => {
  const {data} = useSelectAllBoughtNfts();

  const group = groupBy((nft) => {
    if (["init", "requested"].includes(nft?.status)) {
      return "minting";
    }

    return nft?.status || "none";
  }, data?.items || []);

  const picked = pick(["minting", "error"], group);
  //@ts-ignore
  const onlySomeStates: Array<NftDataItem> = [...(picked?.minting || []), ...(picked?.error || [])];

  return {
    minting: onlySomeStates.filter((x) => !x.merchandise),
    merch: onlySomeStates.filter((x) => x.merchandise),
    inSomeState: !isEmpty(onlySomeStates),
    failedCount: onlySomeStates.filter((x) => x.status === "error").length,
  };
};
