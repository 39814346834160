import React, {useState} from "react";

import {
  useGetPy3ApiUserProfileMutation,
  usePostPy3ApiUserLogoutMutation,
  UserProfile,
} from "@ef/api";
import {COOKIES_USER_ID} from "@ef/constants";
import {createContext} from "@ef/helpers";
import {encryptDataToString, mixpanelIdentifyUser} from "@ef/utils";

import {useRedirectToLogin} from "../../utils/redirects";

type AuthAdditionalProps = {
  isLoading: boolean;
  refetchUserInfo: () => Promise<void> | void;
  logout: (callback?: () => Promise<void> | void) => void;
  setUserManually: (user: UserProfile) => void;
};

type AuthProvider = {user?: UserProfile} & AuthAdditionalProps;
type AuthProviderStrict = {user: UserProfile} & AuthAdditionalProps;

const [AuthContextProvider, useAuth] = createContext<AuthProvider>({
  strict: true,
  name: "AuthProvider",
});

const useStrictAuth = (): AuthProviderStrict => {
  return useAuth() as AuthProviderStrict;
};

export {useAuth, useStrictAuth};

const AuthProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [{isLoading, user}, setUserObj] = useState<{isLoading: boolean; user?: UserProfile}>({
    isLoading: true,
  });
  const [call] = useGetPy3ApiUserProfileMutation();
  const [logout] = usePostPy3ApiUserLogoutMutation();
  const redirectToLogin = useRedirectToLogin({noBackToUrl: true});

  React.useEffect(() => {
    window.USER_LOGGED_IN = Boolean(user);

    // This code is need for the mixpanel reporting lib
    if (user?.user_id) {
      mixpanelIdentifyUser(user?.user_id?.toString(), {email: user.email});
      const val = encryptDataToString(
        JSON.stringify({uid: user?.user_id?.toString(), email: user.email})
      );
      sessionStorage.setItem(COOKIES_USER_ID, val);
    } else {
      sessionStorage.removeItem(COOKIES_USER_ID);
    }
  }, [user]);

  // React.useEffect(() => {
  //   call()
  //     .then((x) => {
  //       // const searchQuery = new URLSearchParams(window.location.search);
  //       if ("data" in x && x?.data?.user) {
  //         setUserObj({isLoading: false, user: x.data.user});
  //         return;
  //       }
  //       setUserObj({isLoading: false});
  //     })
  //     .catch(() => {
  //       setUserObj({isLoading: false});
  //     });
  // }, []);

  return (
    <AuthContextProvider
      value={{
        user,
        setUserManually: (usr) => setUserObj((p) => ({...p, user: usr})),
        isLoading,
        refetchUserInfo: async () => {
          await call().then((x) => {
            if ("data" in x && x?.data?.user) {
              setUserObj((p) => ({...p, user: x.data.user}));
            }
          });
        },
        logout: async (callback) => {
          try {
            await logout();
          } catch (error) {}
          setUserObj((p) => ({...p, user: undefined}));
          if (callback) {
            await callback();
            return;
          } else {
            redirectToLogin.redirect();
          }
        },
      }}
    >
      {children}
    </AuthContextProvider>
  );
};

export default AuthProvider;
