import {useRouter} from "next/router";

/** Location: /login?backToUrl={currentURL} */
export const useRedirectToLogin = (
  {noBackToUrl}: {noBackToUrl?: boolean} = {noBackToUrl: false}
) => {
  const {push} = useRouter();
  return {
    redirect: () =>
      push(
        `/login${
          noBackToUrl
            ? ""
            : `?backToUrl=${encodeURIComponent(
                `${window.location.pathname.replace("/app/", "/")}${window.location.search}${
                  window.location.hash
                }`
              )}`
        }`
      ),
  };
};
