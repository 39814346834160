import {NextPage} from "next";

import withAuthRedirect from "./withAuthRedirect";

// import withAuthRedirect from "@hooks/withAuthRedirect";
// import { ILocation } from "@hooks/useRouter";

/**
 * Require the user to be authenticated in order to render the component.
 * If the user isn't authenticated, forward to the given URL.
 */
export default function withAuth<P>(
  WrappedComponent: NextPage<P>,
  location = "/login" //ILocation = {route: "index"}
): NextPage<P> {
  return withAuthRedirect({
    WrappedComponent,
    location,
    expectedAuth: true,
  });
}
