// import {PhoneNumberUtil as GoolePhoneNumber} from "google-libphonenumber";
import Joi from "joi";

// import {resolveTranslation} from "../utils/localization";

export const JoiPhoneValidator = Joi.string();
// .custom((value, helpers) => {
//   try {
//     const phoneUtil = new GoolePhoneNumber();

//     const number = phoneUtil.parseAndKeepRawInput(value);

//     if (phoneUtil.isValidNumber(number)) {
//       return true;
//     }

//     //@ts-expect-error fix
//     return helpers.message(resolveTranslation("en", "phoneNumber.invalid"));
//   } catch (error) {
//     if ((error as Error)?.message === "Invalid country calling code") {
//       // @ts-expect-error fix
//       return helpers.message(resolveTranslation("en", "phoneNumber.code.invalid"));
//     }
//     // @ts-expect-error fix
//     return helpers.message(resolveTranslation("en", "phoneNumber.invalid"));
//   }
// });
