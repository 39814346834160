import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";

import {OrderItemType} from "@ef/api";

export type Ticket = {
  type_id: string;
  quantity: number;
  type: OrderItemType;
};

export type CalculationError = {
  type: string;
  message: string;
};

type StoreType = {
  tickets: Array<Ticket>;
  discountCodes: Array<string>;
  requestId: string;
  actions: {
    setTickets: (tickets?: Array<Ticket>) => void;
    addDiscountCode: (code: string) => void;
    changeTicket: (ticket: Ticket) => void;
    setRequestId: (id: string) => void;
    setCalculationErrors: (tickets?: Array<CalculationError>) => void;
  };
  calculationErrors: Array<CalculationError>;
};

const eventCheckoutStore = create(
  subscribeWithSelector<StoreType>((set) => ({
    tickets: [],
    calculationErrors: [],
    discountCodes: [],
    requestId: "none",
    actions: {
      setTickets: (tickets = []) => set(() => ({tickets})),
      addDiscountCode: (code) => set((state) => ({discountCodes: [...state.discountCodes, code]})),
      setRequestId: (requestId) => set(() => ({requestId})),
      changeTicket: (props) => {
        set(({tickets}) => ({
          tickets:
            props.quantity === 0
              ? tickets.filter((x) => x.type_id !== props.type_id)
              : tickets.some((x) => x.type_id === props.type_id)
              ? tickets.map((x) => ({
                  ...x,
                  quantity: x.type_id === props.type_id ? props.quantity : x.quantity,
                }))
              : [...tickets, props],
        }));
      },
      setCalculationErrors: (calculationErrors = []) => set(() => ({calculationErrors})),
    },
  }))
);

export {eventCheckoutStore};
