import {beApi} from "./beApi.generated";

const enhanceApis = beApi.enhanceEndpoints({
  addTagTypes: ["nftMinting"],
  endpoints: {
    getPy3ApiNft: {providesTags: ["nftMinting"]},
    postPy3ApiNftsByNftIdRetry: {
      invalidatesTags: ["nftMinting"],
    },
  },
});

export const {usePostPy3ApiNftsByNftIdRetryMutation, useGetPy3ApiNftQuery} = enhanceApis;
