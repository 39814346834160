import React from "react";

import {InitialUseCreateModalType, useCreateModal} from "@ef/modals";

import {Box, Heading, Text} from "@chakra-ui/react";

type ActivationEmailSentModalProps = {email: string};

const ActivationEmailSentModal: React.FC<ActivationEmailSentModalProps> = ({email}) => {
  return (
    <Box p="2rem">
      <Heading w="100%" variant="h3" textAlign="center" mb="1rem">
        Account created successfully.
      </Heading>

      <Text w="100%" textAlign="center" fontSize="small">
        Check your <b>{email}</b> to finish the activation process.
      </Text>
    </Box>
  );
};

export const useActivationEmailModal: InitialUseCreateModalType<
  ActivationEmailSentModalProps
> = () => useCreateModal("activationEmailSentModal", ActivationEmailSentModal);
