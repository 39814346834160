import * as React from "react";
import {FieldError} from "react-hook-form";

import {resolveChakraSpacing} from "@ef/utils";

import {
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Stack,
  Text,
} from "@chakra-ui/react";

export type CheckboxProps = Omit<ChakraCheckboxProps, "type"> & {
  error?: FieldError;
  dataTest?: string;
  customError?: string;
  customMessage?: string;
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({error, customMessage, dataTest, customError, ...props}, ref) => {
    const newProps = resolveChakraSpacing<CheckboxProps, CheckboxProps, CheckboxProps>(props);

    return (
      <Box w="100%" {...newProps.spacing}>
        <ChakraCheckbox
          ref={ref}
          border-color={Boolean(error) ? "ef-red" : "ef-border"}
          data-test={dataTest}
          {...newProps.rest}
        />
        {error?.message && (
          <Stack direction="row" align="center" w="fit-content" pt="4px">
            <Box h="16px" w="3px" bg="ef-red" />
            <Text variant="form-error">{customError ? customError : error.message}</Text>
          </Stack>
        )}
        {customMessage && (
          <Text variant="form-label" mt="-5px" size="small" color="ef-gray">
            {customMessage}
          </Text>
        )}
      </Box>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
