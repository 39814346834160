import React from "react";

import {ButtonLink, EfFullLogo, LogoSquare, IconUser, Image} from "@ef/components";
import {ORGANISER_ROLE_ORG_ENDPOINT} from "@ef/constants";
import {useAuth} from "@ef/providers";

import {ArrowBackIcon, ExternalLinkIcon} from "@chakra-ui/icons";
import {Container, Divider, Grid, Heading, Link, Stack, Text} from "@chakra-ui/react";

const Custom500: React.FC = () => {
  // const {user} = useAuth();

  return (
    <Stack w="100%" h={[undefined, "100vh"]} align="center" justify={[undefined, "space-between"]}>
      <Container maxW="1380px" py="2rem">
        <Stack direction="row" align="center" justify="space-between">
          <Link href="https://eventsframe.com">
            <EfFullLogo w="auto" h="24px" display={["none", "block"]} />
            <LogoSquare w="auto" h="24px" display={["block", "none"]} />
          </Link>

          <Stack direction="row" align="center" spacing="2rem" display={["none", "none", "block"]}>
            <Link href="https://support.eventsframe.com" variant="blackWithNoDecoration" isExternal>
              Support <ExternalLinkIcon />
            </Link>
          </Stack>

          <Stack direction="row" align="center" spacing={["1rem", "1rem", "2rem"]}>
            <ButtonLink href="https://app.eventsframe.com/order/sVU" variant="blue">
              Create event
            </ButtonLink>
          </Stack>
        </Stack>
      </Container>

      <Container maxW="1280px" h={["auto", "640px"]} maxH={["auto", "640px"]}>
        <Grid
          templateColumns={["repeat(1,1fr)", "repeat(1,1fr)", "50% 50%"]}
          w="100%"
          h="100%"
          gap="2rem"
        >
          <Stack justify="center" align={["center", "center", "normal"]}>
            <Text color="ef-blue">500 error</Text>

            <Heading variant="h1" pb="1rem">
              Woops!
            </Heading>

            <Text pb="2rem" maxW="80%" textAlign={["center", "center", "left"]}>
              Something went wrong :(
            </Text>

            <Stack direction={["column", "row"]} align="center" spacing="1.5rem">
              <ButtonLink
                onClick={() => window.location.reload()}
                variant="grayOutline"
                leftIcon={<ArrowBackIcon />}
              >
                Reload
              </ButtonLink>
              <ButtonLink variant="blue" href={ORGANISER_ROLE_ORG_ENDPOINT}>
                Take me home
              </ButtonLink>
            </Stack>
          </Stack>
          <Image
            objectFit="none"
            width="100%"
            height="100%"
            alt="error-image"
            src="/images/errors/landing-error.png"
          />
        </Grid>
      </Container>

      <Divider pt="2rem" display={["block", "none"]} />

      <Container maxW="1280px" pb="2rem" pt={["2rem", "5rem", "2rem"]}>
        <Stack
          direction={["column", "column", "row"]}
          spacing={["2rem", undefined]}
          align="center"
          justify="space-between"
        >
          <Stack direction={["column", "column", "row"]} align="center" spacing="1rem">
            <EfFullLogo w="auto" h="20px" />
            <Text size="small" color="ef-gray">
              © Copyright 2022 EventsFrame. All rights reserved.
            </Text>
          </Stack>

          <Stack direction="row" align="center" spacing="1rem">
            <Link
              variant="baseDecorationSwitched"
              textDecoration="none"
              href="https://twitter.com/eventsframe"
            >
              Twitter
            </Link>
            <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
            <Link
              href="https://support.eventsframe.com"
              variant="baseDecorationSwitched"
              textDecoration="none"
            >
              Support
            </Link>
            <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
            <Link
              variant="baseDecorationSwitched"
              textDecoration="none"
              href="https://support.eventsframe.com"
            >
              Contact
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Custom500;
