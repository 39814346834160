import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from "@reduxjs/toolkit/query";

export const addAxiosHeaders = () => {
  const headers = {"Content-Type": "application/json"};

  // if (process.env.NEXT_PUBLIC_ENV_TYPE === "local") {
  //   Object.assign({"x-efdev": process.env.NEXT_PUBLIC_DEV_NAME || ""}, headers);
  //   return headers;
  // }

  return headers;
};

export const addAPIExtraHeaders = (headers: Headers) => {
  if (process.env.NEXT_PUBLIC_ENV_TYPE === "local") {
    headers.set("x-efdev", process.env.NEXT_PUBLIC_DEV_NAME || "");
  }
  return headers;
};

const fetchBaseQueryInstance = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API_URL,
  // credentials: "include",
  prepareHeaders: (headers) => {
    return addAPIExtraHeaders(headers);
  },
});

export const baseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  return await fetchBaseQueryInstance(args, api, extraOptions);
};
