import {differenceInDays, format, isPast, parseISO} from "date-fns";
import {is, isEmpty} from "ramda";

import {NextSeo} from "next-seo";

import {GetPy3ApiEventsByEventIdApiResponse} from "@ef/api";

export const scrollToEventSection = ({
  id,
  offset = 0,
  isUser,
}: {
  id: string;
  offset?: number;
  isUser: boolean;
}) => {
  if (!document || !window) return;
  const element = document?.getElementById(id);
  const elementPosition = element?.getBoundingClientRect().top || 0;
  const navButtonsTopPosition =
    document.getElementById("nav-buttons")?.getBoundingClientRect()?.top - (isUser ? 150 : 70);
  const isCollapsed = navButtonsTopPosition <= 0;
  const offsetPosition = elementPosition + window.pageYOffset - offset - (isCollapsed ? 70 : 0);

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const resolveIsRegistrationOpen = (detail: GetPy3ApiEventsByEventIdApiResponse) =>
  detail.reg_open_iso === null ? true : isPast(parseISO(detail.reg_open_iso));

export const formatDate = (date: string) => {
  if (!is(String, date)) return "";

  try {
    return format(parseISO(date), "MMM dd, yyyy, hh:mm bbbb");
  } catch {
    return "";
  }
};

// export const getLowestTicketPrice = (
//   tickets: GetPy3ApiEventsByEventIdApiResponse["ticket_kinds"]
// ) => {
//   const lowest = sort((a, b) => a.price - b.price, tickets)[0];

//   if (!lowest) return "";

//   return formatCurrency(lowest.currency, lowest.price);
// };

export const resolveRegistrationOpenDate = (detail: GetPy3ApiEventsByEventIdApiResponse) =>
  format(parseISO(detail.reg_open_iso), "PP, pppp");

export const resolveRegistrationCloseDate = (detail: GetPy3ApiEventsByEventIdApiResponse) => {
  if (typeof detail?.reg_close_iso !== "string") return "";

  const lastXDays = resolveRegistrationLast3Days(detail);

  if (typeof lastXDays !== "number") return "";

  return format(parseISO(detail.reg_close_iso), "PP, pppp");
};

export const resolveRegistrationLast3Days = (detail: GetPy3ApiEventsByEventIdApiResponse) => {
  if (!resolveIsRegistrationOpen(detail) || typeof detail?.reg_close_iso !== "string") return false;

  const lastXDays = differenceInDays(parseISO(detail.reg_close_iso), new Date());
  if (lastXDays <= 3) return lastXDays;

  return false;
};

export const resolveRegistrationNotAvailable = (detail: GetPy3ApiEventsByEventIdApiResponse) => {
  const allFreeTickets = (detail?.ticket_kinds || []).every((x) => x.price === 0);
  const isPublished = detail.status === "published";
  const paymentsEnabled = detail.paid_event && detail.payment_methods.length > 0;

  return isPublished && !allFreeTickets && !paymentsEnabled;
};

export const isEventPublished = (detail: GetPy3ApiEventsByEventIdApiResponse) =>
  detail.status === "published";

export const isRegistrationFullyOpen = (detail: GetPy3ApiEventsByEventIdApiResponse) => {
  const isRegistrationOpen = resolveIsRegistrationOpen(detail);
  const registrationNotAvailable = resolveRegistrationNotAvailable(detail);

  return (
    detail.status !== "published" ||
    !isRegistrationOpen ||
    isEmpty(detail.ticket_kinds) ||
    registrationNotAvailable
  );
};

export const isEventSoldout = (tickets: GetPy3ApiEventsByEventIdApiResponse["ticket_kinds"]) => {
  return tickets.every(
    (x) => x.available_order_batch.length === 1 && x.available_order_batch[0] === 0
  );
};

export const checkNoTickets = (tickets: GetPy3ApiEventsByEventIdApiResponse["ticket_kinds"]) => {
  return !tickets.length;
};
