/* eslint-disable @typescript-eslint/ban-types */
import React, {ReactElement} from "react";

import {NextPage} from "next";

import {useAuth} from "@ef/providers";

import {useRedirectToLogin} from "../utils/redirects";

export function isBrowser(): boolean {
  return typeof window !== "undefined";
}

// const StyledLoadingContainer = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 0;
//   right: 0;
//   margin: auto;
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
//   transform: translateY(-50%);
// `;

function DefaultLoadingFallback(): ReactElement {
  return null;
  // <Stack position="fixed" w="100%" h="100%" justify="center" align="center">
  //   <Spinner color="ef-primary" />
  // </Stack>
}

/**
 * Support client-side conditional redirecting based on the user's
 * authenticated state.
 *
 * @param WrappedComponent The component that this functionality
 * will be added to.
 * @param LoadingComponent The component that will be rendered while
 * the auth state is loading.
 * @param expectedAuth Whether the user should be authenticated for
 * the component to be rendered.
 * @param location The location to redirect to.
 */
export default function withAuthRedirect<CP = {}, IP = CP>({
  WrappedComponent,
  LoadingComponent = DefaultLoadingFallback,
  expectedAuth,
}: // location,
{
  WrappedComponent: NextPage<CP, IP>;
  LoadingComponent?: NextPage;
  expectedAuth: boolean;
  location?: string; //ILocation;
}): NextPage<CP, IP> {
  const WithAuthRedirectWrapper: NextPage<CP, IP> = (props) => {
    // const {user, isLoading} = useAuth(); // isLoading: isUserLoading
    const user = null;
    const isLoading = false; // isLoading: isUserLoading
    const {redirect} = useRedirectToLogin();

    if (isLoading) {
      return <LoadingComponent />;
    }

    if (isBrowser() && expectedAuth !== Boolean(user)) {
      redirect();
      return <></>;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuthRedirectWrapper;
}
