import React from "react";

import {resolvePrefix} from "@ef/helpers";

import {Image as ChakraImage, ImageProps as ChakraImageProps} from "@chakra-ui/react";

const Image = React.forwardRef<HTMLImageElement, ChakraImageProps>(
  ({src, fallbackSrc, ...props}, ref) => (
    <ChakraImage
      ref={ref}
      src={resolvePrefix(src)}
      fallbackSrc={resolvePrefix(fallbackSrc)}
      {...props}
    />
  )
);

Image.displayName = "Image";

export default Image;
