import Cookies from "js-cookie";
import {nanoid} from "nanoid";

const VISITOR_ID = "visitorId";

export const getVisitorId = () => Cookies.get(VISITOR_ID);

export const setVisitorId = () => {
  const randomGuid = nanoid();
  Cookies.set(VISITOR_ID, randomGuid, {expires: 3650});

  return randomGuid;
};

export const initVisitorIdCookie = () => {
  const initialCookie = getVisitorId();

  if (initialCookie) {
    return initialCookie;
  }

  return setVisitorId();
};
