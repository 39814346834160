import {dataCys} from "@@shared";

import React from "react";

import {usePostPy3ApiUserLoginMutation} from "@ef/api";
import {Input, Link} from "@ef/components";
import {OnFormSubmitType, useForm} from "@ef/hooks";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef/modals";
import {useAuth} from "@ef/providers";

import {Alert, AlertDescription, AlertIcon, Box, Button, Heading, Stack} from "@chakra-ui/react";

import {AlertSubscriber, useAlert} from "../providers/AlertProvider";
import {formInputsSchema, FormInputsType} from "../scenes/Login/Login";

type LoginModalProps = {email: string};

const LoginModal: React.FC<LoginModalProps> = ({email}) => {
  const {closeModal} = useModal("loginModal");
  const [loginApi] = usePostPy3ApiUserLoginMutation();
  const {setUserManually} = useAuth();
  const setAlert = useAlert("loginResponse");

  const {registerWithError, handleSubmit, getValues} = useForm<FormInputsType>(formInputsSchema, {
    keepDataOnSuccess: true,
    defaultValues:
      process.env.NEXT_PUBLIC_ENV_TYPE === "local"
        ? {email: email || "test@eventsframe.com", password: "test"}
        : {email},
  });

  const handleOnSubmit: OnFormSubmitType<FormInputsType> = async (data) => {
    const loginRes = await loginApi({
      requestLoginA992424: {username: data.email, password: data.password},
    });

    if ("data" in loginRes && loginRes.data.user) {
      setUserManually(loginRes.data.user);
      closeModal();
    }

    setAlert(
      <Box pb="1rem">
        <Alert status="error">
          <AlertIcon boxSize="16px" />
          <AlertDescription fontWeight="bold" color="red.500">
            {
              //@ts-expect-error fix
              loginRes?.error?.data?.message || "Something went wrong."
            }
          </AlertDescription>
        </Alert>
      </Box>,
      {clearAfter: 7000}
    );
  };

  return (
    <Box p="2rem">
      <Heading w="100%" variant="h3" textAlign="center" mb="1rem">
        Sign in to Eventsframe
      </Heading>

      <Box as="form" w="100%" onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack pb="0.8rem">
          <Input
            {...registerWithError("email")}
            placeholder="Enter your email"
            type="email"
            data-cy={dataCys.modals.loginSignInForm.email}
          />

          <Input
            {...registerWithError("password")}
            placeholder="Enter your password"
            type="password"
            data-cy={dataCys.modals.loginSignInForm.password}
            labelRight={
              <Link
                isExternal
                variant="baseDecorationSwitched"
                href={`/forgot-password?ignoreEffects=1&email=${encodeURIComponent(
                  getValues()?.email || ""
                )}`}
              >
                Forgot password?
              </Link>
            }
          />
        </Stack>

        <AlertSubscriber boxId="loginResponse" />

        <Button type="submit" w="100%" data-cy={dataCys.modals.loginSignInForm.btn}>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export const useLoginModal: InitialUseCreateModalType<LoginModalProps> = () =>
  useCreateModal("loginModal", LoginModal);
