import {useSelector} from "react-redux";

import {useRouter} from "next/router";

import {
  beApi,
  useGetPy3ApiEventsByEventIdQuery, // useGetPy3ApiEventsByEventIdTicketsQuery,
  // GetPy3ApiEventsByEventIdTicketsApiResponse,
} from "@ef/api";

export function useEventDetail() {
  const {query} = useRouter();

  return useSelector(
    beApi.endpoints.getPy3ApiEventsByEventId.select({
      eventId: query?.eventId?.toString() || "",
    })
  );
}

export function useInitEventDetail() {
  const {query} = useRouter();

  const res = useGetPy3ApiEventsByEventIdQuery(
    {eventId: query?.eventId?.toString() || ""},
    {refetchOnMountOrArgChange: true, skip: !Boolean(query?.eventId)}
  );

  return res;
}

// export function useInitEventDetailTickets(
//   initialTickets?: GetPy3ApiEventsByEventIdTicketsApiResponse
// ) {
//   const {query} = useRouter();

//   const res = useGetPy3ApiEventsByEventIdTicketsQuery(
//     {eventId: query?.eventId?.toString() || ""},
//     {refetchOnMountOrArgChange: true, skip: !Boolean(query?.eventId)}
//   );

//   return {data: res.data || initialTickets, isError: res.isError};
// }
