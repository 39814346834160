import Cookies, {CookieAttributes} from "js-cookie";
import {omit} from "ramda";

import React, {useState} from "react";

export type EF_COOKIES = {
  "wallet-new-features": "off";
};

type SetCookie<T> = (newValue: T, opts?: CookieAttributes) => void;
type CookieOpts = CookieAttributes & {stringify?: boolean};

const useCookie = <CookieName extends keyof EF_COOKIES>(
  cookieName: CookieName,
  cookieOpts: CookieOpts = {stringify: false}
) => {
  const [cookieValue, _setCookie] = useState<EF_COOKIES[CookieName]>(null);

  React.useEffect(() => {
    const initialCookie = Cookies.get(cookieName);

    if (initialCookie) {
      _setCookie(cookieOpts?.stringify ? JSON.parse(initialCookie) : (initialCookie as CookieName));
    }
  }, []);

  const setCookie: SetCookie<EF_COOKIES[CookieName]> = (newValue, opts = {}) => {
    Cookies.set(cookieName, cookieOpts?.stringify ? JSON.stringify(newValue) : newValue, {
      ...omit(["stringify"], cookieOpts),
      ...opts,
    });
    _setCookie(newValue);
  };

  return {cookieValue, hasCookie: Boolean(cookieValue), setCookie};
};

export default useCookie;
