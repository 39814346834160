import {pathOr} from "ramda";

import {CURRENCIES} from "./currenciesData";

type Options = {asPercent?: boolean};
export const formatCurrency = (
  currency = "eur",
  price: number,
  opts: Options = {asPercent: false}
) => {
  if (price === 0) {
    return "FREE";
  }

  const curr = pathOr(
    "__price__",
    [typeof currency === "string" ? currency?.toLowerCase() : "eur" || "eur"],
    CURRENCIES
  );

  // if (typeof curr !== "string") {
  //   return new Intl.NumberFormat(Intl.NumberFormat().resolvedOptions()?.locale || "en-GB", {
  //     style: "currency",
  //     currency,
  //     maximumSignificantDigits: 2,
  //   }).format(price);
  // }

  const sum = formatPriceToCorrectFormat(price).toString();

  if (opts.asPercent) return `${sum} %`;

  return curr.replace("__price__", sum);
};

export const formatPriceToCorrectFormat = (price: number): number => {
  return price % 1 != 0 ? parseFloat(price.toFixed(2)) : parseInt(price.toFixed(0));
};

export const formatCurrencyAsHtml = (
  currency: string,
  price: number,
  opts: Options = {asPercent: false}
) => <span dangerouslySetInnerHTML={{__html: formatCurrency(currency, price, opts)}} />;
