import Joi from "joi";

import React, {useRef, useState} from "react";

import {useGetPy3ApiUserStatusMutation} from "@ef/api";
import {useAuth} from "@ef/providers";

const emailValidator = Joi.string().email({tlds: {allow: false}});

export type UseEmailCheckerChatusType = "notInitialized" | "free" | "exists" | "verificationNeeded";

const useEmailChecker = (email) => {
  const [_email, setEmail] = useState(email);
  const [emailStatus, setShowInvalid] = useState<UseEmailCheckerChatusType>("notInitialized");
  const interval = useRef<NodeJS.Timer>(null);
  const [validateEmailWithBe] = useGetPy3ApiUserStatusMutation();
  const {user} = useAuth();
  const [revalidator, setRevalidator] = useState(0);

  React.useEffect(() => {
    clearInterval(interval.current);

    if (emailStatus === "verificationNeeded") {
      interval.current = setInterval(() => {
        setRevalidator((r) => r + 1);
      }, 3000);
    }
  }, [emailStatus]);

  React.useEffect(() => {
    setEmail(email);
  }, [email]);

  React.useEffect(() => {
    if (user || !_email || emailValidator.validate(_email)?.error) {
      setShowInvalid("free");
      return;
    }

    validateEmailWithBe({email: _email?.toLowerCase()})
      .then((e) => {
        if ("data" in e) {
          if (e.data.is_created && !e.data.is_verified) {
            setShowInvalid("verificationNeeded");
            return;
          } else if (e.data.is_created && e.data.is_verified) {
            setShowInvalid("exists");
            return;
          }
        }
        setShowInvalid("free");
      })
      .catch(() => {
        setShowInvalid("free");
      });
  }, [_email, user, validateEmailWithBe, revalidator]);

  return {
    emailStatus,
    revalidate: () => setRevalidator((r) => r + 1),
    setEmailToValidation: (email: string) => setEmail(email),
  };
};

export default useEmailChecker;
