import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const LogoSquare: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      id="logo"
      className="st0"
      d="M36.1,14.4H14.4v28.8h21.7v14.4H0V0h36.1V14.4z M79.2,57.6H64.9V14.4H43.3V0h36L79.2,57.6L79.2,57.6z
	 M57.7,36H43.3l-0.1-14.4h14.5V36z M36.1,36H21.7V21.6h14.4L36.1,36L36.1,36z"
    />
  </Icon>
);

export default LogoSquare;
