import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Provider} from "react-redux";

import {NextSeo} from "next-seo";
import {AppProps} from "next/app";
import Head from "next/head";
import Script from "next/script";

import {ModalProvider} from "@ef/modals";
import {AuthProvider} from "@ef/providers";

// import {mixpanelInit} from "@ef/utils";
import {ChakraProvider} from "@chakra-ui/react";

import Fonts from "../src/fonts";
import useRouteChangeHandler from "../src/hooks/useRouteChangeHandler";
// import useRouteChangeHandler from "../src/hooks/useRouteChangeHandler";
import Custom500 from "../src/scenes/Custom500/Custom500";
import {store, wrapper} from "../src/store";
import theme from "../src/theme";
import {initVisitorIdCookie} from "../src/utils/cookies";
// import {initVisitorIdCookie} from "../src/utils/cookies";
import {initGoogleReporting, reportError} from "../src/utils/googleError";

// import {mixpanelInit} from "../src/utils/mixpanel";

function App({Component, pageProps}: AppProps) {
  useRouteChangeHandler();

  React.useEffect(() => {
    initVisitorIdCookie();
    initGoogleReporting();
    // mixpanelInit();
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
      </Head>

      <Script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js" />

      <NextSeo
        title="Host Awesome Events"
        titleTemplate="%s | EventsFrame"
        canonical="https://eventsframe.com"
        twitter={{cardType: "summary_large_image", handle: "@EventsFrame", site: "@EventsFrame"}}
        openGraph={{
          title: "EventsFrame | Host Awesome Events",
          url: "https://eventsframe.com",
          description: "Easy to use ticketing for both organizers and attendees.",
          images: [
            {
              url: "https://uploads-ssl.webflow.com/625e843e32d684c692beb3ae/63165e33bfb65ade3394c359_features_landing_page.jpg",
              alt: "@EventsFrame",
            },
          ],
          locale: "en_US",
          site_name: "EventsFrame",
          type: "website",
        }}
        robotsProps={{noarchive: true}}
      />

      <Provider store={store}>
        <ChakraProvider theme={theme}>
          {
            // eslint-disable-next-line no-console
            <ErrorBoundary FallbackComponent={Custom500} onError={reportError}>
              <AuthProvider>
                <ModalProvider>
                  <Fonts />
                  <Component {...pageProps} />
                </ModalProvider>
              </AuthProvider>
            </ErrorBoundary>
          }
        </ChakraProvider>
      </Provider>
    </>
  );
}

export default wrapper.withRedux(App);
