import {format} from "date-fns";
import Joi from "joi";

import React from "react";

import {NftDataResponse, usePostPy3ApiNftsWithdrawalByWithdrawalIdConfirmMutation} from "@ef/api";
import {useForm, useSelectEventDetailByQueryEventId} from "@ef/hooks";
import {InitialUseCreateModalType, useCreateModal, useModal, useWithdrawalModal} from "@ef/modals";
import {useStrictAuth} from "@ef/providers";
import {mixpanelTrackEvent} from "@ef/utils";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";

import {useWithdrawalSuccessModal} from "./useWithdrawalSuccessModal";

type FormInputsTypes = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
};

const formInputsSchema = Joi.object({
  code1: Joi.string().length(1),
  code2: Joi.string().length(1),
  code3: Joi.string().length(1),
  code4: Joi.string().length(1),
  code5: Joi.string().length(1),
  code6: Joi.string().length(1),
});

type WithdrawalEmailModalProps = {
  withdrawalId: string;
  nftDetail: NftDataResponse;
  refetch: () => void;
};

const WithdrawalEmailModal: React.FC<WithdrawalEmailModalProps> = ({
  withdrawalId,
  nftDetail,
  refetch,
}) => {
  const {closeModal} = useModal("WithdrawalEmailModal");
  const {data: eventDetail} = useSelectEventDetailByQueryEventId();
  const {openModal: openSuccessModal} = useWithdrawalSuccessModal();
  const [time, setTime] = React.useState(3600000); //1 hour in ms
  const [callApi, {data}] = usePostPy3ApiNftsWithdrawalByWithdrawalIdConfirmMutation();
  const {user} = useStrictAuth();
  const [showAlert, setShowAlert] = React.useState(false);
  const {openModal: openNewWithdrawalModal} = useWithdrawalModal();
  const {
    registerWithError,
    setFocus,
    watch,
    formState: {isSubmitting},
    reset,
  } = useForm<FormInputsTypes>(formInputsSchema, {
    defaultValues: {code1: "", code2: "", code3: "", code4: "", code5: "", code6: ""},
  });

  const code1 = watch("code1");
  const code2 = watch("code2");
  const code3 = watch("code3");
  const code4 = watch("code4");
  const code5 = watch("code5");
  const code6 = watch("code6");

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const clip = e.clipboardData.getData("text");
    if (clip.length === 6) {
      const res = clip.split("").reduce((prev, code, i) => {
        prev[`code${i + 1}`] = code;
        return prev;
      }, {});

      reset(res);
    }
  };

  React.useEffect(() => {
    setFocus("code1");
    const interval = setInterval(() => setTime((time) => time - 1000), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    const values = [code1, code2, code3, code4, code5, code6];
    if (values.every((v) => Boolean(v))) {
      callApi({
        withdrawalId,
        nftWidthrawalConfirmationPayload08Fd148: {
          confirmation_code: Number(values.join("")),
        },
      })
        .then((data) => {
          // @ts-ignore swagger
          if (data?.data?.status === "requested") {
            mixpanelTrackEvent("NFT:WITHDRAWAL:EMAIL_CONFIRMATION_SUCCESSFUL", {
              eventId: eventDetail.id,
              eventName: eventDetail.name,
              nftId: nftDetail.id,
            });

            refetch();
            closeModal();
            openSuccessModal();
          } else {
            setShowAlert(true);
          }
        })
        .catch(() => {
          mixpanelTrackEvent("NFT:WITHDRAWAL:EMAIL_CONFIRMATION_ERROR", {
            eventId: eventDetail.id,
            eventName: eventDetail.name,
            nftId: nftDetail.id,
          });
        });
    }
  }, [code1, code2, code3, code4, code5, code6]);

  return (
    <Box p="24px">
      <Heading variant="h3" lineHeight="22px" mb="2rem">
        Please check your email
      </Heading>
      <Text>
        We&apos;ve sent a code to <b>{user?.email ?? "your.email@mail.com"}</b>
      </Text>
      <Text mb="2rem">Please enter the code in the form below to confirm your withdrawal. </Text>
      <Stack bg="ef-gray-l1" p="1rem" borderRadius="12px" align="center" mb="2rem">
        <Text>The code expires in</Text>
        <Heading variant="h2">{time === 3600000 ? "60:00" : format(time, "mm:ss")}</Heading>
      </Stack>

      <form>
        <Text variant="form-label">Please enter the code</Text>
        <Stack direction="row" justify="space-between">
          <Input
            h="56px"
            w="50px"
            maxLength={1}
            fontSize="20px"
            fontWeight="bold"
            {...registerWithError("code1")}
            textAlign="center"
            type="tel"
            onInput={(e) => {
              if (e.currentTarget.value.length >= 1) {
                setFocus("code2");
              }
            }}
            onPaste={handlePaste}
          />
          <Input
            h="56px"
            w="50px"
            type="tel"
            maxLength={1}
            fontSize="20px"
            fontWeight="bold"
            {...registerWithError("code2")}
            textAlign="center"
            onInput={(e) => {
              if (e.currentTarget.value.length >= 1) {
                setFocus("code3");
              }
            }}
            onPaste={handlePaste}
          />
          <Input
            h="56px"
            w="50px"
            type="tel"
            maxLength={1}
            fontSize="20px"
            fontWeight="bold"
            {...registerWithError("code3")}
            textAlign="center"
            onInput={(e) => {
              if (e.currentTarget.value.length >= 1) {
                setFocus("code4");
              }
            }}
            onPaste={handlePaste}
          />
          <Input
            h="56px"
            w="50px"
            type="tel"
            maxLength={1}
            fontSize="20px"
            fontWeight="bold"
            {...registerWithError("code4")}
            textAlign="center"
            onInput={(e) => {
              if (e.currentTarget.value.length >= 1) {
                setFocus("code5");
              }
            }}
            onPaste={handlePaste}
          />
          <Input
            h="56px"
            w="50px"
            type="tel"
            maxLength={1}
            fontSize="20px"
            fontWeight="bold"
            {...registerWithError("code5")}
            textAlign="center"
            onInput={(e) => {
              if (e.currentTarget.value.length >= 1) {
                setFocus("code6");
              }
            }}
            onPaste={handlePaste}
          />
          <Input
            h="56px"
            w="50px"
            type="tel"
            maxLength={1}
            fontSize="20px"
            fontWeight="bold"
            {...registerWithError("code6")}
            textAlign="center"
            onInput={(e) => {
              if (e.currentTarget.value.length < 1) {
                setFocus("code5");
              }
            }}
            onPaste={handlePaste}
          />
        </Stack>
        {showAlert && (
          <Box pb="1rem" mt="10px" fontSize="13px">
            <Alert status="error">
              <AlertIcon boxSize="16px" />
              <AlertDescription fontWeight="bold" color="red.500">
                {data?.status === "waiting-for-confirmation"
                  ? "Code is incorrect."
                  : "Something went wrong."}
              </AlertDescription>
            </Alert>
          </Box>
        )}

        <Stack spacing="0" direction="row-reverse" mt="3rem" justify="space-between">
          <Button w="33%" variant="blueOutline" isLoading={isSubmitting} onClick={closeModal}>
            Cancel
          </Button>
          {showAlert && (
            <Button
              variant="blue"
              onClick={() => {
                closeModal();
                openNewWithdrawalModal({nftDetail, refetch});
              }}
            >
              Start over
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export const useWithdrawalEmailModal: InitialUseCreateModalType<WithdrawalEmailModalProps> = () =>
  useCreateModal("WithdrawalEmailModal", WithdrawalEmailModal, {
    hideCloseButton: true,
    size: "md",
    isFullOnMobile: true,
  });
